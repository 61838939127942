<script>
import { AxiosAPI } from '@/axios';
import sound from '@/assets/sound/fim.mp3';
import { useMainStore, useBusStore } from '../../stores/index';
export default {
	name: 'TvDiscussion',
	components: {},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			tribuna: false,
			document_lote: [],
			user_doc: null,
			user_on: false,
			x: false,
			voted: false,
			legendavote: false,
			f_fala: false,
			seconds: 2,
			minutes: 0,
			Document_discut_name: ['Aguarde - Carregando documento...',],
			Document_discut_autor: 'Aguarde...',
			Document_discut_tipo: 'Aguarde...',
			Document_discut_id: 0,
			Discut_id: 0,
			discut_vereadores: [],
			cronometro: 'Aguarde...',
			dia: 0,
			mes: '',
			time: '',
			timer_session: 'Aguarde o inicio',
			autor: '',
			socket_ids: [],
			timerOn: false,
			TimerOver: false,
			minutesToProgressBar: 0,
			secondsToProgressBar: 0,
			Documentss: []
		};
	},

	watch: {
		TimerOver: function (newValue, oldPath) {
			if (newValue === true) {
				this.TimerOver = false;
				this.Sound();
				this.Finalizar_fala();
			}
		},
	},
	computed: {

		document_lote_uniq() {
			const LIMIT = 60;
			return this.Document_discut_name.map((name) => {
				const parts = name.split(' - ');
				return [
					{ text: parts[0], colorClass: 'text-uppercase font-weight-bold text-warning' },
					{ text: ' - ', colorClass: 'text-uppercase font-weight-bold text-white' },
					{
						text: parts[1].length > LIMIT ? parts[1].substring(0, 300) : parts[1],
						colorClass: 'text-uppercase font-weight-bold text-white',
					},
				];
			});
		},

		document_lote_number() {
			return this.Document_discut_name.map((name) => {
				const parts = name.split(' - ');
				return [
					{ text: parts[0] },
				];
			});
		},

		SeeState () {
			let state =  this.discut_vereadores.some(item => item.state === 'F');

			return state;
		}
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);

				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 26) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.DiscutOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 27) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.DiscutOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 28) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.DiscutOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 29) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.DiscutOn();
							this.socket_ids.push(e.event_id);
						}
					}
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
	},

	methods: {

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.session = this.StoreAll.session; this.session_status = this.StoreAll.session.session_status.id;
			this.session_id = this.StoreAll.session.id; this.session_status_name = this.StoreAll.session.session_status.name;
			this.TimesNow();
			this.Session_timer();
			this.Hora();
			this.Discut_comp();
			setInterval(this.Hora, 1000);
		},

		TimesNow(){
			let verificarTimer = setInterval(() => {
				if (this.StoreAll.timers !== null) {
					clearInterval(verificarTimer);
					this.seconds = this.StoreAll.timers[0].Vereador_discussion.seconds;
					this.minutes = this.StoreAll.timers[0].Vereador_discussion.minutes;

				}
			}, 500);
		},

		Session_timer() {
			setTimeout(() => {
				this.Session_timer();
			}, 900);

			if(this.StoreAll.quorum.length <= 0){
				return;
			}

			let inicio = new Date(this.StoreAll.quorum[0].created_at).getTime();;
			let fim = new Date().getTime();;
			let diferenca = fim - inicio;
			let hours = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
			let minutes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
			let seconds = Math.floor((diferenca % (1000 * 60)) / 1000);
			let timer_session = hours + 'h: ' + minutes + 'm: ' + seconds + 's';
			this.timer_session = timer_session;
		},

		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mesesEmPortugues = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
				'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
			];
			let mes = mesesEmPortugues[date_time.getMonth()];

			let minutes = date_time.getMinutes().toString().padStart(2, '0');
			let hours = date_time.getHours().toString().padStart(2, '0');
			let seconds = date_time.getSeconds().toString().padStart(2, '0');

			this.time = `${hours}: ${minutes}: ${seconds}`;
			this.dia = dia;
			this.mes = mes;
		},

		async Quorum_data(){
			await this.StoreAll.Get_Data();
		},

		async Discut_comp(){
			let Document_discut_id = [];
			let Discut_id = [];
			let documents_lote = [];
			let documents_lote_name = [];

			if(!this.StoreAll.discut){
				return;
			}
			if(this.StoreAll.quorum == null){
				this.Quorum_data();
				return;
			}

			await AxiosAPI.get('/discussion?quorum_id='+this.StoreAll.quorum[0].id,{
				headers: {
					Authorization: 'Bearer '+this.StoreAll.accessToken,
				},
			}).then((resp) => {

				if (resp.data.data.length <= 0) {
					return;
				}

				resp.data.data.forEach(dataDiscussion => {
					if (dataDiscussion.status_discussion === '1') {
						documents_lote.push(dataDiscussion);
						Document_discut_id.push(dataDiscussion.document_id);
						Discut_id.push(dataDiscussion.id);
						documents_lote_name.push(dataDiscussion.document.name);
					}
				});

				this.document_lote = documents_lote_name;
				this.Document_discut_id = Document_discut_id;
				this.Discut_id = Discut_id;


				this.user_doc = documents_lote[0].document.authors;
				this.Document_discut_tipo =  documents_lote[0].document.document_category_id;
				this.Documentss =  documents_lote;
				this.Detail();
			});

		},

		Detail(){
			this.Document_discut_autor = this.user_doc;
			let elementos = this.document_lote;
			let LIMIT = 300;
			let names_d = [];

			for (let i = 0; i < elementos.length; i++) {
				if (elementos[i].length > LIMIT) {
					names_d.push(elementos[i].substring(0, 300) + '...');
				} else {
					names_d.push(elementos[i]);
				}
			}
			this.Document_discut_name = names_d;
			this.Author();
		},

		Author(){
			let Co_user = [];
			for (let i = 0; i < this.Document_discut_autor.length; i++) {
				Co_user.push(this.Document_discut_autor[i].nickname);
			}
			let result = Co_user.join(', ');

			this.autor = result;
		},

		async DiscutOn() {
			if (!this.voted) {
				await AxiosAPI.get('/discussion-users?discussion_id=' + this.Discut_id[0], {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {
					let discut_vereadores = [];
					let ArrayLess = [];

					for (let i = 0; i < response.data.data.length; i++) {
						discut_vereadores.push(response.data.data[i]);
					}

					for (let i = 0; i < discut_vereadores.length; i++) {
						const TalkDiscussion = discut_vereadores[i];
						if (response.data.data[i].status_fala_discussion === '1') {
							TalkDiscussion['state'] = 'F';
							this.StoreAll.Discut_User = TalkDiscussion.user.id;
							localStorage.setItem('id_user_tv_discut', TalkDiscussion.user.id);
							this.user_on = true;
							this.fala = true;
							this.f_fala = false;

							if(response.data.data[i].orador === 1){
								this.seconds = this.StoreAll.timers[0].Vereador_discussion.seconds;
								this.minutes = this.StoreAll.timers[0].Vereador_discussion.minutes;

								this.minutesToProgressBar = this.StoreAll.timers[0].Vereador_discussion.minutes;
								this.secondsToProgressBar = this.StoreAll.timers[0].Vereador_discussion.seconds;
							}

							if(response.data.data[i].orador === 2){
								this.seconds = this.StoreAll.timers[0].Vereador_reduzido_discussion.seconds;
								this.minutes = this.StoreAll.timers[0].Vereador_reduzido_discussion.minutes;

								this.minutesToProgressBar = this.StoreAll.timers[0].Vereador_reduzido_discussion.minutes;
								this.secondsToProgressBar = this.StoreAll.timers[0].Vereador_reduzido_discussion.seconds;
							}
						}

						if (response.data.data[i].status_fala_discussion === '2') {
							TalkDiscussion['state'] = 'FS';
						}
						ArrayLess.push(TalkDiscussion);
					}
					this.discut_vereadores = ArrayLess;
					this.CronometroStatus(ArrayLess);
				});
			}
		},

		CronometroStatus(value) {
			if (value.length <= 0) {
				return;
			}
			const TalkDiscussion = [];
			const FimDiscussion = [];

			value.forEach(Talk => {
				if (Talk.state === 'F') {
					TalkDiscussion.push(Talk);
				}

				if (Talk.state === 'FS') {
					FimDiscussion.push(Talk);
				}
			});

			if (TalkDiscussion.length >= 1) {
				this.timerOn = true;
				this.timer();
			}

			if (TalkDiscussion.length <= 0) {
				this.timerOn = false;
				this.ResetTimer(FimDiscussion);
			}

		},

		timer() {
			if (!this.timerOn) {
				return;
			}

			setTimeout(() => {
				this.timer();
			}, 1000);

			let sec = this.seconds--;
			let min = this.minutes;

			if (min == 0 && sec == 0) {
				this.seconds = 0;
				this.minutes = 0;
				this.cronometro = '';
				let text = 'Acabou seu tempo!';
				document.getElementById('timerzz').innerText = text;
				this.timerOn = false;
				this.TimerOver = true;
			} else {

				if (sec <= 9) {
					sec = '0' + sec;
					this.t = sec;
				}
				if (sec == 0) {
					this.seconds = 59;
					let min = this.minutes--;
					this.t = min;
				}
				if (min == 0 && sec <= 59) {
					document.getElementById('timerz').style.color = '#FF0000';
					document.getElementById('timerzz').style.color = '#FF0000';
				}

				this.cronometro = min + ' : ' + sec;
				this.t = sec;

				let totalRemainingSeconds = this.minutes * 60 + this.seconds;

				let totalInitialSeconds = (this.minutesToProgressBar * 60 + this.secondsToProgressBar + 1);

				let percentageRemaining = (totalRemainingSeconds / totalInitialSeconds) * 100;

				document.querySelector('.progress-bar').style.width = percentageRemaining + '%';
				if (totalRemainingSeconds < 60) {
			 document.querySelector('.progress-bar').style.backgroundColor = '#DC3545';
				} else {
					document.querySelector('.progress-bar').style.backgroundColor = '#198754';
				}
			}
		},

		ResetTimer() {
			this.seconds = this.StoreAll.timers[0].Vereador_discussion.seconds;
			this.minutes = this.StoreAll.timers[0].Vereador_discussion.minutes;
			this.StoreAll.Discut_User = null;
		},

		Finalizar_fala(){
			const formData = new FormData();
			formData.append('discussion_id', this.Discut_id[0]);
			formData.append('user_id', localStorage.getItem('id_user_tv_discut'));
			formData.append('status_fala_discussion', 2);

			AxiosAPI.post('/discussion-users-upp', formData, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				this.t = resp;
				this.ResetTimer();
			});
		},

		Sound () {
			let audio = new Audio(sound);
			audio.play();
		},

		isPresent(user) {
			return user[1] === 'P';
		},

		userStatus(user) {
			return this.isPresent(user) ? 'Presente' : 'Ausente';
		},

		userImagePath(user) {
			return this.StoreAll.Dochost + user.path_image;
		}
	}

};
</script>
<template>
  <div>
    <div v-if="StoreAll.tvlength === 1" class="d-flex">
		<div class="box-documents" style="margin-left: 1%;">
			<div v-if="Documentss.length <= 1">
				<div class="d-flex" style="height: 8vh;margin-top: 1%;">
					<div v-for="(name, index) in document_lote_number" :key="name">
						<div v-if="index === 0" class="ml-2 mt-3 mr-2 box-names-docs" style="width: 100%;">
							<div class="d-flex justify-content-center align-items-center">
								<span v-for="part in name" :key="part" class="fonts-body" style="font-size: 2vw; color:#212529">
									{{ part.text }}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div style="height: 50vh;">
					<div v-for="name in document_lote_uniq" :key="name" class="ml-2 mt-3">
						<div>
							<span v-for="part in name.slice(2,3)" :key="part" class="fonts-body" style="font-size: 2vw;">
								{{ part.text }}
							</span>
						</div>
					</div>
				</div>

				<div  style="top: 5px; position: relative;">
					<span class="fonts-body ml-2" style="font-size: 1.5vw;">
                    {{ autor }} Autor(es) da materia.
                  </span>
				</div>
			</div>
			<div v-if="Documentss.length >= 2" class="ml-3">

				<div class="d-flex" style="height: 8vh;margin-top: 1%;">
					<div v-for="(name, index) in document_lote_number" :key="name">
						<div v-if="index === 0" class="ml-2 mt-3 mr-2 box-names-docs" style="width: 100%;">
							<div class="d-flex justify-content-center align-items-center">
								<span v-for="part in name" :key="part" class="fonts-body" style="font-size: 2vw; color:#212529">
									{{ part.text }}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div style="height: 30vh;">
					<div v-for="(name, index) in document_lote_uniq" :key="name" class="ml-2 mt-3">
						<div v-if="index === 0">
							<span v-for="part in name.slice(2,3)" :key="part" class="fonts-body" style="font-size: 2vw;">
								{{ part.text }}
							</span>
						</div>
					</div>
				</div>

				<div  style="top: 5px; position: relative;">
					<span class="fonts-body ml-2" style="font-size: 1.5vw;">
                    {{ autor }} Autor(es) da materia.
                  </span>
				</div>

				<div style="top: 40px; position: relative;">
					<span class="fonts-body ml-2" style="font-size: 1.8vw;">
                    E mais na discussão:
                  </span>
				</div>

				<div class="d-flex" style="top: 40px; position: relative;">
					<div v-for="name in document_lote_number" :key="name" class="ml-2 mt-3 mr-2 box-names-docs">
						<div class="d-flex justify-content-center align-items-center">
							<span v-for="part in name" :key="part" class="fonts-body" style="font-size: 2vw; color:#212529">
								{{ part.text }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
			<div class="ml-3" style="width: 31.5%;">
				<div v-if="discut_vereadores.length <= 0" class="WaitSubscribeBefore d-flex justify-content-center">
						<div style="position: relative; top: 20%; height:40vh">
							<div class="d-flex justify-content-center align-items-center text-center">
								<div>
									<i class="fa-solid fa-user" style="color:#007AB8;font-size: 16.6vw;height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"></i>
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<i class="fa-solid fa-hourglass-half" style="font-size: 2.6vw; color: #007AB8"></i>
							</div>
						</div>
				</div>
				<div v-if="discut_vereadores.length >= 1" class="WaitSubscribeBefore d-flex justify-content-center">
					<div v-for="(discut, index) in discut_vereadores" :key="discut.id">
						<div v-if="discut.state === 'F'" style="position: relative; top: 3%">
							<div class="d-flex justify-content-center">
								<div>
									<img :src="userImagePath(discut.user)"
									style="height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"
									alt="user photo" />
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<span
								class="ml-2 fonts-body"
								style="font-size: 1.6vw">
								{{ discut.user.nickname }}
								</span>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<span
								v-if="discut.user.user_category_id === 3"
								id="status"
								class="text-black ml-2 pl-2 pr-2 box-presidente"
								style="font-size: 1.1vw;">
								Presidente
								</span>
								<span
								v-if="discut.user.user_category_id !== 5 && discut.user.user_category_id !== 8"
								id="status"
								class="text-white mr-2 pl-2 pr-2 box-partido"
								style="border:1px solid white; font-size: 1.1vw;">
								{{ discut.user.category_party.name_party }}
								</span>
								<div class="d-flex justify-content-center align-items-center mr-2 box-presidente" style="background-color: #198754;">
									<i class="fa-solid fa-microphone mr-2 text-white" style="font-size: 1.2vw"></i>
									<span class="text-white fonts-body" style="font-size: 1.2vw">Falando</span>
								</div>
							</div>
							<div class="d-flex justify-content-center">
								<div class="box-timerTalk">
									<div class="d-grid align-items-center justify-content-center" style="top: 20%;position: relative;">
										<div class="progress" style="width: 27vw; height: 2vh;">
											<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerz" class="fonts-body" style="font-size:2.9vw;"> {{ cronometro }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerzz" class="fonts-body" style="font-size:1.2vw;"> Tempo </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!SeeState" style="position: relative; top: 20%; height:40vh">
							<div v-if="index === 0">
								<div class="d-flex justify-content-center align-items-center text-center">
									<div>
										<i class="fa-solid fa-user" style="color:#007AB8;font-size: 16.6vw;height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"></i>
									</div>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<i class="fa-solid fa-hourglass-half" style="font-size: 2.6vw; color: #007AB8"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        <div>
      </div>
    </div>
	<div v-if="StoreAll.tvlength === 2 && StoreAll.fullScren_quorum" class="d-flex">
		<div class="box-documents" style="margin-left: 1%;width: 98%;">
			<div v-if="Documentss.length <= 1">
				<div class="d-flex" style="height: 8vh;margin-top: 1%;">
					<div v-for="(name, index) in document_lote_number" :key="name">
						<div v-if="index === 0" class="ml-2 mt-3 mr-2 box-names-docs" style="width: 100%;">
							<div class="d-flex justify-content-center align-items-center">
								<span v-for="part in name" :key="part" class="fonts-body" style="font-size: 2vw; color:#212529">
									{{ part.text }}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div style="height: 50vh;">
					<div v-for="name in document_lote_uniq" :key="name" class="ml-2 mt-3">
						<div>
							<span v-for="part in name.slice(2,3)" :key="part" class="fonts-body" style="font-size: 2vw;">
								{{ part.text }}
							</span>
						</div>
					</div>
				</div>

				<div  style="top: 5px; position: relative;">
					<span class="fonts-body ml-2" style="font-size: 1.5vw;">
                    {{ autor }} Autor(es) da materia.
                  </span>
				</div>
			</div>
			<div v-if="Documentss.length >= 2" class="ml-3">

				<div class="d-flex" style="height: 8vh;margin-top: 1%;">
					<div v-for="(name, index) in document_lote_number" :key="name">
						<div v-if="index === 0" class="ml-2 mt-3 mr-2 box-names-docs" style="width: 100%;">
							<div class="d-flex justify-content-center align-items-center">
								<span v-for="part in name" :key="part" class="fonts-body" style="font-size: 2vw; color:#212529">
									{{ part.text }}
								</span>
							</div>
						</div>
					</div>
				</div>

				<div style="height: 30vh;">
					<div v-for="(name, index) in document_lote_uniq" :key="name" class="ml-2 mt-3">
						<div v-if="index === 0">
							<span v-for="part in name.slice(2,3)" :key="part" class="fonts-body" style="font-size: 2vw;">
								{{ part.text }}
							</span>
						</div>
					</div>
				</div>

				<div  style="top: 5px; position: relative;">
					<span class="fonts-body ml-2" style="font-size: 1.5vw;">
                    {{ autor }} Autor(es) da materia.
                  </span>
				</div>

				<div style="top: 40px; position: relative;">
					<span class="fonts-body ml-2" style="font-size: 1.8vw;">
                    E mais na discussão:
                  </span>
				</div>

				<div class="d-flex" style="top: 40px; position: relative;">
					<div v-for="name in document_lote_number" :key="name" class="ml-2 mt-3 mr-2 box-names-docs">
						<div class="d-flex justify-content-center align-items-center">
							<span v-for="part in name" :key="part" class="fonts-body" style="font-size: 2vw; color:#212529">
								{{ part.text }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
        <div>
      </div>
    </div>
	<div v-if="StoreAll.tvlength === 2 && StoreAll.fullScren_actions" class="d-flex">
			<div class="ml-3" style="width: 98%;">
				<div v-if="discut_vereadores.length <= 0" class="WaitSubscribeBefore d-flex justify-content-center">
						<div style="position: relative; top: 20%; height:40vh">
							<div class="d-flex justify-content-center align-items-center text-center">
								<div>
									<i class="fa-solid fa-user" style="color:#007AB8;font-size: 16.6vw;height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"></i>
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<i class="fa-solid fa-hourglass-half" style="font-size: 2.6vw; color: #007AB8"></i>
							</div>
						</div>
				</div>
				<div v-if="discut_vereadores.length >= 1" class="WaitSubscribeBefore d-flex justify-content-center">
					<div v-for="(discut, index) in discut_vereadores" :key="discut.id" class="d-flex align-items-center">
						<div v-if="discut.state === 'F'"  class="d-flex" style="position: relative;">
							<div>
								<div class="d-flex justify-content-center">
								<div>
									<img :src="userImagePath(discut.user)"
									style="height: 24.5vw;width: 24.5vw;border-radius:50%;object-fit: cover;"
									alt="user photo" />
								</div>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ discut.user.nickname }}
									</span>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<span
									v-if="discut.user.user_category_id === 3"
									id="status"
									class="text-black ml-2 pl-2 pr-2 box-presidente"
									style="font-size: 1.1vw;">
									Presidente
									</span>
									<span
									v-if="discut.user.user_category_id !== 5 && discut.user.user_category_id !== 8"
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ discut.user.category_party.name_party }}
									</span>
									<div class="d-flex justify-content-center align-items-center mr-2 box-presidente" style="background-color: #198754;">
										<i class="fa-solid fa-microphone mr-2 text-white" style="font-size: 1.2vw"></i>
										<span class="text-white fonts-body" style="font-size: 1.2vw">Falando</span>
									</div>
								</div>
							</div>
							<div class="d-flex justify-content-center align-items-center">
								<div class="box-timerTalkExplanation">
									<div class="d-grid align-items-center justify-content-center" style="top: 20%;position: relative;">
										<div class="progress" style="width: 55vw; height: 2vh;">
											<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerz" class="fonts-body" style="font-size:6.9vw;"> {{ cronometro }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerzz" class="fonts-body" style="font-size:2.2vw;"> Tempo </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!SeeState" style="position: relative; top: 0%; height:40vh">
							<div v-if="index === 0">
								<div class="d-flex justify-content-center align-items-center text-center">
									<div>
										<i class="fa-solid fa-user" style="color:#007AB8;font-size: 16.6vw;height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"></i>
									</div>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<i class="fa-solid fa-hourglass-half" style="font-size: 2.6vw; color: #007AB8"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        <div>
      </div>
    </div>
	<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
	style="bottom: 15px; position: fixed;">
		<div class="d-flex align-items-center w-20">
			<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
			<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
		</div>
		<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
			<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
		</div>
		<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
			style="font-size: 1.8vw;">
			<i class="fa-solid fa-clock mr-2"></i>
			Tempo de sessão
			<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
		</div>
	</div>
  </div>
</template>
<style>
.box-documents {
	width: 65%;
    height: 70vh;
    background-color: #023866;
    border-radius: 32px;
    margin-top: 2rem;
}

.box-names-docs {
	width: 20%;
    height: 6vh;
    background-color: #F8F9FA;
    border-radius: 40px;
}
.box-user-talk {
	width: 31%;
    height: 70vh;
    background-color: #023866;
    border-radius: 16px;
    margin-top: 2rem;
}

.text-black {
  color: black;
}
.status_circle {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.status_circle_active {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: 2px solid #0db80d;
  background-color: #0db80d;
}
.display_vh {
  height: clamp(0.1vw, 44.6vw, 855px);
}
.display_sign {
  width: clamp(0.1vw, 22.6vw, 410px);
}
.display_cronometro {
  width: clamp(0.1vw, 50vw, 600px);
}
.display_img {
  width: clamp(0.1vw, 20vw, 310px);
  height: clamp(0.1vw, 20vw, 310px);
}

.display_margin {
  margin-right: 12%;
}
@media only screen and (max-width: 1600px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 690px);
  }
  .display_sign {
    width: clamp(0.1vw, 25.6vw, 410px);
  }
  .display_cronometro {
    width: clamp(0.1vw, 30vw, 500px);
  }
  .display_margin {
    margin-right: 7%;
  }
}
@media only screen and (max-width: 1380px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 570px);
  }
  .display_sign {
    width: clamp(0.1vw, 28.6vw, 400px);
  }
  .display_margin {
    margin-right: 4%;
  }
}
@media only screen and (max-width: 1280px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 530px);
  }
  .display_sign {
    width: clamp(0.1vw, 29.6vw, 400px);
  }
  .display_margin {
    margin-right: 2%;
  }
}
</style>
