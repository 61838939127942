<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';

export default {
	name: 'LayoutPresidenteBody',
	setup() {
		const StoreAll = useMainStore();
		StoreAll.User();
		return {
			StoreAll: StoreAll
		};
	},
	props: {
		UserPresidente: {
			type: Array,
			required: true
		}
	},
	data() {
		return {
			session: { name: 'Aguarde o quorum ser iniciado...', },
			socket_ids: [],
			QuorumRemoveUser: false,
			QuorumAddUser: false,
			users_quorums: [],
			users: [],
			User_Tot: [],
			totall: 0,
			presentess: 0,
			ausentess: 0,
			us: [],
			Loading: false,
			session_status_name: '',
			session_id: 0,
		};
	},

	watch: {
		QuorumRemoveUser: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Get_Quorum_Socket_Tv_full();
				this.QuorumRemoveUser = false;
			}
		},

		QuorumAddUser: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.Get_Quorum_Socket_Tv_full();
				this.QuorumAddUser = false;
			}
		}
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 1) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.GetSession();
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 4) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.QuorumAddUser = true;
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 5) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.QuorumRemoveUser = true;
							this.socket_ids.push(e.event_id);
						}
					}
				});
			}
		}, 500);

	},

	mounted() {
		this.GetSession();
	},

	methods: {
		async GetSession() {
			this.Loading = true;
			await this.StoreAll.Get_Data();
			const { session } = this.StoreAll;
			if (session == null) {
				this.GetSession();
				return;
			}
			const { session_status, id } = session;
			this.session = session;
			this.session_status = session_status.id;
			this.session_id = id;
			this.session_status_name = session_status.name;

			this.get_expediente();
			this.Get_Quorum_Socket_Tv_full();
		},

		async get_expediente() {
			try {
				const response = await AxiosAPI.get('/users', {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				});

				this.users = response.data.filter(user => {
					const excludedStatusUser = [2, 3];
					return !excludedStatusUser.includes(user.status_user) && user.user_category_id !== 3;
				});

				this.users2 = response.data.filter(user => user.user_category_id === 3);
				this.user_presidente = this.users2;

			} catch (error) {
				console.log(error);
			}
		},

		async Get_Quorum_Socket_Tv_full() {
			await this.StoreAll.Get_Data();
			this.Quorum_atual_full();
		},

		async Quorum_atual_full() {
			if (this.StoreAll.quorum == null) {
				return;
			}
			if (this.StoreAll.quorum.length <= 0) {
				return;
			}
			if (this.StoreAll.quorum.length >= 1 && this.StoreAll.quorum != null) {
				try {
					await AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((response) => {
						this.users_quorums = response.data.data;
						this.ContagemUsersAndPresidente();
					});
				}
				catch {
					console.log('error');
				}
			};
		},

		ContagemUsersAndPresidente() {
			let users_presentes = 0;
			let presidente_presente = 0;

			// Inicialização das variáveis
			let user_P = [];
			let user_A = [];
			let user_T = [];

			// Definir o estado 'Ausente' para todos os usuários
			this.users.forEach(user => {
				user[1] = 'A';
			});

			// Definir o estado 'Ausente' para todos os presidentes
			this.user_presidente.forEach(user => {
				user[1] = 'A';
			});

			// Verificar usuários presentes nos quorums
			this.users_quorums.forEach(quorum => {
				const foundUser = this.users.find(user => user.name === quorum.user.name);
				if (foundUser) {
					foundUser[1] = 'P';
					users_presentes++;
				}

				const foundPresidente = this.user_presidente.find(user => user.name === quorum.user.name);
				if (foundPresidente) {
					foundPresidente[1] = 'P';
					presidente_presente++;
				}
			});

			// Filtrar usuários presentes e ausentes
			user_P = this.users.filter(user => user[1] === 'P');
			user_A = this.users.filter(user => user[1] === 'A');
			user_T = [...user_P, ...user_A];
			this.User_Tot = user_T;

			// Atualizar contagem total de usuários
			this.totall = this.users.length + this.user_presidente.length;
			this.presentess = users_presentes + presidente_presente;
			this.ausentess = this.totall - this.presentess;

			// Atualizar estados de usuários presentes
			this.us = this.users;
			this.StoreAll.quorum_moment = true;
			this.Loading = false;
		},

		isPresent(user) {
			return user[1] === 'P';
		},
		userStatus(user) {
			return this.isPresent(user) ? 'Presente' : 'Ausente';
		},
		userImagePath(user) {
			return this.StoreAll.Dochost + user.path_image;
		}
	},
};
</script>
<template>
	<div>
		<div v-if="this.StoreAll.Large_host == 17" class="d-flex align-items-center" style="height: 17vh;">
			<div class="d-flex w-100" style="height: 85%;">
				<div class="d-flex container-presidente py-2 w-75">
						<div v-for="user in UserPresidente" :key="user" class="d-flex align-items-center">
								<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
									<div class="d-flex align-items-center">
										<img :src="userImagePath(user)" class="img_display_presidente" alt="user photo" />

										<div>
											<div class="">
												<span
												class="ml-2 fonts-body">
												{{ user.nickname }}
												</span>
											</div>

											<div class="d-flex mb-1">
												<span
												id="status"
												class="text-black ml-2 pl-2 pr-2 box-presidente"
												style="font-size: 1.1vw;">
												Presidente
												</span>
												<span
												id="status"
												class="text-white mr-2 pl-2 pr-2 box-partido"
												style="border:1px solid white; font-size: 1.1vw;">
												{{ user.category_party.name_party }}
												</span>
												<span v-if="userStatus(user) === 'Presente'"
												id="status"
												class="text-white pl-2 pr-2 box-presente-status"
												style="font-size: 1.1vw;">
												{{ userStatus(user) }}
												</span>
												<span v-if="userStatus(user) === 'Ausente'"
												id="status"
												class="text-white pl-2 pr-2 box-ausente-status"
												style="font-size: 1.1vw;">
												{{ userStatus(user) }}
												</span>
											</div>

										</div>
									</div>
								</div>
						</div>
				</div>
				<div class="d-flex w-25" style="margin-right: 0.8rem;">
						<div class="d-grid box-present align-items-center">
							<div class="d-grid boxs">
								<span class="text-white font-Local-number">{{ presentess }}</span>
								<span class="text-white fonts-body">Presentes</span>
							</div>
						</div>
						<div class="d-grid box-ausente align-items-center">
							<div class="d-grid boxs">
								<span class="text-white font-Local-number">{{ ausentess }}</span>
								<span class="text-white fonts-body">Ausentes</span>
							</div>
						</div>
				</div>
			</div>
		</div>
		<div v-if="this.StoreAll.Large_host == 13" class="d-flex align-items-center" style="height: 20vh;">
			<div class="d-flex w-100" style="height: 85%;">
				<div class="d-flex container-presidente py-2 w-75">
						<div v-for="user in UserPresidente" :key="user" class="d-flex align-items-center">
								<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
									<div class="d-flex align-items-center">
										<img :src="userImagePath(user)" class="img_display_presidente" alt="user photo" />

										<div>
											<div class="">
												<span
												class="ml-2 fonts-body">
												{{ user.nickname }}
												</span>
											</div>

											<div class="d-flex mb-1">
												<span
												id="status"
												class="text-black ml-2 pl-2 pr-2 box-presidente"
												style="font-size: 1.1vw;">
												Presidente
												</span>
												<span
												id="status"
												class="text-white mr-2 pl-2 pr-2 box-partido"
												style="border:1px solid white; font-size: 1.1vw;">
												{{ user.category_party.name_party }}
												</span>
												<span v-if="userStatus(user) === 'Presente'"
												id="status"
												class="text-white pl-2 pr-2 box-presente-status"
												style="font-size: 1.1vw;">
												{{ userStatus(user) }}
												</span>
												<span v-if="userStatus(user) === 'Ausente'"
												id="status"
												class="text-white pl-2 pr-2 box-ausente-status"
												style="font-size: 1.1vw;">
												{{ userStatus(user) }}
												</span>
											</div>

										</div>
									</div>
								</div>
						</div>
				</div>
				<div class="d-flex w-25" style="margin-right: 0.8rem;">
						<div class="d-grid box-present align-items-center">
							<div class="d-grid boxs">
								<span class="text-white font-Local-number">{{ presentess }}</span>
								<span class="text-white fonts-body">Presentes</span>
							</div>
						</div>
						<div class="d-grid box-ausente align-items-center">
							<div class="d-grid boxs">
								<span class="text-white font-Local-number">{{ ausentess }}</span>
								<span class="text-white fonts-body">Ausentes</span>
							</div>
						</div>
				</div>
			</div>
		</div>
		<div v-if="this.StoreAll.Large_host == 11" class="d-flex align-items-center" style="height: 20vh;">
			<div class="d-flex w-100" style="height: 85%;">
				<div class="d-flex container-presidente py-2 w-75">
						<div v-for="user in UserPresidente" :key="user" class="d-flex align-items-center">
								<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
									<div class="d-flex align-items-center">
										<img :src="userImagePath(user)" class="img_display_presidente" alt="user photo" />

										<div>
											<div class="">
												<span
												class="ml-2 fonts-body">
												{{ user.nickname }}
												</span>
											</div>

											<div class="d-flex mb-1">
												<span
												id="status"
												class="text-black ml-2 pl-2 pr-2 box-presidente"
												style="font-size: 1.1vw;">
												Presidente
												</span>
												<span
												id="status"
												class="text-white mr-2 pl-2 pr-2 box-partido"
												style="border:1px solid white; font-size: 1.1vw;">
												{{ user.category_party.name_party }}
												</span>
												<span v-if="userStatus(user) === 'Presente'"
												id="status"
												class="text-white pl-2 pr-2 box-presente-status"
												style="font-size: 1.1vw;">
												{{ userStatus(user) }}
												</span>
												<span v-if="userStatus(user) === 'Ausente'"
												id="status"
												class="text-white pl-2 pr-2 box-ausente-status"
												style="font-size: 1.1vw;">
												{{ userStatus(user) }}
												</span>
											</div>

										</div>
									</div>
								</div>
						</div>
				</div>
				<div class="d-flex w-25" style="margin-right: 0.8rem;">
						<div class="d-grid box-present align-items-center">
							<div class="d-grid boxs">
								<span class="text-white font-Local-number">{{ presentess }}</span>
								<span class="text-white fonts-body">Presentes</span>
							</div>
						</div>
						<div class="d-grid box-ausente align-items-center">
							<div class="d-grid boxs">
								<span class="text-white font-Local-number">{{ ausentess }}</span>
								<span class="text-white fonts-body">Ausentes</span>
							</div>
						</div>
				</div>
			</div>
		</div>
		<div v-if="this.StoreAll.Large_host == 9" class="d-flex align-items-center" style="height: 24vh;">
			<div class="d-flex w-100" style="height: 85%;">
				<div class="d-flex container-presidente py-2 w-75">
						<div v-for="user in UserPresidente" :key="user" class="d-flex align-items-center">
								<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
									<div class="d-flex align-items-center">
										<img :src="userImagePath(user)" class="img_display_presidente" alt="user photo" />

										<div>
											<div class="">
												<span
												class="ml-2 fonts-body">
												{{ user.nickname }}
												</span>
											</div>

											<div class="d-flex mb-1">
												<span
												id="status"
												class="text-black ml-2 pl-2 pr-2 box-presidente"
												style="font-size: 1.1vw;">
												Presidente
												</span>
												<span
												id="status"
												class="text-white mr-2 pl-2 pr-2 box-partido"
												style="border:1px solid white; font-size: 1.1vw;">
												{{ user.category_party.name_party }}
												</span>
												<span v-if="userStatus(user) === 'Presente'"
												id="status"
												class="text-white pl-2 pr-2 box-presente-status"
												style="font-size: 1.1vw;">
												{{ userStatus(user) }}
												</span>
												<span v-if="userStatus(user) === 'Ausente'"
												id="status"
												class="text-white pl-2 pr-2 box-ausente-status"
												style="font-size: 1.1vw;">
												{{ userStatus(user) }}
												</span>
											</div>

										</div>
									</div>
								</div>
						</div>
				</div>
				<div class="d-flex w-25" style="margin-right: 0.8rem;">
						<div class="d-grid box-present align-items-center">
							<div class="d-grid boxs">
								<span class="text-white font-Local-number">{{ presentess }}</span>
								<span class="text-white fonts-body">Presentes</span>
							</div>
						</div>
						<div class="d-grid box-ausente align-items-center">
							<div class="d-grid boxs">
								<span class="text-white font-Local-number">{{ ausentess }}</span>
								<span class="text-white fonts-body">Ausentes</span>
							</div>
						</div>
				</div>
			</div>
		</div>
  </div>
</template>
<style>
.boxs{
	margin-top: 0.18rem;
	justify-content: center;
	line-height: 2.5vw;
}

.img_display_presidente{
	width: 7vw;
    height: 7vw;
	border-radius: 50%;
	object-fit: cover;
}

.fonts-body {
    font-family: "Roboto", serif;
    font-size: 1.9vw;
    color: #DEE2E6;
    font-weight: 500 !important;
}

.box-presidente {
	display: flex;
    width: 7vw;
	height: 2vw;
	justify-content: center;
    align-items: center;
	font-family: "Roboto", serif;
	font-weight: 500 !important;
	background-color: white;
	border-radius: 36px;
}

.container-presidente {
	margin-right: 1rem;
	margin-left: 1.5rem;
    background-color: #034680;
	border-radius: 32px;
}

.box-present {
	background-color: #20875b;
	border-radius: 32px;
	margin-right: 0.5rem;
	width: 50%;
	height: auto;
	display: block;
}

.box-ausente {
	background-color: gray;
	border-radius: 32px;
	margin-right: 0.5rem;
	width: 50%;
	height: auto;
	display: block;
}

.font-Local-number{
	font-family: "Roboto", serif;
	font-size: 2.2vw;
	font-weight: 600 !important;
	display: flex;
    align-items: center;
    justify-content: center;
}

</style>