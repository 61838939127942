<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../stores/index';
import LayoutUsersVote from './LayoutVotes/LayoutUsersVote.vue';
import LayoutUsersPresidenteVote from './LayoutVotes/LayoutUsersPresidenteVote.vue';

export default {
	name: 'TvVote',
	components: { LayoutUsersPresidenteVote, LayoutUsersVote},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			category_doc: '',
			autor: '',
			doc_name_reduz: '',
			voted: true,
			users: [],
			us: [],
			User_Tot: [],
			document: [],
			session: {
				name: 'Carregando Informações...',
				id: 9999999,
			},
			user_presidente: [],
			quorum_users: [],
			presentes: 0,
			ausentes: 0,
			total: 0,
			dia: 0,
			mes: '',
			time: '',
			timer_session: 'Aguarde o inicio',
			Favor: 0,
			Contra: 0,
			Absten: 0,
			TotalVote: 0,
			doc_name: 'Aguarde...',
			vote_att: false,
			Loading: true,
		};
	},
	watch: {
		vote_att: function (novoValor, valorAntigo) {
			if (novoValor !== false) {
				this.votedstart();
				this.vote_att = false;
			}
		},
	},

	created () {
		let OnSystem = setInterval(() => {
			if (this.StoreAll.socket !== null) {
				clearInterval(OnSystem);

				this.StoreAll.socket.on('9', (e) => {
					this.vote_att = true;
				});

			}}, 500);
	},

	mounted() {
		this.Get_Quorum_Socket_Tv_vote();
		this.Session_timer();
		this.Hora();
		setInterval(this.Hora, 1000);
	},

	computed: {
		UsersPresente () {
			let Vereadores = this.User_Tot.filter((item) => item[1] === 'P');
			let Presidente = this.user_presidente.filter((item) => item[1] === 'P');
			return Presidente.length + Vereadores.length;
		},
	},

	methods: {
		async Get_Quorum_Socket_Tv_vote() {
			await this.StoreAll.Get_Data();
			await this.StoreAll.GetDocument();
			this.get_expediente();
			this.Dat_doc();
		},

		async get_expediente() {
			try {
				const response = await AxiosAPI.get('/users', {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				});

				this.users = response.data.filter(user => {
					const excludedStatusUser = [2, 3];
					return !excludedStatusUser.includes(user.status_user) && user.user_category_id !== 3;
				});

				this.users2 = response.data.filter(user => user.user_category_id === 3);
				this.user_presidente = this.users2;

				this.Quorum_atual_vote();
			} catch (error) {
				console.log(error);
			}
		},

		async Quorum_atual_vote() {
			await AxiosAPI.get('/quorum-users?quorum_id=' + this.StoreAll.quorum[0].id, {
				headers: {
					authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((response) => {
				this.quorum_users = [];
				let dummy_presentes = 0;
				let dummy_presentess = 0;
				let user_P = [];
				let user_A = [];
				let user_T = [];

				for (let i = 0; i < this.users.length; i++) {
					this.users[i][1] = 'A';
				}

				for (let i = 0; i < response.data.data.length; i++) {
					for (let j = 0; j < this.users.length; j++) {
						if (this.users[j].name == response.data.data[i].user.name) {
							this.users[j][1] = 'P';
							dummy_presentes = dummy_presentes + 1;
							continue;
						}
					}
				}
				for (let i = 0; i < this.user_presidente.length; i++) {
					this.user_presidente[i][1] = 'A';
				}

				for (let i = 0; i < response.data.data.length; i++) {
					for (let j = 0; j < this.user_presidente.length; j++) {
						if (
							this.user_presidente[j].name == response.data.data[i].user.name
						) {
							this.user_presidente[j][1] = 'P';
							dummy_presentess = dummy_presentess + 1;
							continue;
						}
					}
				}

				this.total = this.users.length + this.user_presidente.length;
				this.presentes = dummy_presentes + dummy_presentess;
				this.ausentes = this.total - dummy_presentes - dummy_presentess;
				this.us = this.users;
				this.isQuorumLoaded = true;

				for (let t = 0; t < this.us.length; t++) {
					this.us[t][2] = 'N';
				}
				for (let t = 0; t < this.user_presidente.length; t++) {
					this.user_presidente[t][2] = 'N';
				}

				for (let i = 0; i < this.users.length; i++) {
					if (this.users[i][1] == 'P') {
						user_P.push(this.users[i]);
						user_T.push(this.users[i]);
					}
				}
				for (let i = 0; i < this.users.length; i++) {
					if (this.users[i][1] == 'A') {
						user_A.push(this.users[i]);
						user_T.push(this.users[i]);
					}
				}
				this.User_Tot = user_T;
				this.StoreAll.votacon = true;
				this.Loading = false;
				this.votedstart();
			});


		},

		Session_timer() {
			setTimeout(() => {
				this.Session_timer();
			}, 300);

			if(this.StoreAll.quorum.length <= 0){
				return;
			}else{
				let inicio = new Date(this.StoreAll.quorum[0].created_at).getTime();;
				let fim = new Date().getTime();;
				let diferenca = fim - inicio;
				let hours = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((diferenca % (1000 * 60)) / 1000);
				let timer_session = hours + 'h: ' + minutes + 'm: ' + seconds + 's';
				this.timer_session = timer_session;
			}},

		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mesesEmPortugues = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
				'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
			];
			let mes = mesesEmPortugues[date_time.getMonth()];

			let minutes = date_time.getMinutes().toString().padStart(2, '0');
			let hours = date_time.getHours().toString().padStart(2, '0');
			let seconds = date_time.getSeconds().toString().padStart(2, '0');

			this.time = `${hours}: ${minutes}: ${seconds}`;
			this.dia = dia;
			this.mes = mes;
		},

		Dat_doc() {
			let Co_user = [];

			for (let i = 0; i < this.StoreAll.document.authors.length; i++) {
				Co_user.push(this.StoreAll.document.authors[i].nickname);
			}

			let result = Co_user.join(', ');

			this.category_doc = this.StoreAll.document.document_category.name;
			this.autor = result;
			this.doc_name = this.StoreAll.document.name;
		},

		async votedstart() {
			if (this.StoreAll.document == null) {
				return;
			} else {
				await AxiosAPI.get(
					'/votes?document_id=' +
          this.StoreAll.document.id +
          '&session_id=' +
          this.StoreAll.session.id,
					{
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				).then((response) => {
					let favor = 0;
					let contra = 0;
					let absten = 0;

					this.votados = response.data.data;
					for (let t = 0; t < this.votados.length; t++) {
						for (let j = 0; j < this.us.length; j++) {
							if (this.us[j].name == this.votados[t].user.name) {
								if (this.votados[t].vote_category.id == 1) {
									this.us[j][2] = 'F';
									favor = favor + 1;
								} else if (this.votados[t].vote_category.id == 2) {
									this.us[j][2] = 'C';
									contra = contra + 1;
								} else if (this.votados[t].vote_category.id == 3) {
									this.us[j][2] = 'V';
									absten = absten + 1;
								}
							}
						}
					}

					for (let t = 0; t < this.votados.length; t++) {
						for (let j = 0; j < this.user_presidente.length; j++) {
							if (this.user_presidente[j].name == this.votados[t].user.name) {
								if (this.votados[t].vote_category.id == 1) {
									this.user_presidente[j][2] = 'F';
									favor = favor + 1;
								} else if (this.votados[t].vote_category.id == 2) {
									this.user_presidente[j][2] = 'C';
									contra = contra + 1;
								} else if (this.votados[t].vote_category.id == 3) {
									this.user_presidente[j][2] = 'V';
									absten = absten + 1;
								}
							}
						}
					}
					this.Favor = favor;
					this.Contra = contra;
					this.Absten = absten;
					this.TotalVote = favor + contra + absten;
				});
			}
		},
	},
};
</script>
<template>
  <div>
    <div>
      <div v-if="!this.StoreAll.votacon" class="d-flex justify-content-center">
          <div class="WaitQuorum">
            <span
            class="font_footer_17 d-grid"
            style="transform: translate(-50%, 80%);margin-right: -50%;top: 39%;left: 50%;position: absolute;font-size: 2.5vw;font-weight: 500 !important;color: white;">
            <i class="d-flex justify-content-center fa-solid fa-hourglass-half" style="color:#007AB8"></i>
            Aguardadao início da votação...</span>
          </div>
      </div>

      <div v-if="this.StoreAll.votacon && Loading" class="d-flex justify-content-center">
          <div class="WaitQuorum">
            <span
            class="font_footer_17 d-grid"
            style="transform: translate(-50%, 80%);margin-right: -50%;top: 39%;left: 50%;position: absolute;font-size: 2.5vw;font-weight: 500 !important;color: white;">
            <i class="d-flex justify-content-center fa-solid fa-hourglass-half" style="color:#007AB8"></i>
            Carregando vereadores...</span>
          </div>
      </div>

      <div v-if="this.StoreAll.votacon && !this.StoreAll.vote_secret && !Loading && StoreAll.tvlength === 1" class="d-flex">
          <div class="w-70">
            <LayoutUsersPresidenteVote :UserPresidente = user_presidente />
            <LayoutUsersVote :Users = User_Tot />
          </div>
            <div class="w-30">
              <div class="WaitSubscribe">
                <div class="w-100 d-flex justify-content-center">
                  <div class="d-flex w-90">
                    <div class="d-grid box-votes align-items-center" style="background-color: #198754">
                      <div class="d-grid boxs">
                        <span class="text-white font-Local-number">{{Favor}}</span>
                        <span class="text-white fonts-body" style="font-size:1.5vw">Favor</span>
                      </div>
                    </div>
                    <div class="d-grid box-votes align-items-center" style="background-color: #DC3545">
                      <div class="d-grid boxs">
                        <span class="text-white font-Local-number">{{ Contra }}</span>
                        <span class="text-white fonts-body" style="font-size:1.5vw">Contra</span>
                      </div>
                    </div>
                    <div class="d-grid box-votes align-items-center" style="background-color: #E9ECEF">
                      <div class="d-grid boxs">
                        <span class="text-black font-Local-number">{{ Absten }}</span>
                        <span class="text-black fonts-body" style="font-size:1.5vw">Abst.</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="w-100 d-flex justify-content-center mt-3">
                  <div v-if="Favor + Contra + Absten < UsersPresente" class="w-90 text-center" style="background-color: #034680;border-radius:16px">
                    <span class="fonts-body" style="font-size: 1.5vw; color:#CED4DA">Aguardando {{ UsersPresente - (Favor + Contra + Absten) }} votarem...</span>
                  </div>

                  <div v-else-if="Favor > Contra && Favor > Absten" class="w-90 text-center" style="background-color: #198754;border-radius:16px">
                    <div><span class="fonts-body" style="font-size: 1.9vw; color:#CED4DA">Aprovado</span></div>
                    <div><span class="fonts-body" style="font-size: 1.2vw; color:#CED4DA">Resultado</span></div>
                  </div>

                  <div v-else-if="Contra > Favor && Contra > Absten" class="w-90 text-center" style="background-color: #DC3545;border-radius:16px">
                    <div><span class="fonts-body" style="font-size: 1.9vw; color:#CED4DA">Reprovado</span></div>
                    <div><span class="fonts-body" style="font-size: 1.2vw; color:#CED4DA">Resultado</span></div>
                  </div>

                  <div v-else-if="Absten > Favor && Absten > Contra" class="w-90 text-center" style="background-color: #E9ECEF;border-radius:16px">
                    <div><span class="fonts-body text-black" style="font-size: 1.9vw;">Abstenção</span></div>
                    <div><span class="fonts-body text-black" style="font-size: 1.2vw;">Resultado</span></div>
                  </div>

                  <div v-else class="w-90 text-center" style="background-color: #E9ECEF;border-radius:16px">
                    <div><span class="fonts-body text-black" style="font-size: 1.9vw;">Indefinido</span></div>
                    <div><span class="fonts-body text-black" style="font-size: 1.2vw;">Resultado</span></div>
                  </div>
                </div>

                <div class="w-100 d-flex justify-content-center mt-3">
                  <div class="w-90">
                    <span class="fonts-body text-white" style="font-size: 1.5vw;">
                      {{ doc_name.substring(0, 200) }}{{doc_name.length > 200 ? '...' : ''}}
                    </span>
                  </div>
                </div>

                <div class="w-100 d-flex justify-content-center" style="position: relative;top: 7%;">
                  <div class="w-90">
                    <span class="fonts-body text-white" style="font-size: 1.2vw;">
                      {{ autor }} Autor(es) da materia.
                    </span>
                  </div>
                </div>

            </div>
          </div>
      </div>

      <div v-if="this.StoreAll.votacon && !this.StoreAll.vote_secret && !Loading && StoreAll.tvlength === 2 && StoreAll.fullScren_quorum" class="d-flex">
        <div class="w-100">
          <LayoutUsersPresidenteVote :UserPresidente = user_presidente />
          <LayoutUsersVote :Users = User_Tot />
        </div>
      </div>

      <div v-if="this.StoreAll.votacon && !this.StoreAll.vote_secret && !Loading && StoreAll.tvlength === 2 && StoreAll.fullScren_actions" class="d-flex">
          <div class="w-70">
        <div class="box-documents" style="margin-left: 1%;width: 98%;">
        <div>

          <div style="height: 60vh;">
            <div class="ml-2 mt-3" style="padding-top: 1%;padding-left: 1%;">
              <div>
                <span class="fonts-body" style="font-size: 2vw;">
                  {{ doc_name.substring(0, 400) }}{{doc_name.length > 400 ? '...' : ''}}
                </span>
              </div>
            </div>
          </div>

          <div  style="top: 15px; position: relative;padding-left: 1%;">
            <span class="fonts-body ml-2" style="font-size: 1.5vw;">
                      {{ autor }} Autor(es) da materia.
                    </span>
          </div>
        </div>
      </div>
          </div>
            <div class="w-30">
              <div class="WaitSubscribe">
          <div class="w-100 d-flex justify-content-center mt-3">
                  <div v-if="Favor + Contra + Absten < UsersPresente" class="w-90 text-center" style="margin-top: 30%;background-color: #034680;border-radius:16px">
                    <span class="fonts-body" style="font-size: 2vw; color:#CED4DA">Aguardando {{ UsersPresente - (Favor + Contra + Absten) }} votarem...</span>
                  </div>

                  <div v-else-if="Favor > Contra && Favor > Absten" class="w-90 text-center" style="margin-top: 30%;background-color: #198754;border-radius:16px">
                    <div><span class="fonts-body" style="font-size: 3.9vw; color:#CED4DA">Aprovado</span></div>
                    <div><span class="fonts-body" style="font-size: 1.5vw; color:#CED4DA">Resultado</span></div>
                  </div>

                  <div v-else-if="Contra > Favor && Contra > Absten" class="w-90 text-center" style="margin-top: 30%;background-color: #DC3545;border-radius:16px">
                    <div><span class="fonts-body" style="font-size: 3.9vw; color:#CED4DA">Reprovado</span></div>
                    <div><span class="fonts-body" style="font-size: 1.5vw; color:#CED4DA">Resultado</span></div>
                  </div>

                  <div v-else-if="Absten > Favor && Absten > Contra" class="w-90 text-center" style="margin-top: 30%;background-color: #E9ECEF;border-radius:16px">
                    <div><span class="fonts-body text-black" style="font-size: 3.9vw;">Abstenção</span></div>
                    <div><span class="fonts-body text-black" style="font-size: 1.5vw;">Resultado</span></div>
                  </div>

                  <div v-else class="w-90 text-center" style="margin-top: 30%;background-color: #E9ECEF;border-radius:16px">
                    <div><span class="fonts-body text-black" style="font-size: 3.9vw;">Indefinido</span></div>
                    <div><span class="fonts-body text-black" style="font-size: 1.5vw;">Resultado</span></div>
                  </div>
                </div>

                <div class="w-100 d-flex justify-content-center">
                  <div class="d-flex w-90">
                    <div class="d-grid box-votes align-items-center" style="background-color: #198754; margin-top: 1rem;">
                      <div class="d-grid boxs">
                        <span class="text-white font-Local-number">{{Favor}}</span>
                        <span class="text-white fonts-body" style="font-size:1.5vw">Favor</span>
                      </div>
                    </div>
                    <div class="d-grid box-votes align-items-center" style="background-color: #DC3545; margin-top: 1rem;">
                      <div class="d-grid boxs">
                        <span class="text-white font-Local-number">{{ Contra }}</span>
                        <span class="text-white fonts-body" style="font-size:1.5vw">Contra</span>
                      </div>
                    </div>
                    <div class="d-grid box-votes align-items-center" style="background-color: #E9ECEF; margin-top: 1rem;">
                      <div class="d-grid boxs">
                        <span class="text-black font-Local-number">{{ Absten }}</span>
                        <span class="text-black fonts-body" style="font-size:1.5vw">Abst.</span>
                      </div>
                    </div>
                  </div>
                </div>

          <div class="w-100 d-flex justify-content-center mt-3">
                  <div class="w-90 text-center" style="background-color: #034680;border-radius:16px">
                    <div><span class="fonts-body text-white" style="font-size: 1.5vw;">Total de votos: {{Favor + Contra + Absten}}.</span></div>
                  </div>
                </div>

            </div>
          </div>
      </div>

      <div v-if="this.StoreAll.votacon && this.StoreAll.vote_secret && !Loading && StoreAll.tvlength === 1" class="d-flex">
            <div class="w-70">
              <div class="box-documents" style="margin-left: 1%;width: 98%;">
              <div>

                <div style="height: 60vh;">
                  <div class="ml-2 mt-3" style="padding-top: 1%;padding-left: 1%;">
                    <div>
                      <span class="fonts-body" style="font-size: 2vw;">
                        {{ doc_name.substring(0, 400) }}{{doc_name.length > 400 ? '...' : ''}}
                      </span>
                    </div>
                  </div>
                </div>

                <div  style="top: 15px; position: relative;padding-left: 1%;">
                  <span class="fonts-body ml-2" style="font-size: 1.5vw;">
                            {{ autor }} Autor(es) da materia.
                          </span>
                </div>
              </div>
            </div>
          </div>
            <div class="w-30">
              <div class="WaitSubscribe">
          <div class="w-100 d-flex justify-content-center mt-3">
                  <div v-if="Favor + Contra + Absten < UsersPresente" class="w-90 text-center" style="margin-top: 30%;background-color: #034680;border-radius:16px">
                    <span class="fonts-body" style="font-size: 2vw; color:#CED4DA">Aguardando {{ UsersPresente - (Favor + Contra + Absten) }} votarem...</span>
                  </div>

                  <div v-else-if="Favor > Contra && Favor > Absten" class="w-90 text-center" style="margin-top: 30%;background-color: #198754;border-radius:16px">
                    <div><span class="fonts-body" style="font-size: 3.9vw; color:#CED4DA">Aprovado</span></div>
                    <div><span class="fonts-body" style="font-size: 1.5vw; color:#CED4DA">Resultado</span></div>
                  </div>

                  <div v-else-if="Contra > Favor && Contra > Absten" class="w-90 text-center" style="margin-top: 30%;background-color: #DC3545;border-radius:16px">
                    <div><span class="fonts-body" style="font-size: 3.9vw; color:#CED4DA">Reprovado</span></div>
                    <div><span class="fonts-body" style="font-size: 1.5vw; color:#CED4DA">Resultado</span></div>
                  </div>

                  <div v-else-if="Absten > Favor && Absten > Contra" class="w-90 text-center" style="margin-top: 30%;background-color: #E9ECEF;border-radius:16px">
                    <div><span class="fonts-body text-black" style="font-size: 3.9vw;">Abstenção</span></div>
                    <div><span class="fonts-body text-black" style="font-size: 1.5vw;">Resultado</span></div>
                  </div>

                  <div v-else class="w-90 text-center" style="margin-top: 30%;background-color: #E9ECEF;border-radius:16px">
                    <div><span class="fonts-body text-black" style="font-size: 3.9vw;">Indefinido</span></div>
                    <div><span class="fonts-body text-black" style="font-size: 1.5vw;">Resultado</span></div>
                  </div>
                </div>

                <div class="w-100 d-flex justify-content-center">
                  <div class="d-flex w-90">
                    <div class="d-grid box-votes align-items-center" style="background-color: #198754; margin-top: 1rem;">
                      <div class="d-grid boxs">
                        <span class="text-white font-Local-number">{{Favor}}</span>
                        <span class="text-white fonts-body" style="font-size:1.5vw">Favor</span>
                      </div>
                    </div>
                    <div class="d-grid box-votes align-items-center" style="background-color: #DC3545; margin-top: 1rem;">
                      <div class="d-grid boxs">
                        <span class="text-white font-Local-number">{{ Contra }}</span>
                        <span class="text-white fonts-body" style="font-size:1.5vw">Contra</span>
                      </div>
                    </div>
                    <div class="d-grid box-votes align-items-center" style="background-color: #E9ECEF; margin-top: 1rem;">
                      <div class="d-grid boxs">
                        <span class="text-black font-Local-number">{{ Absten }}</span>
                        <span class="text-black fonts-body" style="font-size:1.5vw">Abst.</span>
                      </div>
                    </div>
                  </div>
                </div>

          <div class="w-100 d-flex justify-content-center mt-3">
                  <div class="w-90 text-center" style="background-color: #034680;border-radius:16px">
                    <div><span class="fonts-body text-white" style="font-size: 1.5vw;">Total de votos: {{Favor + Contra + Absten}}.</span></div>
                  </div>
                </div>

            </div>
          </div>
      </div>

      <div v-if="this.StoreAll.votacon && this.StoreAll.vote_secret && !Loading && StoreAll.tvlength === 2 && StoreAll.fullScren_quorum" class="d-flex justify-content-center">
				<div class="WaitExplanation">
					<span
					class="font_footer_17 d-grid"
					style="transform: translate(-50%, 80%);margin-right: -50%;top: 35%;left: 50%;position: absolute;font-size: 2.5vw;font-weight: 500 !important;color: white;">
					<i class="d-flex justify-content-center fa-solid fa-user-secret" style="color:#007AB8"></i>
					Votação secreta</span>
				</div>
			</div>

      <div v-if="this.StoreAll.votacon && this.StoreAll.vote_secret && !Loading && StoreAll.tvlength === 2 && StoreAll.fullScren_actions" class="d-flex">
          <div class="w-70">
        <div class="box-documents" style="margin-left: 1%;width: 98%;">
        <div>

          <div style="height: 60vh;">
            <div class="ml-2 mt-3" style="padding-top: 1%;padding-left: 1%;">
              <div>
                <span class="fonts-body" style="font-size: 2vw;">
                  {{ doc_name.substring(0, 400) }}{{doc_name.length > 400 ? '...' : ''}}
                </span>
              </div>
            </div>
          </div>

          <div  style="top: 15px; position: relative;padding-left: 1%;">
            <span class="fonts-body ml-2" style="font-size: 1.5vw;">
                      {{ autor }} Autor(es) da materia.
                    </span>
          </div>
        </div>
      </div>
          </div>
            <div class="w-30">
              <div class="WaitSubscribe">
          <div class="w-100 d-flex justify-content-center mt-3">
                  <div v-if="Favor + Contra + Absten < UsersPresente" class="w-90 text-center" style="margin-top: 30%;background-color: #034680;border-radius:16px">
                    <span class="fonts-body" style="font-size: 2vw; color:#CED4DA">Aguardando {{ UsersPresente - (Favor + Contra + Absten) }} votarem...</span>
                  </div>

                  <div v-else-if="Favor > Contra && Favor > Absten" class="w-90 text-center" style="margin-top: 30%;background-color: #198754;border-radius:16px">
                    <div><span class="fonts-body" style="font-size: 3.9vw; color:#CED4DA">Aprovado</span></div>
                    <div><span class="fonts-body" style="font-size: 1.5vw; color:#CED4DA">Resultado</span></div>
                  </div>

                  <div v-else-if="Contra > Favor && Contra > Absten" class="w-90 text-center" style="margin-top: 30%;background-color: #DC3545;border-radius:16px">
                    <div><span class="fonts-body" style="font-size: 3.9vw; color:#CED4DA">Reprovado</span></div>
                    <div><span class="fonts-body" style="font-size: 1.5vw; color:#CED4DA">Resultado</span></div>
                  </div>

                  <div v-else-if="Absten > Favor && Absten > Contra" class="w-90 text-center" style="margin-top: 30%;background-color: #E9ECEF;border-radius:16px">
                    <div><span class="fonts-body text-black" style="font-size: 3.9vw;">Abstenção</span></div>
                    <div><span class="fonts-body text-black" style="font-size: 1.5vw;">Resultado</span></div>
                  </div>

                  <div v-else class="w-90 text-center" style="margin-top: 30%;background-color: #E9ECEF;border-radius:16px">
                    <div><span class="fonts-body text-black" style="font-size: 3.9vw;">Indefinido</span></div>
                    <div><span class="fonts-body text-black" style="font-size: 1.5vw;">Resultado</span></div>
                  </div>
                </div>

                <div class="w-100 d-flex justify-content-center">
                  <div class="d-flex w-90">
                    <div class="d-grid box-votes align-items-center" style="background-color: #198754; margin-top: 1rem;">
                      <div class="d-grid boxs">
                        <span class="text-white font-Local-number">{{Favor}}</span>
                        <span class="text-white fonts-body" style="font-size:1.5vw">Favor</span>
                      </div>
                    </div>
                    <div class="d-grid box-votes align-items-center" style="background-color: #DC3545; margin-top: 1rem;">
                      <div class="d-grid boxs">
                        <span class="text-white font-Local-number">{{ Contra }}</span>
                        <span class="text-white fonts-body" style="font-size:1.5vw">Contra</span>
                      </div>
                    </div>
                    <div class="d-grid box-votes align-items-center" style="background-color: #E9ECEF; margin-top: 1rem;">
                      <div class="d-grid boxs">
                        <span class="text-black font-Local-number">{{ Absten }}</span>
                        <span class="text-black fonts-body" style="font-size:1.5vw">Abst.</span>
                      </div>
                    </div>
                  </div>
                </div>

          <div class="w-100 d-flex justify-content-center mt-3">
                  <div class="w-90 text-center" style="background-color: #034680;border-radius:16px">
                    <div><span class="fonts-body text-white" style="font-size: 1.5vw;">Total de votos: {{Favor + Contra + Absten}}.</span></div>
                  </div>
                </div>

            </div>
          </div>
      </div>

    </div>

    <div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
	style="bottom: 15px; position: fixed;">
		<div class="d-flex align-items-center w-20">
			<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
			<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
		</div>
		<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
			<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
		</div>
		<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
			style="font-size: 1.8vw;">
			<i class="fa-solid fa-clock mr-2"></i>
			Tempo de sessão
			<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
		</div>
	</div>

  </div>
</template>
<style>
.box-votes {
  margin-top: 2rem;
  border-radius: 16px;
  margin-right: 0.5rem;
  width: 50%;
  height: 13vh;
  display: block;
}
.text-black {
  color: black;
}

.display_fast_vote {
  width: clamp(0.1vw, 8vw, 300px);
  height: clamp(0.1vw, 8vw, 300px);
}

.display_type_doc {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, 75%);
  }

  .footers {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px;
  text-align: center;
}

@media only screen and (max-width: 1600px) {
  .display_fast_vote {
    width: clamp(0.1vw, 7.6vw, 300px);
    height: clamp(0.1vw, 7.6vw, 300px);
  }
  .display_type_doc {
    top: 33%;
  }
}

@media only screen and (max-width: 1380px) {
  .display_fast_vote {
    width: clamp(0.1vw, 7vw, 300px);
    height: clamp(0.1vw, 7vw, 300px);
  }
  .display_type_doc {
    top: 33%;
  }
}

@media only screen and (max-width: 1280px) {
  .display_fast_vote {
    width: clamp(0.1vw, 6.8vw, 300px);
    height: clamp(0.1vw, 6.8vw, 300px);
  }
}
</style>
