<script>
import ModalBigger from './ModalBigger.vue';
import {AxiosAPI} from '@/axios';
import { useBusStore } from '../../../stores/index';
import { useMainStore } from '../../../stores/index';
import moment from 'moment';
export default {
	name: 'ModalInscrito',
	components : {
		ModalBigger
	},
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			tribuna_vereador: [],
			t: null,
			socket_ids:[],
			cronometro: 'Aguarde...',
			cronometros: '',
			seconds: 0,
			minutes: 2,
			c: 0,
		};
	},

	created () {
		let OnSystem = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSystem);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 11) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.GetTribunaUsers_websocket();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 12) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.close_tribuna_end();
							this.socket_ids.push(e.event_id);
						}
					}
				});
			}}, 500);
	},

	methods: {
		openModal2() {
			this.$refs.viewDocument.openModal();
			this.closemodal();
			localStorage.setItem('timer', 'ok');
			/* if(this.StoreAll.user.user_category_id == 2){
				this.timerstart();
			} */
			this.seconds = this.StoreAll.timers[0].Subscriber_tribune.seconds;
			this.minutes = this.StoreAll.timers[0].Subscriber_tribune.minutes;
			this.GetTribunaUsers_websocket();
			this.calcularTempoRestante();
		},
		finallyloading() {
			document.getElementById('loads').style.display = 'flex';
			document.getElementById('sim_tribune').style.display = 'none';
			document.getElementById('nao_tribune').style.display = 'none';
			document.getElementById('fim_tribune').style.display = 'none';
		},
		calcularTempoRestante() {
			let startTime = moment(this.StoreAll.tribunaData[0].created_at);
			let endTime = moment(startTime)
				.add(this.StoreAll.timers[0].Subscriber_tribune.minutes, 'minutes')
				.add(this.StoreAll.timers[0].Subscriber_tribune.seconds, 'seconds');
			let now = moment();

			let diff = endTime.diff(now, 'seconds');

			if (diff <= 0) {
				this.cronometros = 'Tempo encerrado!';
				return;
			}

			let minutes = Math.floor(diff / 60);
			let seconds = diff % 60;

			this.cronometros = `${this.padZero(minutes)}:${this.padZero(seconds)}`;
			this.StoreAll.cronometro_tribuna = this.cronometros;

			setTimeout(this.calcularTempoRestante, 1000);
		},

		padZero(number) {
			return number < 10 ? '0' + number : number;
		},

		Verify () {
			return new Promise((resolve) => {
				AxiosAPI.get('/tribune-users?tribune_id='+this.StoreAll.tribuna_id,{
					headers: {
						Authorization: 'Bearer '+ this.StoreAll.accessToken
					}
				}).then((response) => {
					resolve(response.data.data);
				});
			});
		},

		async subscription(id){
			let Subscriber = [];
			const dataCheck = await this.Verify();

			dataCheck.forEach(element => {
				Subscriber.push(element.user);
			});

			const hasSubscribe = Subscriber.some(Check => Check.id === this.StoreAll.user.id);

			if (!hasSubscribe) {
				if(id == 1){

					const inscrit = {
						tribune_id: this.StoreAll.tribuna_id,
						users_id: [this.StoreAll.user.id],
						status_fala: 1
					};

					AxiosAPI.post('/tribune-users',inscrit,{
						headers: {
							'Content-Type': 'application/json',
							Authorization: 'Bearer '+ this.StoreAll.accessToken
						}
					}).then((response)=>{if(response.status == 200){
						this.StoreAll.in_tribuna = true;
						this.Bus.tribuneEmitModalOne = true;
					}});

					if(this.StoreAll.user.user_category_id != 3){
						this.$refs.viewDocument.closeModal();
					}

				}else if(id == 2){
					this.StoreAll.in_tribuna = true;
					this.Bus.tribuneEmitModalTwo = true;

					if(this.StoreAll.user.user_category_id != 3){
						this.$refs.viewDocument.closeModal();
					}
				}
			} else {
				this.Bus.tribuneEmitModalThree = true;
			}
		},
		closemodal(){
			if(this.StoreAll.status_tribune == 2){
				this.closers();
				localStorage.removeItem('timer');
				this.$refs.viewDocument.closeModal();
			}else{
				setTimeout(() => {
					this.closemodal();
				}, 2000);
			}

		},
		closers(){
			this.$refs.viewDocument.closeModal();
		},

		close_tribuna_end(){
			try{
				this.$refs.viewDocument.closeModal();
			}catch{
				console.log('já fechado');
			}
		},

		async GetTribunaUsers_websocket(){
			if(this.StoreAll.user.user_category_id != 1){
				if(this.StoreAll.tribuna_id == 0){
					return;
				}else{
					await AxiosAPI.get('/tribune-users?tribune_id='+this.StoreAll.tribuna_id+'&status_fala=1',{
						headers: {
							Authorization: 'Bearer '+ this.StoreAll.accessToken
						}
					}).then((response)=>{
						this.tribuna_vereador = response.data.data;
					});
				}}else{return;}
		},
	},
};
</script>
<template>
  <div>
  <ModalBigger v-if="this.StoreAll.user.user_category_id == 3" ref="viewDocument">
         <template #header>
          <div class="status_label">
                <strong><span class="ml mr title">Inscrição para tribuna - presidente</span></strong>
            </div>
          <div class="flex-start">

                          <button
                          id="close"
                          type="button"
                          class="close"
                          @click="$refs.viewDocument.closeModal();"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
          </div>
        </template>

        <template #body>
          <div class="d-flex justify-content-around">
            <div class="justify-content-center">
              <div class="d-flex justify-content-center text-uppercase text-black text-lg font-weight-bolder mb-2">
                <span> Deseja se inscrever ?</span>
            </div>
          <button type="button" class="btn btn-success mb-1 mr-3"
          style="width: 150px; height: 60px;" @click="subscription(1); $refs.viewDocument.closeModal();">Sim</button>
          <button type="button" class="btn btn-danger mb-1"
          style="width: 150px; height: 60px;" @click="subscription(2); $refs.viewDocument.closeModal();">Não</button>
          </div>
          <div class="justify-content-center" >
            <span class="text-uppercase text-black text-lg font-weight-bolder">Vereadores inscritos</span>
            <div v-for="users in tribuna_vereador" :key="users">
              <span class="text-uppercase text-black font-weight-bolder"> {{users.user.nickname}} </span>
            </div>
          </div>
          </div>
          <div class="d-flex justify-content-center">

            <span class="text-uppercase text-black text-2xl font-weight-bolder mt-5"> Tempo para encerramento das inscrições </span>
          </div>
          <div class="d-flex justify-content-center">
            <span v-if="this.StoreAll.cronometro_tribuna != '0 : 01'" id="timerz" class="text-uppercase text-black text-3xl font-weight-bolder mt-2"> {{this.cronometros}} </span>
             <span id="timerzz" class="text-uppercase text-black text-3xl font-weight-bolder mt-2"></span>
          </div>
        </template>
        <template #footer>

        </template>
    </ModalBigger>
   <ModalBigger v-if="this.StoreAll.user.user_category_id == 2" ref="viewDocument">
        <template #header>
          <div class="status_label">
                <strong><span class="ml mr title">Inscrição para tribuna - Vereador</span></strong>
            </div>
          <div class="flex-start">

                          <button
                          id="close"
                          type="button"
                          class="close"
                          @click="$refs.viewDocument.closeModal()"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
          </div>
        </template>

        <template #body>

           <div class="d-flex justify-content-center">
            <button type="button" class="btn btn-success mb-1  mr-3"
            style="width: 150px; height: 60px;" @click="subscription(1); $refs.viewDocument.closeModal()">Sim</button>

            <button type="button" class="btn btn-danger mb-1"
            style="width: 150px; height: 60px;" @click="subscription(2); $refs.viewDocument.closeModal()">Não</button>
          </div>

          <div class="d-flex justify-content-center">
            <span class="text-uppercase text-black text-2xl font-weight-bolder mt-5"> Tempo para encerramento das inscrições </span>
          </div>
          <div class="d-flex justify-content-center">
            <span v-if="this.StoreAll.cronometro_tribuna != '0 : 01'" id="timerz" class="text-uppercase text-black text-3xl font-weight-bolder mt-2"> {{this.cronometros}} </span>
             <span id="timerzz" class="text-uppercase text-black text-3xl font-weight-bolder mt-2"></span>
          </div>

        </template>
        <template #footer>

        </template>
    </ModalBigger>

    <div
                  class="modal fade tribunaconf"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="mySmallModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-sm">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h6>STATUS INSCRIÇÃO DA TRIBUNA</h6>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          @click="finallyloading()"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>

                      <div class="modal-body">
                        <div
                          id="loads"
                          class="justify-content-center"
                          style="display: none"
                        >
                          <div class="spinner-grow text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-secondary" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-success" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-danger" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-warning" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-info" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                          <div class="spinner-grow text-dark" role="status">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>

                        <div
                          id="sim_tribune"
                          class="justify-content-center"
                          style="display: none"
                        >
                          <span
                            class="
                              justify-content-center
                              text-uppercase
                              font-weight-bold
                            "
                            style="font-size: 13px; color: green"
                          >
                            Você foi inscrito na tribuna!
                          </span>
                        </div>

                        <div
                          id="nao_tribune"
                          class="justify-content-center"
                          style="display: none"
                        >
                          <span
                            class="
                              justify-content-center
                              text-uppercase
                              font-weight-bold
                            "
                            style="font-size: 13px; color: green"
                          >
                            Não adicionado a tribuna.
                          </span>
                        </div>

                         <div
                          id="fim_tribune"
                          class="justify-content-center"
                          style="display: none"
                        >
                          <span
                            class="
                              justify-content-center
                              text-uppercase
                              font-weight-bold
                            "
                            style="font-size: 13px; color: green"
                          >
                            Inscrições finalizadas!
                          </span>
                        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <style>
  .swal2-container {
    z-index: 10001 !important;
  }
  .close{
    font-size: 2.3rem !important;
  }
  </style>