<script>
import { AxiosAPI } from '@/axios';
import sound from '@/assets/sound/fim.mp3';
import { useMainStore, useBusStore } from '../../stores/index';
import LayoutUsersTribuna from './LayoutTribuna/LayoutUsersTribuna.vue';
import moment from 'moment';
export default {
	name: 'TvTribuna',
	components: { LayoutUsersTribuna },
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		StoreAll.User();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			Subscribe_seconds: 0,
			Subscribe_minutes: 0,
			seconds: 0,
			minutes: 0,
			SubscribeTimer: true,
			timerSubscribeEnd: false,
			Aparte_seconds: 2,
			Aparte_minutes: 0,
			Aparte_minutes_reduze: 0,
			Aparte_seconds_reduze: 2,
			Timer_Extra_Vereador_minutes: 0,
			Timer_Extra_Vereador_seconds: 2,
			Timer_Extra_Convidado_minutes: 0,
			minutes_lider: 0,
			seconds_lider: 0,
			Timer_Extra_Convidado_seconds: 2,
			minutes_convidado:0,
			seconds_convidado:2,
			minutes_prefeitura: 0,
			seconds_prefeitura: 2,
			tribuna_id: 0,
			fala: true,
			tribuna: true,
			voted: false,
			legendavote: false,
			tribruna_vereadores: [],
			tribruna_vereadores_aparte: [],
			Apartes: '',
			timer_start: false,
			cronometro: 'Aguarde',
			cronometros: '2: 00',
			Aparte: '3:00',
			convidado: null,
			dia: 0,
			mes: '',
			time: '',
			timer_session: 'Aguarde o inicio',
			fmenor: false,
			TimersConf: false,
			SoundGo: false,
			Loading: true,
			timerOn: false,
			timerOnAparte: false,
			TimerOver: false,
			TimerOverAparte: false,
			socket_ids: [],
			textTimerDisplay: 'none',
			textTimerColor: '#ADB5BD',
			textTimer: '',
			timerCronometroDisplay: 'none',
			timerCronometroColor: 'white',
			timerCronometroSize: '5.9vw',
			minutesToProgressBar: 0,
			secondsToProgressBar: 0,
			Aparte_secondsToProgress: 0,
			Aparte_minutesToProgress: 0,
			cronometross: '',
		};
	},

	watch: {
		SoundGo: function (newValue, oldPath) {
			if (newValue === true) {
				this.Sound();
				this.SoundGo = false;
			}
		},
		TimerOver: function (newValue, oldPath) {
			if (newValue === true) {
				this.TimerOver = false;
				this.Sound();
				this.FinishUserTalk();
			}
		},
		TimerOverAparte: function (newValue, oldPath) {
			if (newValue === true) {
				this.TimerOverAparte = false;
				this.Sound();
				this.FinishTalkAparte();
			}
		},

	},

	beforeCreate() {
		let verificarTimer = setInterval(() => {
			if (this.StoreAll.timers !== null) {
				clearInterval(verificarTimer);

				/* Inscrição */
				/* Timer vereador */
				this.minutes = this.StoreAll.timers[0].Vereador_tribune.minutes;
				this.seconds = this.StoreAll.timers[0].Vereador_tribune.seconds;

				/* Timer vereador lider */
				this.minutes_lider = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
				this.seconds_lider = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;

				/* Timer convidado */
				this.minutes_convidado = this.StoreAll.timers[0].Convidado_tribune.minutes;
				this.seconds_convidado = this.StoreAll.timers[0].Convidado_tribune.seconds;

				/* Timer prefeitura */
				this.minutes_prefeitura = this.StoreAll.timers[0].Prefeitura_tribune.minutes;
				this.seconds_prefeitura = this.StoreAll.timers[0].Prefeitura_tribune.seconds;

				/* Timer apartiamento */
				this.Aparte_minutes = this.StoreAll.timers[0].Apartiamento_tribune.minutes;
				this.Aparte_seconds = this.StoreAll.timers[0].Apartiamento_tribune.seconds;

				/* Time apartiamento reduzido */
				this.Aparte_minutes_reduze = this.StoreAll.timers[0].Apartiamento_reduzido_tribune.minutes;
				this.Aparte_seconds_reduze = this.StoreAll.timers[0].Apartiamento_reduzido_tribune.seconds;

				/* Timer extra */

				this.Timer_Extra_Vereador_minutes = this.StoreAll.timers[0].Extra_vereador.minutes;
				this.Timer_Extra_Vereador_seconds = this.StoreAll.timers[0].Extra_vereador.seconds;

				/* Timer extra Convidado */

				this.Timer_Extra_Convidado_minutes = this.StoreAll.timers[0].Extra_convidado.minutes;
				this.Timer_Extra_Convidado_seconds = this.StoreAll.timers[0].Extra_convidado.seconds;

				this.Subscribe_seconds =  this.StoreAll.timers[0].Subscriber_tribune.seconds;
				this.Subscribe_minutes = this.StoreAll.timers[0].Subscriber_tribune.minutes;

				this.TimersConf = true;
			}
		}, 500);
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 10) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 11) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 12) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOn();
							this.force_stop_time_inscrito();
							this.handleTimerEnd();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 13) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribuna_of();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 14) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}
						else {
							this.Users_Tribuna();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 15) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Users_Tribuna();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 16) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Users_Tribuna();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 17) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Users_Tribuna();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 18) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Addtimer();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 19) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.AparteOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 20) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.AparteOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 35) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOn();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 36) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.tribunaOn();
							this.socket_ids.push(e.event_id);
						}
					}
				});
			}}, 500);
	},
	computed: {
		orderedTribunaVereadores() {
			let ordem = this.tribruna_vereadores.filter((item) => item.state !== 'F' && item.state !== 'VP');
			ordem.slice().sort((a, b) => a.position - b.position);
			return ordem;
		},

		TalkNow() {
			let list = this.tribruna_vereadores.some((item) => item.state === 'F' || item.state === 'VP');
			return list;
		},

		TalkNowOneTv() {
			let result;
			let list1 = this.tribruna_vereadores_aparte.some((item) => item.state === 'APT');
			let list2 = this.tribruna_vereadores.some((item) => item.state === 'F' || item.state === 'VP');

			if (list1 && list2) {
				result = 1;
			} else if (!list1 && list2) {
				result = 2;
			} else if (list1 && !list2) {
				result = 3;
			} else if (!list1 && !list2) {
				result = 4;
			}
			return result;
		}
	},

	mounted() {
		this.GetSession();
	},
	methods: {

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.session = this.StoreAll.session; this.session_status = this.StoreAll.session.session_status.id;
			this.session_id = this.StoreAll.session.id; this.session_status_name = this.StoreAll.session.session_status.name;
			this.tribunaOn();
			this.status_timer();
			this.Session_timer();
			this.Hora();
			setInterval(this.Hora, 1000);
		},

		Session_timer() {
			setTimeout(() => {
				this.Session_timer();
			}, 300);

			if(this.StoreAll.quorum.length <= 0){
				return;
			}else{
				let inicio = new Date(this.StoreAll.quorum[0].created_at).getTime();;
				let fim = new Date().getTime();;
				let diferenca = fim - inicio;
				let hours = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((diferenca % (1000 * 60)) / 1000);
				let timer_session = hours + 'h: ' + minutes + 'm: ' + seconds + 's';
				this.timer_session = timer_session;
			}},

		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mesesEmPortugues = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
				'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
			];
			let mes = mesesEmPortugues[date_time.getMonth()];

			let minutes = date_time.getMinutes().toString().padStart(2, '0');
			let hours = date_time.getHours().toString().padStart(2, '0');
			let seconds = date_time.getSeconds().toString().padStart(2, '0');

			this.time = `${hours}: ${minutes}: ${seconds}`;
			this.dia = dia;
			this.mes = mes;
		},

		async tribunaOn() {
			if (this.voted == false) {
				if(this.StoreAll.tribuna == false){
					return;
				}else{
					await AxiosAPI.get('/tribune?quorum_id=' + this.StoreAll.quorum[0].id, {
						headers: {
							Authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}).then((response) => {
						this.tribuna_id = response.data.data[0].id;
						this.Users_Tribuna();
						if (response.data.data[0].status_tribune == 1 || response.data.data[0].status_tribune == 2) {
							this.tribuna = true;
							this.StoreAll.tribuna = true;
							if (response.data.data[0].status_tribune == 2) {
								this.timerSubscribeEnd = true;
								this.StoreAll.timerSubscribeEnd = true;
							}
						} else {
							this.tribuna = false;
							this.StoreAll.tribuna = false;
							return;
						}
					});
				}}
		},

		tribuna_of(){
			this.StoreAll.tribuna = false;
		},

		Sound () {
			let audio = new Audio(sound);
			audio.play();
		},

		calcularTempoRestante() {
			if (this.StoreAll.tribunaData !== null) {
				let startTime = moment(this.StoreAll.tribunaData[0].created_at);
				let endTime = moment(startTime)
					.add(this.StoreAll.timers[0].Subscriber_tribune.minutes, 'minutes')
					.add(this.StoreAll.timers[0].Subscriber_tribune.seconds, 'seconds');
				let now = moment();

				let diff = endTime.diff(now, 'seconds');

				if (diff <= 0) {
					this.timerCronometroColor = '#DC3545';
					this.timerCronometroSize = '2.9vw';
					this.cronometross = 'Tempo encerrado!';
					return;
				}

				let minutes = Math.floor(diff / 60);
				let seconds = diff % 60;

				this.cronometross = `${this.padZero(minutes)}:${this.padZero(seconds)}`;

				if (!this.StoreAll.fullScren_quorum && document.querySelector('.progress-bar') !== null) {

					let totalRemainingSeconds = minutes * 60 + seconds;

					let totalInitialSeconds = (this.StoreAll.timers[0].Subscriber_tribune.minutes * 60 + this.StoreAll.timers[0].Subscriber_tribune.seconds);

					let percentageRemaining = (totalRemainingSeconds / totalInitialSeconds) * 100;
					console.log(percentageRemaining);
					if (percentageRemaining < 1.3) {
						percentageRemaining = 0;
					}
					document.querySelector('.progress-bar').style.width = percentageRemaining + '%';

					if (totalRemainingSeconds < 60) {
						this.timerCronometroColor = '#DC3545';
						document.querySelector('.progress-bar').style.backgroundColor = '#DC3545';
					} else {
						this.timerCronometroColor = 'white';
						document.querySelector('.progress-bar').style.backgroundColor = '#198754';
					}
				}
			} else {
				this.cronometross = 'Aguarde...';
			}
		},

		padZero(number) {
			return number < 10 ? '0' + number : number;
		},

		async status_timer(){
			await AxiosAPI.get('/tribune?quorum_id=' + this.StoreAll.quorum[0].id, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((response) => {
				if (response.data.data[0].status_tribune === '1') {
					this.calcularTempoRestante();
					let verificarTimer = setInterval(() => {
						if (this.TimersConf === true) {
							clearInterval(verificarTimer);
							this.timer_start = true;
							this.tempoinscrito();
						}
					}, 500);
				}else{
					this.timer_start = false;
				}
			});
		},

		force_stop_time_inscrito(){
			this.timer_start = false;
		},

		tempoinscrito() {
			if (!this.StoreAll.tribuna) {
				return;
			}

			setTimeout(() => {
				this.tempoinscrito();
			}, 1000);


			if (!this.timer_start) {
				this.hideTimerElements();
				return;
			}

			this.showTimerElements();

			if (this.Subscribe_seconds <= 0 && this.Subscribe_minutes <= 0) {
				this.handleTimerEnd();
			} else {
				/* this.updateTimer(); */
				this.calcularTempoRestante();
			}
		},

		hideTimerElements() {
			this.timerCronometroDisplay = 'none';
			this.textTimerDisplay = 'none';
		},

		showTimerElements() {
			this.timerCronometroDisplay = 'flex';
			this.timerCronometroColor = 'white';
			this.textTimerDisplay = 'flex';
			this.textTimerColor = '#ADB5BD';
			this.textTimer = 'Tempo para encerramento das inscrições.';
		},

		handleTimerEnd() {
			this.Subscribe_seconds = 0;
			this.Subscribe_minutes = 0;
			this.cronometros = '';
			this.timerCronometroDisplay = 'none';
			this.textTimerColor = '#FF0000';
			this.textTimer = 'Tempo para inscrições encerrado';
			if (this.timerSubscribeEnd === false) {
				this.timerSubscribeEnd = true;
				this.StoreAll.timerSubscribeEnd = true;
				this.StopSubscribre();
			}

		},

		updateTimer() {
			let sec = this.Subscribe_seconds;
			let min = this.Subscribe_minutes;

			sec--;

			if (sec < 0) {
				sec = 59;
				min--;

				if (min < 0) {
					min = 0;
				}
			}

			this.Subscribe_seconds = sec;
			this.Subscribe_minutes = min;

			if (sec <= 9) {
				sec = '0' + sec;
			}

			if (min <= 9) {
				min = '0' + min;
			}

			if (min == 0 && sec <= 59) {
				this.timerCronometroColor = '#DC3545';
			}

			this.cronometros = min + ' : ' + sec;

			if (!this.StoreAll.fullScren_quorum) {
				let totalRemainingSeconds = this.Subscribe_minutes * 60 + this.Subscribe_seconds;

				let totalInitialSeconds = (this.StoreAll.timers[0].Subscriber_tribune.minutes * 60 + this.StoreAll.timers[0].Subscriber_tribune.seconds + 1);

				let percentageRemaining = (totalRemainingSeconds / totalInitialSeconds) * 100;

				document.querySelector('.progress-bar').style.width = percentageRemaining + '%';

				if (totalRemainingSeconds < 60) {
					document.querySelector('.progress-bar').style.backgroundColor = '#DC3545';
				} else {
					document.querySelector('.progress-bar').style.backgroundColor = '#198754';
				}
			}
		},


		async StopSubscribre () {
			/* CAMARA DE CAMPO GRANDE */
			if (this.StoreAll.host == 'campogrande' && !this.timerSubscribeEnd) {
				await AxiosAPI.put('/tribune/' + this.tribuna_id, {
					status_tribune: 2
				}, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((response) => {
					if (response.status == 200) {
						this.tribunaOn();
						this.force_stop_time_inscrito();
					}
				});
			}
		},

		async Users_Tribuna() {
			const response = await AxiosAPI.get(`/tribune-users?tribune_id=${this.tribuna_id}`, {
				headers: {
					Authorization: `Bearer ${this.StoreAll.accessToken}`,
				},
			});
			const responseData = response.data.data;

			const tribruna_vereadores = responseData.slice();
			const ArrayLessEach = [];

			let verificarTimer = setInterval(() => {
				if (this.StoreAll.timers !== null) {
					clearInterval(verificarTimer);

					tribruna_vereadores.forEach(vereador => {
						if (vereador.status_fala == 1) {
							vereador.state = 'A';
						}
						if (vereador.status_fala == 2) {
							vereador.state = 'F';
							if (vereador.user.user_category_id === 2) {
								if (vereador.user.status_lider === true) {
									this.minutes = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
									this.seconds = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;

									this.minutesToProgressBar = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
									this.secondsToProgressBar = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;
								} else {
									this.seconds = this.StoreAll.timers[0].Vereador_tribune.seconds;
									this.minutes = this.StoreAll.timers[0].Vereador_tribune.minutes;

									this.minutesToProgressBar = this.StoreAll.timers[0].Vereador_tribune.minutes;
									this.secondsToProgressBar = this.StoreAll.timers[0].Vereador_tribune.seconds;
								}
							}
							if (vereador.user.user_category_id === 3) {
								if (vereador.user.status_lider === true) {
									this.minutes = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
									this.seconds = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;

									this.minutesToProgressBar = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
									this.secondsToProgressBar = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;
								} else {
									this.seconds = this.StoreAll.timers[0].Vereador_tribune.seconds;
									this.minutes = this.StoreAll.timers[0].Vereador_tribune.minutes;

									this.minutesToProgressBar = this.StoreAll.timers[0].Vereador_tribune.minutes;
									this.secondsToProgressBar = this.StoreAll.timers[0].Vereador_tribune.seconds;
								}
							}
							if(vereador.user.user_category_id === 8){
								this.minutes = this.StoreAll.timers[0].Convidado_tribune.minutes;
								this.seconds = this.StoreAll.timers[0].Convidado_tribune.seconds;

								this.minutesToProgressBar = this.StoreAll.timers[0].Convidado_tribune.minutes;
								this.secondsToProgressBar = this.StoreAll.timers[0].Convidado_tribune.seconds;
							}
							if(vereador.user.user_category_id === 5){
								this.minutes = this.StoreAll.timers[0].Prefeitura_tribune.minutes;
								this.seconds = this.StoreAll.timers[0].Prefeitura_tribune.seconds;

								this.minutesToProgressBar = this.StoreAll.timers[0].Prefeitura_tribune.minutes;
								this.secondsToProgressBar = this.StoreAll.timers[0].Prefeitura_tribune.seconds;
							}
							if(vereador.user.user_category_id !== 1 && vereador.user.user_category_id !== 2 && vereador.user.user_category_id !== 3 && vereador.user.user_category_id !== 5 && vereador.user.user_category_id !== 8){
								this.minutes = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
								this.seconds = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;

								this.minutesToProgressBar = this.StoreAll.timers[0].Vereador_lider_tribune.minutes;
								this.secondsToProgressBar = this.StoreAll.timers[0].Vereador_lider_tribune.seconds;
							}
							this.fala = true;
							localStorage.setItem('id_user_tv', vereador.user.id);
							localStorage.setItem('id_timer_extra', vereador.id);
						}
						if (vereador.status_fala == 3) {
							vereador.state = 'P';
							this.fala = false;
						}
						if (vereador.status_fala == 4) {
							vereador.state = 'VP';
							localStorage.setItem('id_user_tv', vereador.user.id);
						}
						if (vereador.status_fala == 5) {
							vereador.state = 'FS';
						}
						ArrayLessEach.push(vereador);
					});

					this.tribruna_vereadores = ArrayLessEach;
					this.Loading = false;
					this.cronometroconf(ArrayLessEach);
				}}, 500);
		},

		cronometroconf(value) {
			if (value.length <= 0) {
				return;
			}

			const TalkUser = [];
			const PauseUser = [];
			const FimUsers = [];

			if (value.length >= 1) {

				value.forEach(Talk => {
					if (Talk.state === 'F') {
						TalkUser.push(Talk);
					}
					if (Talk.state === 'P') {
						PauseUser.push(Talk);
					}
					if (Talk.state === 'VP') {
						TalkUser.push(Talk);
					}

					if (Talk.state === 'FS') {
						FimUsers.push(Talk);
					}
				});
			}

			if (TalkUser.length >= 1) {
				this.timerOn = true;
				this.timer();
			}

			if (PauseUser.length >= 1) {
				this.timerOn = false;
				this.VerificaFala();
			}

			if (TalkUser.length <= 0 && PauseUser.length <= 0) {
				this.timerOn = false;
				this.ResetTimerFS(FimUsers);
			}
		},

		VerificaFala() {
			this.timerpauseseconds = this.seconds;
			this.timerpauseminutes = this.minutes;

			this.seconds = this.timerpauseseconds;
			this.minutes = this.timerpauseminutes;
		},

		ResetTimerFS(value) {
			for (let i = 0; i < value.length; i++) {
				if (value[i].user.id == localStorage.getItem('id_user_tv')) {
					localStorage.removeItem('id_user_tv');
				}
			}
		},

		timer() {
			if (!this.timerOn) {
				return;
			}

			setTimeout(() => {
				this.timer();
			}, 1000);

			let sec = this.seconds--;
			let min = this.minutes;

			if (min == 0 && sec == 0) {
				this.cronometro = '';
				let text = 'Acabou seu tempo!';
				document.getElementById('timerzz').innerText = text;
				this.timerOn = false;
				this.TimerOver = true;

			} else {

				if (sec <= 9) {
					sec = '0' + sec;
				}
				if (sec == 0) {
					this.seconds = 59;
					let min = this.minutes--;
				}
				if (min == 0 && sec <= 59) {
					let CheckId = setInterval(() => {
						if (document.getElementById('timerz') !== null && document.getElementById('timerzz') !== null) {
							clearInterval(CheckId);
							document.getElementById('timerz').style.color = '#FF0000';
							document.getElementById('timerzz').style.color = '#FF0000';
						}
					}, 500);
				}

				this.cronometro = min + ' : ' + sec;

				if (!this.StoreAll.fullScren_quorum) {
					let totalRemainingSeconds = this.minutes * 60 + this.seconds;

					let totalInitialSeconds = (this.minutesToProgressBar * 60 + this.secondsToProgressBar + 1);

					let percentageRemaining = (totalRemainingSeconds / totalInitialSeconds) * 100;

					document.querySelector('.progress-bar').style.width = percentageRemaining + '%';
					if (totalRemainingSeconds < 60) {
				      document.querySelector('.progress-bar').style.backgroundColor = '#DC3545';
					} else {
						document.querySelector('.progress-bar').style.backgroundColor = '#198754';
					}
				}
			}
		},

		FinishUserTalk () {
			try {
				const formData = new FormData();
				formData.append('tribune_id', this.tribuna_id);
				formData.append('user_id', localStorage.getItem('id_user_tv'));
				formData.append('status_fala', 5);


				AxiosAPI.post('/tribune-users-upp', formData, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((resp) => {
					console.log(resp);
				});
			} catch {
				console.log('error');
			}
		},

		async AparteOn() {
			if(this.StoreAll.tribuna == false){
				return;
			}
			if (this.voted) {
				return;
			}
			if (this.fala) {
				return;
			}

			try {
				await AxiosAPI.get('/apartiamento-users?tribune_id=' + this.tribuna_id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {
					const ArrayLessAparte = [];

					response.data.data.forEach(Apartiador => {
						if (Apartiador.status_fala === '1') {
							Apartiador.state = 'APT';
							localStorage.setItem('id_user_tv_aparte', Apartiador.user.id);

							if (Apartiador.type) {
								this.Aparte_seconds = this.StoreAll.timers[0].Apartiamento_reduzido_tribune.seconds;
								this.Aparte_minutes = this.StoreAll.timers[0].Apartiamento_reduzido_tribune.minutes;

								this.Aparte_secondsToProgress = this.StoreAll.timers[0].Apartiamento_reduzido_tribune.seconds;
								this.Aparte_minutesToProgress = this.StoreAll.timers[0].Apartiamento_reduzido_tribune.minutes;
							}

							if (!Apartiador.type) {
								this.Aparte_minutes = this.StoreAll.timers[0].Apartiamento_tribune.minutes;
								this.Aparte_seconds = this.StoreAll.timers[0].Apartiamento_tribune.seconds;

								this.Aparte_secondsToProgress = this.StoreAll.timers[0].Apartiamento_tribune.seconds;
								this.Aparte_minutesToProgress = this.StoreAll.timers[0].Apartiamento_tribune.minutes;
							}
						}

						if (Apartiador.status_fala === '2') {
							Apartiador.state = 'APTF';
						}
						ArrayLessAparte.push(Apartiador);
					});

					this.tribruna_vereadores_aparte = ArrayLessAparte;
					this.CronometroAparte(ArrayLessAparte);
				});
			} catch (error) {
				console.log(error);
			}
		},

		CronometroAparte(value) {
			if (value.length <= 0) {
				return;
			}
			const TalkAparte = [];
			const FimAparte = [];

			value.forEach(Talk => {
				if (Talk.state === 'APT') {
					TalkAparte.push(Talk);
				}

				if (Talk.state === 'APTF') {
					FimAparte.push(Talk);
				}
			});

			if (TalkAparte.length >= 1) {
				this.timerOnAparte = true;
				this.timerparte();
			}

			if (TalkAparte.length <= 0) {
				this.timerOnAparte = false;
				this.ResetTimerAPTF(FimAparte);
			}

		},

		timerparte() {
			if (!this.timerOnAparte) {
				return;
			}

			setTimeout(() => {
				this.timerparte();
			}, 1000);


			let sec = this.Aparte_seconds--;
			let min = this.Aparte_minutes;

			if (min == 0 && sec == 0) {
				this.Aparte = '';
				this.Apartes = 'Acabou seu tempo!';
				this.timerOnAparte = false;
				this.TimerOverAparte = true;
			} else {
				this.Apartes = '';
				if (sec <= 9) {
					sec = '0' + sec;

				}
				if (sec == 0) {
					this.Aparte_seconds = 59;
					let min = this.Aparte_minutes--;

				}
				if (min == 0 && sec <= 59) {
					let CheckId = setInterval(() => {
						if (document.getElementById('timerz') !== null) {
							clearInterval(CheckId);
							document.getElementById('timerz').style.color = '#FF0000';
						}
					}, 500);
				}

				this.Aparte = min + ' : ' + sec;

				if (!this.StoreAll.fullScren_quorum) {
					let totalRemainingSeconds = this.Aparte_minutes * 60 + this.Aparte_seconds;

					let totalInitialSeconds = (this.Aparte_minutesToProgress * 60 + this.Aparte_secondsToProgress + 1);

					let percentageRemaining = (totalRemainingSeconds / totalInitialSeconds) * 100;

					document.querySelector('.progress-bar').style.width = percentageRemaining + '%';
					if (totalRemainingSeconds < 60) {
						document.querySelector('.progress-bar').style.backgroundColor = '#DC3545';
					} else {
						document.querySelector('.progress-bar').style.backgroundColor = '#198754';
					}
				}
			}},

		async ResetTimerAPTF(value) {
			for (let i = 0; i < value.length; i++) {
				if (value[i].user.id == localStorage.getItem('id_user_tv_aparte')) {

					this.Aparte_seconds = this.StoreAll.timers[0].Apartiamento_tribune.seconds;
					this.Aparte_minutes = this.StoreAll.timers[0].Apartiamento_tribune.minutes;

					try {
						await AxiosAPI.delete('/att-aparte?tribune_id=' + this.tribuna_id + '&user_id=' + localStorage.getItem('id_user_tv_aparte'), {
							headers: {
								Authorization: 'Bearer ' + this.StoreAll.accessToken
							}
						}).then((resp) => {
							localStorage.removeItem('id_user_tv_aparte');
							this.Apartes = '';
							this.t = resp;
						});
					} catch {
						console.log('error');
					}
				}
			}
		},

		FinishTalkAparte () {
			try {
				const formData = new FormData();
				formData.append('tribune_id', this.tribuna_id);
				formData.append('user_id', localStorage.getItem('id_user_tv_aparte'));
				formData.append('status_fala', 2);


				AxiosAPI.post('/apartiamento-users-upp', formData, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken
					}
				}).then((resp) => {
					console.log(resp);
				});
			} catch {
				console.log('error');
			}
		},

		async Addtimer() {
			try{
				await AxiosAPI.get('/tribune-users?tribune_id=' + this.tribuna_id, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {

					let id_timer_extra = localStorage.getItem('id_timer_extra');

					for (let i = 0; i < response.data.data.length; i++) {

						if (response.data.data[i].user.id == localStorage.getItem('id_user_tv')) {

							if (response.data.data[i].time_extra == 2) {
								if(response.data.data[i].user.user_category_id === 8){
									this.minutes = this.minutes + this.Timer_Extra_Convidado_minutes;

									AxiosAPI.patch('/tribune-users/'+id_timer_extra+'/update-time-extra?time_extra=1',{},{
										headers: {
											Authorization: 'Bearer ' + this.StoreAll.accessToken,
										},
									});
								}

								if(response.data.data[i].user.user_category_id !== 8){
									this.minutes = this.minutes + this.Timer_Extra_Vereador_minutes;

									AxiosAPI.patch('/tribune-users/'+id_timer_extra+'/update-time-extra?time_extra=1',{}, {
										headers: {
											Authorization: 'Bearer ' + this.StoreAll.accessToken,
										},
									});}
							}
						}

					}
				});
			}
			catch {
				console.log('error');
			}
		},

		isPresent(user) {
			return user[1] === 'P';
		},

		userStatus(user) {
			return this.isPresent(user) ? 'Presente' : 'Ausente';
		},

		userImagePath(user) {
			return this.StoreAll.Dochost + user.path_image;
		}
	},
};
</script>
<template>
<div>
	<div v-if="Loading" class="d-flex justify-content-center">
			<div class="WaitQuorum">
				<span
				class="font_footer_17 d-grid"
				style="transform: translate(-50%, 80%);margin-right: -50%;top: 39%;left: 50%;position: absolute;font-size: 2.5vw;font-weight: 500 !important;color: white;">
				<i class="d-flex justify-content-center fa-solid fa-hourglass-half" style="color:#007AB8"></i>
				Aguardando início da tribuna...</span>
			</div>
	</div>

	<div v-if="!Loading && StoreAll.tvlength === 1">
		<div v-if="orderedTribunaVereadores.length <= 0 && !timerSubscribeEnd" class="d-flex justify-content-center">
			<div class="WaitSubscribe d-flex justify-content-center align-items-center">
				<div>
				<div class="d-flex justify-content-center">
					<span class="fonts-body" style="font-size:2.9vw;">Realize sua inscrição</span>
				</div>
				<div class="d-flex align-items-center justify-content-center">
					<div class="w-100 d-flex justify-content-center">
						<div class="box-timer">
							<div class="d-grid align-items-center justify-content-center mt-4">
								<div class="progress" style="width: 67vw; height: 2vh;">
									<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
								<div class="d-flex justify-content-center align-items-center">
									<span id="timerinit" class="fonts-body" :style="{ display: timerCronometroDisplay, color: timerCronometroColor, fontSize: timerCronometroSize}"> {{ cronometross }} </span>
								</div>
								<div class="d-flex justify-content-center align-items-center">
									<span id="textinit" class="fonts-body" :style="{ display: textTimerDisplay, color: textTimerColor }" style="font-size: clamp(0.1vw, 2.5vw, 3vw);"> {{ textTimer }} </span>
								</div>
							</div>
					</div>
					</div>
				</div>
				</div>
			</div>

			<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
		</div>
		<div v-else-if="orderedTribunaVereadores.length >= 0 && !timerSubscribeEnd" class="d-flex w-100">
			<div v-if="timer_start" class="d-flex justify-content-start ml-4" style="width: 33%;">
				<div class="WaitSubscribeBefore d-flex justify-content-center align-items-center">
					<div>
						<div class="d-flex justify-content-center">
							<span class="fonts-body" style="font-size:2.9vw;">Realize sua inscrição</span>
						</div>
						<div class="d-flex align-items-center justify-content-center">
							<div class="w-100 d-flex justify-content-center">
								<div class="box-timerBefore">
									<div class="d-grid align-items-center justify-content-center mt-4">
										<div class="d-flex justify-content-center">
											<div class="progress" style="width: 27vw; height: 2vh;">
												<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerinit" class="fonts-body" :style="{ display: timerCronometroDisplay, color: timerCronometroColor, fontSize: timerCronometroSize}"> {{ cronometross }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="textinit" class="fonts-body"
											:style="{ display: textTimerDisplay, color: textTimerColor }"
											style="text-align: center;">
											{{ textTimer }}
											</span>
										</div>
									</div>
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="!timer_start" class="d-flex justify-content-start ml-4" style="width: 33%;">
				<div class="WaitSubscribeBefore d-flex justify-content-center">
					<div v-for="(TribunaTalkNow, index) in tribruna_vereadores" :key="TribunaTalkNow.id">
						<div v-if="TribunaTalkNow.state === 'F' || TribunaTalkNow.state === 'VP'" style="position: relative; top: 3%">
							<div class="d-flex justify-content-center">
								<div>
									<img :src="userImagePath(TribunaTalkNow.user)"
									style="height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"
									alt="user photo" />
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<span
								class="ml-2 fonts-body"
								style="font-size: 1.6vw">
								{{ TribunaTalkNow.user.nickname }}
								</span>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<span
								v-if="TribunaTalkNow.user.user_category_id === 3"
								id="status"
								class="text-black ml-2 pl-2 pr-2 box-presidente"
								style="font-size: 1.1vw;">
								Presidente
								</span>
								<span
								v-if="TribunaTalkNow.user.user_category_id !== 5 && TribunaTalkNow.user.user_category_id !== 8"
								id="status"
								class="text-white mr-2 pl-2 pr-2 box-partido"
								style="border:1px solid white; font-size: 1.1vw;">
								{{ TribunaTalkNow.user.category_party.name_party }}
								</span>
								<div class="d-flex justify-content-center align-items-center mr-2 box-presidente" style="background-color: #198754;">
									<i class="fa-solid fa-microphone mr-2 text-white" style="font-size: 1.2vw"></i>
									<span class="text-white fonts-body" style="font-size: 1.2vw">Falando</span>
								</div>
							</div>
							<div class="d-flex justify-content-center">
								<div class="box-timerTalk">
									<div class="d-grid align-items-center justify-content-center" style="top: 20%;position: relative;">
										<div class="progress" style="width: 27vw; height: 2vh;">
											<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerz" class="fonts-body" style="font-size:2.9vw;"> {{ cronometro }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerzz" class="fonts-body" style="font-size:1.2vw;"> Tempo </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else-if="index === 0 && !timerOnAparte && !TalkNow" style="position: relative; top: 20%">

							<div class="d-flex justify-content-center align-items-center text-center">
								<div>
									<i class="fa-solid fa-user" style="color:#007AB8;font-size: 16.6vw;height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"></i>
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<i class="fa-solid fa-hourglass-half" style="font-size: 2.6vw; color: #007AB8"></i>
							</div>
						</div>
					</div>
					<div v-if="timerOnAparte">
					<div v-for="TribunaTalkAparte in tribruna_vereadores_aparte" :key="TribunaTalkAparte.id" style="position: relative; top: 3%">
						<div  v-if="TribunaTalkAparte.state == 'APT'">
							<div class="d-flex justify-content-center">
								<div>
									<img :src="userImagePath(TribunaTalkAparte.user)"
									style="height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"
									alt="user photo" />
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<span
								class="ml-2 fonts-body"
								style="font-size: 1.6vw">
								{{ TribunaTalkAparte.user.nickname }}
								</span>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<span
								v-if="TribunaTalkAparte.user.user_category_id === 3"
								id="status"
								class="text-black ml-2 pl-2 pr-2 box-presidente"
								style="font-size: 1.1vw;">
								Presidente
								</span>
								<span
								v-if="TribunaTalkAparte.user.user_category_id !== 5 && TribunaTalkAparte.user.user_category_id !== 8"
								id="status"
								class="text-white mr-2 pl-2 pr-2 box-partido"
								style="border:1px solid white; font-size: 1.1vw;">
								{{ TribunaTalkAparte.user.category_party.name_party }}
								</span>
								<div class="d-flex justify-content-center align-items-center mr-2 box-presidente" style="width: 30%; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
									<i class="fa-solid fa-microphone mr-2 text-white" style="font-size: 1.2vw"></i>
									<span class="text-white fonts-body mr-2" style="font-size: 1.2vw">Aparteando</span>
								</div>
							</div>
							<div class="d-flex justify-content-center">
								<div class="box-timerTalk">
									<div class="d-grid align-items-center justify-content-center" style="top: 20%;position: relative;">
										<div class="progress" style="width: 27vw; height: 2vh;">
											<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerz" class="fonts-body" style="font-size:2.9vw;"> {{ Aparte }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerzz" class="fonts-body" style="font-size:1.2vw;"> Tempo </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
			</div>

			<div class="d-flex justify-content-start" style="width: 66%;">
				<LayoutUsersTribuna :UserTribuna = orderedTribunaVereadores :timerPause = cronometro />
			</div>

			<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
		</div>
		<div v-else-if="timerSubscribeEnd" class="d-flex w-100">
			<div v-if="TalkNowOneTv === 1 || TalkNowOneTv === 2 || TalkNowOneTv === 3" class="d-flex w-100">
			<div v-if="!timer_start" class="d-flex justify-content-start ml-2" style="width: 99%;">
				<div class="WaitSubscribeBefore d-flex justify-content-center align-items-center">
					<div v-for="(TribunaTalkNow) in tribruna_vereadores" :key="TribunaTalkNow.id">
						<div v-if="TribunaTalkNow.state === 'F' || TribunaTalkNow.state === 'VP'" style="position: relative;" class="d-flex align-items-center">
							<div>
								<div class="d-flex justify-content-center">
									<div>
										<img :src="userImagePath(TribunaTalkNow.user)"
										style="height: 24.5vw;width: 24.5vw;border-radius:50%;object-fit: cover;"
										alt="user photo" />
									</div>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ TribunaTalkNow.user.nickname }}
									</span>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<span
									v-if="TribunaTalkNow.user.user_category_id === 3"
									id="status"
									class="text-black ml-2 pl-2 pr-2 box-presidente"
									style="font-size: 1.1vw;">
									Presidente
									</span>
									<span
									v-if="TribunaTalkNow.user.user_category_id !== 5 && TribunaTalkNow.user.user_category_id !== 8"
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ TribunaTalkNow.user.category_party.name_party }}
									</span>
									<div class="d-flex justify-content-center align-items-center mr-2 box-presidente" style="background-color: #198754;">
										<i class="fa-solid fa-microphone mr-2 text-white" style="font-size: 1.2vw"></i>
										<span class="text-white fonts-body" style="font-size: 1.2vw">Falando</span>
									</div>
								</div>
							</div>
							<div class="d-flex justify-content-center">
								<div class="box-timerTalk ml-4" style="width: 60vw;height: 40vh;">
									<div class="d-grid align-items-center justify-content-center" style="top: 20%;position: relative;">
										<div class="progress" style="width: 55vw; height: 2vh;">
											<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerz" class="fonts-body" style="font-size:6.9vw;"> {{ cronometro }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerzz" class="fonts-body" style="font-size:2.2vw;"> Tempo </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else-if="TalkNowOneTv === 4 && !timerOnAparte" style="position: relative; top: 20%">
							<div class="d-flex justify-content-center align-items-center text-center">
								<div>
									<i class="fa-solid fa-user" style="color:#007AB8;font-size: 16.6vw;height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"></i>
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<i class="fa-solid fa-hourglass-half" style="font-size: 2.6vw; color: #007AB8"></i>
							</div>
						</div>
					</div>
					<div v-if="timerOnAparte">
					<div v-for="TribunaTalkAparte in tribruna_vereadores_aparte" :key="TribunaTalkAparte.id" style="position: relative;">
						<div v-if="TribunaTalkAparte.state == 'APT'" class="d-flex align-items-center">
							<div>
							<div class="d-flex justify-content-center">
								<div>
									<img :src="userImagePath(TribunaTalkAparte.user)"
									style="height: 24.5vw;width: 24.5vw;border-radius:50%;object-fit: cover;"
									alt="user photo" />
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<span
								class="ml-2 fonts-body"
								style="font-size: 1.6vw">
								{{ TribunaTalkAparte.user.nickname }}
								</span>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<span
								v-if="TribunaTalkAparte.user.user_category_id === 3"
								id="status"
								class="text-black ml-2 pl-2 pr-2 box-presidente"
								style="font-size: 1.1vw;">
								Presidente
								</span>
								<span
								v-if="TribunaTalkAparte.user.user_category_id !== 5 && TribunaTalkAparte.user.user_category_id !== 8"
								id="status"
								class="text-white mr-2 pl-2 pr-2 box-partido"
								style="border:1px solid white; font-size: 1.1vw;">
								{{ TribunaTalkAparte.user.category_party.name_party }}
								</span>
								<div class="d-flex justify-content-center align-items-center mr-2 box-presidente" style="width: 40%; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
									<i class="fa-solid fa-microphone mr-2 text-white" style="font-size: 1.2vw"></i>
									<span class="text-white fonts-body mr-2" style="font-size: 1.2vw">Aparteando</span>
								</div>
							</div>
							</div>
							<div class="d-flex justify-content-center">
								<div class="box-timerTalk ml-4" style="width: 60vw;height: 40vh;">
									<div class="d-grid align-items-center justify-content-center" style="top: 20%;position: relative;">
										<div class="progress" style="width: 55vw; height: 2vh;">
											<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerz" class="fonts-body" style="font-size:6.9vw;"> {{ Aparte }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerzz" class="fonts-body" style="font-size:2.2vw;"> Tempo </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>

			<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
			</div>

			<div v-else class="d-flex justify-content-start" style="width: 100%;">
				<LayoutUsersTribuna :UserTribuna = orderedTribunaVereadores :timerPause = cronometro />
			</div>

			<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
		</div>
	</div>

	<div v-if="!Loading && StoreAll.tvlength === 2 && StoreAll.fullScren_quorum">
		<div v-if="orderedTribunaVereadores.length >= 1" class="d-flex w-100">
			<div class="d-flex justify-content-start ml-1" style="width: 100%;">
				<LayoutUsersTribuna :UserTribuna = orderedTribunaVereadores :timerPause = cronometro />
			</div>

			<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
		</div>
		<div v-if="orderedTribunaVereadores.length <= 0" class="d-flex justify-content-center">
			<div class="WaitExplanation">
					<span
					class="font_footer_17 d-grid"
					style="transform: translate(-50%, 80%);margin-right: -50%;top: 35%;left: 50%;position: absolute;font-size: 2.5vw;font-weight: 500 !important;color: white;">
					<i class="d-flex justify-content-center fa-solid fa-hourglass-half" style="color:#007AB8"></i>
					Aguardando inscritos...</span>
				</div>

				<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
				style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
		</div>
	</div>

	<div v-if="!Loading && StoreAll.tvlength === 2 && StoreAll.fullScren_actions">
		<div v-if="orderedTribunaVereadores.length <= 0 && !timerSubscribeEnd" class="d-flex justify-content-center">
			<div class="WaitSubscribe d-flex justify-content-center align-items-center">
				<div>
				<div class="d-flex justify-content-center">
					<span class="fonts-body" style="font-size:2.9vw;">Realize sua inscrição</span>
				</div>
				<div class="d-flex align-items-center justify-content-center">
					<div class="w-100 d-flex justify-content-center">
						<div class="box-timer">
							<div class="d-grid align-items-center justify-content-center mt-4">
								<div class="progress" style="width: 67vw; height: 2vh;">
									<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
								<div class="d-flex justify-content-center align-items-center">
									<span id="timerinit" class="fonts-body" :style="{ display: timerCronometroDisplay, color: timerCronometroColor, fontSize: timerCronometroSize}"> {{ cronometross }} </span>
								</div>
								<div class="d-flex justify-content-center align-items-center">
									<span id="textinit" class="fonts-body" :style="{ display: textTimerDisplay, color: textTimerColor }" style="font-size: clamp(0.1vw, 2.5vw, 3vw);"> {{ textTimer }} </span>
								</div>
							</div>
					</div>
					</div>
				</div>
				</div>
			</div>

			<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
		</div>

		<div v-if="orderedTribunaVereadores.length >= 1 && !timerSubscribeEnd" class="d-flex justify-content-center">
			<div class="WaitSubscribe d-flex justify-content-center align-items-center">
				<div>
				<div class="d-flex justify-content-center">
					<span class="fonts-body" style="font-size:2.9vw;">Realize sua inscrição</span>
				</div>
				<div class="d-flex align-items-center justify-content-center">
					<div class="w-100 d-flex justify-content-center">
						<div class="box-timer">
							<div class="d-grid align-items-center justify-content-center mt-4">
								<div class="progress" style="width: 67vw; height: 2vh;">
									<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
								<div class="d-flex justify-content-center align-items-center">
									<span id="timerinit" class="fonts-body" :style="{ display: timerCronometroDisplay, color: timerCronometroColor, fontSize: timerCronometroSize}"> {{ cronometross }} </span>
								</div>
								<div class="d-flex justify-content-center align-items-center">
									<span id="textinit" class="fonts-body" :style="{ display: textTimerDisplay, color: textTimerColor }" style="font-size: clamp(0.1vw, 2.5vw, 3vw);"> {{ textTimer }} </span>
								</div>
							</div>
					</div>
					</div>
				</div>
				</div>
			</div>

			<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
		</div>

		<div v-if="TalkNowOneTv === 1 || TalkNowOneTv === 2 || TalkNowOneTv === 3" class="d-flex w-100">
			<div v-if="!timer_start" class="d-flex justify-content-start ml-2" style="width: 99%;">
				<div class="WaitSubscribeBefore d-flex justify-content-center align-items-center">
					<div v-for="(TribunaTalkNow) in tribruna_vereadores" :key="TribunaTalkNow.id">
						<div v-if="TribunaTalkNow.state === 'F' || TribunaTalkNow.state === 'VP'" style="position: relative;" class="d-flex align-items-center">
							<div>
								<div class="d-flex justify-content-center">
									<div>
										<img :src="userImagePath(TribunaTalkNow.user)"
										style="height: 24.5vw;width: 24.5vw;border-radius:50%;object-fit: cover;"
										alt="user photo" />
									</div>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ TribunaTalkNow.user.nickname }}
									</span>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<span
									v-if="TribunaTalkNow.user.user_category_id === 3"
									id="status"
									class="text-black ml-2 pl-2 pr-2 box-presidente"
									style="font-size: 1.1vw;">
									Presidente
									</span>
									<span
									v-if="TribunaTalkNow.user.user_category_id !== 5 && TribunaTalkNow.user.user_category_id !== 8"
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ TribunaTalkNow.user.category_party.name_party }}
									</span>
									<div class="d-flex justify-content-center align-items-center mr-2 box-presidente" style="background-color: #198754;">
										<i class="fa-solid fa-microphone mr-2 text-white" style="font-size: 1.2vw"></i>
										<span class="text-white fonts-body" style="font-size: 1.2vw">Falando</span>
									</div>
								</div>
							</div>
							<div class="d-flex justify-content-center">
								<div class="box-timerTalk ml-4" style="width: 60vw;height: 40vh;">
									<div class="d-grid align-items-center justify-content-center" style="top: 20%;position: relative;">
										<div class="progress" style="width: 55vw; height: 2vh;">
											<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerz" class="fonts-body" style="font-size:6.9vw;"> {{ cronometro }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerzz" class="fonts-body" style="font-size:2.2vw;"> Tempo </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else-if="TalkNowOneTv === 4 && !timerOnAparte" style="position: relative; top: 20%">
							<div class="d-flex justify-content-center align-items-center text-center">
								<div>
									<i class="fa-solid fa-user" style="color:#007AB8;font-size: 16.6vw;height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"></i>
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<i class="fa-solid fa-hourglass-half" style="font-size: 2.6vw; color: #007AB8"></i>
							</div>
						</div>
					</div>
					<div v-if="timerOnAparte">
					<div v-for="TribunaTalkAparte in tribruna_vereadores_aparte" :key="TribunaTalkAparte.id" style="position: relative;">
						<div v-if="TribunaTalkAparte.state == 'APT'" class="d-flex align-items-center">
							<div>
							<div class="d-flex justify-content-center">
								<div>
									<img :src="userImagePath(TribunaTalkAparte.user)"
									style="height: 24.5vw;width: 24.5vw;border-radius:50%;object-fit: cover;"
									alt="user photo" />
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<span
								class="ml-2 fonts-body"
								style="font-size: 1.6vw">
								{{ TribunaTalkAparte.user.nickname }}
								</span>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<span
								v-if="TribunaTalkAparte.user.user_category_id === 3"
								id="status"
								class="text-black ml-2 pl-2 pr-2 box-presidente"
								style="font-size: 1.1vw;">
								Presidente
								</span>
								<span
								v-if="TribunaTalkAparte.user.user_category_id !== 5 && TribunaTalkAparte.user.user_category_id !== 8"
								id="status"
								class="text-white mr-2 pl-2 pr-2 box-partido"
								style="border:1px solid white; font-size: 1.1vw;">
								{{ TribunaTalkAparte.user.category_party.name_party }}
								</span>
								<div class="d-flex justify-content-center align-items-center mr-2 box-presidente" style="width: 40%; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
									<i class="fa-solid fa-microphone mr-2 text-white" style="font-size: 1.2vw"></i>
									<span class="text-white fonts-body mr-2" style="font-size: 1.2vw">Aparteando</span>
								</div>
							</div>
							</div>
							<div class="d-flex justify-content-center">
								<div class="box-timerTalk ml-4" style="width: 60vw;height: 40vh;">
									<div class="d-grid align-items-center justify-content-center" style="top: 20%;position: relative;">
										<div class="progress" style="width: 55vw; height: 2vh;">
											<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerz" class="fonts-body" style="font-size:6.9vw;"> {{ Aparte }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerzz" class="fonts-body" style="font-size:2.2vw;"> Tempo </span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>

			<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
			</div>

			<div v-else-if="timerSubscribeEnd"  class="WaitSubscribeBefore d-flex justify-content-center align-items-center">
						<div style="position: relative;">
							<div class="d-flex justify-content-center align-items-center text-center">
								<div>
									<i class="fa-solid fa-user" style="color:#007AB8;font-size: 16.6vw;height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"></i>
								</div>
							</div>
							<div class="d-flex justify-content-center mt-3">
								<i class="fa-solid fa-hourglass-half" style="font-size: 2.6vw; color: #007AB8"></i>
							</div>
					</div>
				</div>

				<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
	</div>
 </div>
</template>
<style>

.WaitSubscribe {
	width: 98%;
    height: 70vh;
    background-color: #023866;
    border-radius: 32px;
    margin-top: 2rem;
}

.WaitSubscribeBefore {
	width: 100%;
	height: 70vh;
	background-color: #023866;
	border-radius: 32px;
	margin-top: 2rem;
}

.box-timer {
	width: 70vw;
    height: 35vh;
    background-color: #034680;
    border-radius: 16px;
    margin-top: 2rem;
}

.box-timerBefore {
	width: 30vw;
    height: 35vh;
    background-color: #034680;
    border-radius: 16px;
    margin-top: 2rem;
}

.box-timerTalk {
	width: 30vw;
    height: 20vh;
    background-color: #034680;
    border-radius: 16px;
    margin-top: 2rem;
}



.text-black {
  color: black;
}
.status_circle {
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.status_circle_active {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  border: 2px solid #0db80d;
  background-color: #0db80d;
}
.display_vh {
  height: clamp(0.1vw, 44.6vw, 855px);
}
.display_sign {
  width: clamp(0.1vw, 22.6vw, 410px);
}
.display_cronometro {
  width: clamp(0.1vw, 50vw, 600px);
}
.display_img {
  width: clamp(0.1vw, 20vw, 310px);
  height: clamp(0.1vw, 20vw, 310px);
}

.display_margin {
  margin-right: 12%;
}
@media only screen and (max-width: 1600px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 690px);
  }
  .display_sign {
    width: clamp(0.1vw, 25.6vw, 410px);
  }
  .display_cronometro {
    width: clamp(0.1vw, 30vw, 500px);
  }
  .display_margin {
    margin-right: 7%;
  }
}
@media only screen and (max-width: 1380px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 570px);
  }
  .display_sign {
    width: clamp(0.1vw, 28.6vw, 400px);
  }
  .display_margin {
    margin-right: 4%;
  }
}
@media only screen and (max-width: 1280px) {
  .display_vh {
    height: clamp(0.1vw, 43vw, 530px);
  }
  .display_sign {
    width: clamp(0.1vw, 29.6vw, 400px);
  }
  .display_margin {
    margin-right: 2%;
  }
}
</style>
