<script>
import { useMainStore } from '../../../stores/index';

export default {
	name: 'LayoutUsers',
	setup() {
		const StoreAll = useMainStore();
		StoreAll.User();
		return {
			StoreAll: StoreAll
		};
	},
	props: {
		Users: {
			type: Array,
			required: true
		}
	},
	data() {
		return {

		};
	},

	created () {

	},

	mounted() {

	},

	methods: {
		isPresent(user) {
			return user[1] === 'P';
		},
		userStatus(user) {
			return this.isPresent(user) ? 'Presente' : 'Ausente';
		},
		userImagePath(user) {
			return this.StoreAll.Dochost + user.path_image;
		}
	},
};
</script>
<template>
  <div class="container-body">
	<div v-if="this.StoreAll.Large_host == 17" style="height: 62vh;">
		<div class="d-flex" style="height: 25%;">
			<div v-for="(user, index) in Users.slice(0, 4)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" class="img_display" alt="user photo" />

							<div>
								<div class="mb-1">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span v-if="userStatus(user) === 'Presente'"
									id="status"
									class="text-white pl-2 pr-2 box-presente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
									<span v-if="userStatus(user) === 'Ausente'"
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 25%;">
			<div v-for="(user, index) in Users.slice(4, 8)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" class="img_display" alt="user photo" />

							<div>
								<div class="mb-1">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span v-if="userStatus(user) === 'Presente'"
									id="status"
									class="text-white pl-2 pr-2 box-presente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
									<span v-if="userStatus(user) === 'Ausente'"
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 25%;">
			<div v-for="(user, index) in Users.slice(8, 12)" :key="index" class="w-100" style="margin-right: 1rem;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" class="img_display" alt="user photo" />

								<div>
									<div class="mb-1">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div class="d-flex">
										<span
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.category_party.name_party }}
										</span>
										<span v-if="userStatus(user) === 'Presente'"
										id="status"
										class="text-white pl-2 pr-2 box-presente-status"
										style="font-size: 1.1vw;">
										{{ userStatus(user) }}
										</span>
										<span v-if="userStatus(user) === 'Ausente'"
										id="status"
										class="text-white pl-2 pr-2 box-ausente-status"
										style="font-size: 1.1vw;">
										{{ userStatus(user) }}
										</span>
									</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
		<div class="d-flex" style="height: 25%;">
			<div v-for="(user, index) in Users.slice(12, 16)" :key="index" class="w-100" style="margin-right: 1rem;">
					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" class="img_display" alt="user photo" />

								<div>
									<div class="mb-1">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div class="d-flex">
										<span
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.category_party.name_party }}
										</span>
										<span v-if="userStatus(user) === 'Presente'"
										id="status"
										class="text-white pl-2 pr-2 box-presente-status"
										style="font-size: 1.1vw;">
										{{ userStatus(user) }}
										</span>
										<span v-if="userStatus(user) === 'Ausente'"
										id="status"
										class="text-white pl-2 pr-2 box-ausente-status"
										style="font-size: 1.1vw;">
										{{ userStatus(user) }}
										</span>
									</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
	</div>
	<div v-if="this.StoreAll.Large_host == 13" style="height: 62vh;">
		<div class="d-flex" style="height: 31%;">
			<div v-for="(user, index) in Users.slice(0, 4)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" class="img_display" alt="user photo" />

							<div>
								<div class="mb-1">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span v-if="userStatus(user) === 'Presente'"
									id="status"
									class="text-white pl-2 pr-2 box-presente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
									<span v-if="userStatus(user) === 'Ausente'"
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 31%;">
			<div v-for="(user, index) in Users.slice(4, 8)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" class="img_display" alt="user photo" />

							<div>
								<div class="mb-1">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span v-if="userStatus(user) === 'Presente'"
									id="status"
									class="text-white pl-2 pr-2 box-presente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
									<span v-if="userStatus(user) === 'Ausente'"
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 31%;">
			<div v-for="(user, index) in Users.slice(8, 12)" :key="index" class="w-100" style="margin-right: 1rem;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" class="img_display" alt="user photo" />

								<div>
									<div class="mb-1">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div class="d-flex">
										<span
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.category_party.name_party }}
										</span>
										<span v-if="userStatus(user) === 'Presente'"
										id="status"
										class="text-white pl-2 pr-2 box-presente-status"
										style="font-size: 1.1vw;">
										{{ userStatus(user) }}
										</span>
										<span v-if="userStatus(user) === 'Ausente'"
										id="status"
										class="text-white pl-2 pr-2 box-ausente-status"
										style="font-size: 1.1vw;">
										{{ userStatus(user) }}
										</span>
									</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
	</div>
	<div v-if="this.StoreAll.Large_host == 11" style="height: 62vh;">
		<div class="d-flex" style="height: 31%;">
			<div v-for="(user, index) in Users.slice(0, 4)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" class="img_display" alt="user photo" />

							<div>
								<div class="mb-1">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span v-if="userStatus(user) === 'Presente'"
									id="status"
									class="text-white pl-2 pr-2 box-presente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
									<span v-if="userStatus(user) === 'Ausente'"
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 31%;">
			<div v-for="(user, index) in Users.slice(4, 8)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" class="img_display" alt="user photo" />

							<div>
								<div class="mb-1">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span v-if="userStatus(user) === 'Presente'"
									id="status"
									class="text-white pl-2 pr-2 box-presente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
									<span v-if="userStatus(user) === 'Ausente'"
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 31%;">
			<div v-for="(user, index) in Users.slice(8, 10)" :key="index" class="w-100" style="margin-right: 1rem;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" class="img_display" alt="user photo" />

								<div>
									<div class="mb-1">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div class="d-flex">
										<span
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.category_party.name_party }}
										</span>
										<span v-if="userStatus(user) === 'Presente'"
										id="status"
										class="text-white pl-2 pr-2 box-presente-status"
										style="font-size: 1.1vw;">
										{{ userStatus(user) }}
										</span>
										<span v-if="userStatus(user) === 'Ausente'"
										id="status"
										class="text-white pl-2 pr-2 box-ausente-status"
										style="font-size: 1.1vw;">
										{{ userStatus(user) }}
										</span>
									</div>

								</div>
							</div>
						</div>
					</div>
			</div>
				<div class="w-100" style="margin-right: 1rem;"></div>
    			<div class="w-100" style="margin-right: 1rem;"></div>
		</div>
	</div>
	<div v-if="this.StoreAll.Large_host == 9" style="height: 62vh;">
		<div class="d-flex" style="height: 45%;">
			<div v-for="(user, index) in Users.slice(0, 4)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" class="img_display" alt="user photo" />

							<div>
								<div class="mb-1">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span v-if="userStatus(user) === 'Presente'"
									id="status"
									class="text-white pl-2 pr-2 box-presente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
									<span v-if="userStatus(user) === 'Ausente'"
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 45%;">
			<div v-for="(user, index) in Users.slice(4, 8)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" class="img_display" alt="user photo" />

							<div>
								<div class="mb-1">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span v-if="userStatus(user) === 'Presente'"
									id="status"
									class="text-white pl-2 pr-2 box-presente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
									<span v-if="userStatus(user) === 'Ausente'"
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status"
									style="font-size: 1.1vw;">
									{{ userStatus(user) }}
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
  </div>
</template>
<style>
.container-body {
    margin-left: 1.5rem;
	margin-right: 0.5rem;
	height: 60vh;
}

.container-vereador {
    background-color: #023866;
	border-radius: 32px;
	margin-right: 0.5rem;
	height: 90%;
}

.img_display{
	width: 7vw;
    height: 7vw;
	border-radius: 50%;
	object-fit: cover;
}

.box-presente-status {
	display: flex;
    width: 6vw;
	height: 2vw;
	justify-content: center;
	font-family: "Roboto", serif;
    align-items: center;
	font-weight: 500 !important;
	background-color: #20875b;
	border-radius: 36px;
}

.box-ausente-status {
	display: flex;
    width: 6vw;
	height: 2vw;
	justify-content: center;
	font-family: "Roboto", serif;
    align-items: center;
	font-weight: 500 !important;
	background-color: #6C757D;
	border-radius: 36px;
}

.box-partido {
	display: flex;
    width: 5vw;
	height: 2vw;
	margin-left: 0.3rem;
	justify-content: center;
	font-family: "Roboto", serif;
	font-weight: 500 !important;
    align-items: center;
	border-radius: 36px;
}



</style>