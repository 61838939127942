<script>
import { AxiosAPI } from '@/axios';
import sound from '@/assets/sound/fim.mp3';
import { useMainStore, useBusStore } from '../../stores/index';

export default {
	name: 'TvOrdem',
	components: {},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			id_ordem: null,
			user_on: false,
			voted: false,
			f_fala: false,
			seconds: 2,
			minutes: 0,
			ordem_vereadores: [],
			cronometro: 'Aguarde...',
			dia: 0,
			mes: '',
			time: '',
			timer_session: 'Aguarde o inicio',
			timerOn: false,
			TimerOver: false,
			socket_ids:[],
		};
	},

	watch: {
		TimerOver: function (newValue, oldPath) {
			if (newValue === true) {
				this.TimerOver = false;
				this.Sound();
				this.Finalizar_fala();
			}
		},
	},

	computed: {
		SeeState () {
			let state =  this.ordem_vereadores.some(item => item.state === 'F');

			return state;
		}
	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 32) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Check_ordem();
							this.socket_ids.push(e.event_id);
						}
					}

					if (e.socket_id === 33) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						} else {
							this.Check_ordem();
							this.socket_ids.push(e.event_id);
						}
					}
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
	},

	methods: {

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.session = this.StoreAll.session; this.session_status = this.StoreAll.session.session_status.id;
			this.session_id = this.StoreAll.session.id; this.session_status_name = this.StoreAll.session.session_status.name;
			this.Check_ordem();
			this.TimesNow();
			this.Session_timer();
			this.Hora();
			setInterval(this.Hora, 1000);
		},

		TimesNow(){
			let verificarTimer = setInterval(() => {
				if (this.StoreAll.timers !== null) {
					clearInterval(verificarTimer);
					this.seconds = this.StoreAll.timers[0].Vereador_Ordem.seconds;
					this.minutes = this.StoreAll.timers[0].Vereador_Ordem.minutes;

				}
			}, 500);
		},

		async Check_ordem() {
			console.log(this.StoreAll.Ordem);
			await AxiosAPI
				.get(
					'/question-orders?quorum_id=' + this.StoreAll.quorum[0].id,
					{
						headers: {
							authorization: 'Bearer ' + this.StoreAll.accessToken,
						},
					}
				).then((resp) => {
					console.log(resp);
					let OrdemNow = [];

					resp.data.data.forEach(ordem => {
						if (resp.data.data) {
							if (ordem.status === '1') {
								OrdemNow.push(ordem);
							}
						}
					});

					if (OrdemNow.length <= 0) {
						this.StoreAll.Ordem = false;
					} else {
						this.StoreAll.Ordem = true;
					}
					this.id_ordem = OrdemNow[0].id;
					this.ordem_start();
				});
		},

		Session_timer() {
			setTimeout(() => {
				this.Session_timer();
			}, 300);

			if (this.StoreAll.quorum.length <= 0) {
				return;
			} else {
				let inicio = new Date(this.StoreAll.quorum[0].created_at).getTime();;
				let fim = new Date().getTime();;
				let diferenca = fim - inicio;
				let hours = Math.floor((diferenca % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
				let minutes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
				let seconds = Math.floor((diferenca % (1000 * 60)) / 1000);
				let timer_session = hours + 'h: ' + minutes + 'm: ' + seconds + 's';
				this.timer_session = timer_session;
			}
		},

		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mesesEmPortugues = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
				'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
			];
			let mes = mesesEmPortugues[date_time.getMonth()];

			let minutes = date_time.getMinutes().toString().padStart(2, '0');
			let hours = date_time.getHours().toString().padStart(2, '0');
			let seconds = date_time.getSeconds().toString().padStart(2, '0');

			this.time = `${hours}: ${minutes}: ${seconds}`;
			this.dia = dia;
			this.mes = mes;
		},

		async ordem_start() {
			if (!this.voted) {
				await AxiosAPI.get('/question-order-users?question_order_id=' + this.id_ordem, {
					headers: {
						Authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {
					let ordem_vereadores = [];
					let ArrayLess = [];

					for (let i = 0; i < response.data.data.length; i++) {
						ordem_vereadores.push(response.data.data[i]);
					}

					for (let i = 0; i < ordem_vereadores.length; i++) {
						const OrdemTalk = ordem_vereadores[i];
						if (response.data.data[i].status_fala === '1') {
							OrdemTalk['state'] = 'F';
							this.StoreAll.OrdemUsers = OrdemTalk.user.id;
							localStorage.setItem('id_user_tv_ordem', OrdemTalk.user.id);
							this.user_on = true;
							this.f_fala = false;

						}

						if (response.data.data[i].status_fala === '2') {
							OrdemTalk['state'] = 'FS';
						}

						ArrayLess.push(OrdemTalk);
					}
					this.ordem_vereadores = ArrayLess;
					this.CronometroStatus(ArrayLess);
				});
			}
		},

		CronometroStatus(value) {
			if (value.length <= 0) {
				return;
			}
			const TalkOrdem = [];
			const FimOrdem = [];

			value.forEach(Talk => {
				if (Talk.state === 'F') {
					TalkOrdem.push(Talk);
				}

				if (Talk.state === 'FS') {
					FimOrdem.push(Talk);
				}
			});	

			if (TalkOrdem.length >= 1) {
				this.timerOn = true;
				this.timer();
			}

			if (TalkOrdem.length <= 0) {
				this.timerOn = false;
				this.ResetTimer(FimOrdem);
			}

		},

		timer() {
			if (!this.timerOn) {
				return;
			}

			setTimeout(() => {
				this.timer();
			}, 1000);

			let sec = this.seconds--;
			let min = this.minutes;

			if (min == 0 && sec == 0) {
				this.seconds = 0;
				this.minutes = 0;
				this.cronometro = '';
				let text = 'Acabou seu tempo!';
				document.getElementById('timerzz').innerText = text;
				this.timerOn = false;
				this.TimerOver = true;

			} else {

				if (sec <= 9) {
					sec = '0' + sec;
					this.t = sec;
				}
				if (sec == 0) {
					this.seconds = 59;
					let min = this.minutes--;
					this.t = min;
				}
				if (min == 0 && sec <= 59) {
					document.getElementById('timerz').style.color = '#FF0000';
					document.getElementById('timerzz').style.color = '#FF0000';
				}

				this.cronometro = min + ' : ' + sec;
				this.t = sec;

				let totalRemainingSeconds = this.minutes * 60 + this.seconds;

				let totalInitialSeconds = (this.StoreAll.timers[0].Vereador_Ordem.minutes * 60 + this.StoreAll.timers[0].Vereador_Ordem.seconds + 1);

				let percentageRemaining = (totalRemainingSeconds / totalInitialSeconds) * 100;

				document.querySelector('.progress-bar').style.width = percentageRemaining + '%';
				if (totalRemainingSeconds < 60) {
			 document.querySelector('.progress-bar').style.backgroundColor = '#DC3545';
				} else {
					document.querySelector('.progress-bar').style.backgroundColor = '#198754';
				}
			}
		},

		Finalizar_fala() {
			const formData = new FormData();
			formData.append('question_order_id', this.id_ordem);
			formData.append('user_id', localStorage.getItem('id_user_tv_ordem'));
			formData.append('status_fala', 2);

			AxiosAPI.post('/question-order-users-upp', formData, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				this.t = resp;
				this.ResetTimer();
			});

		},

		ResetTimer() {
			this.seconds = this.StoreAll.timers[0].Vereador_Ordem.seconds;
			this.minutes = this.StoreAll.timers[0].Vereador_Ordem.minutes;
			this.StoreAll.OrdemUsers = null;

		},

		Sound () {
			let audio = new Audio(sound);
			audio.play();
		},

		isPresent(user) {
			return user[1] === 'P';
		},

		userStatus(user) {
			return this.isPresent(user) ? 'Presente' : 'Ausente';
		},

		userImagePath(user) {
			return this.StoreAll.Dochost + user.path_image;
		}
	}

};
</script>
<template>
    <div>
		<div v-if="user_on == false" class="d-flex justify-content-center">
				<div class="WaitExplanation">
					<span
					class="font_footer_17 d-grid"
					style="transform: translate(-50%, 80%);margin-right: -50%;top: 35%;left: 50%;position: absolute;font-size: 2.5vw;font-weight: 500 !important;color: white;">
					<i class="d-flex justify-content-center fa-solid fa-hourglass-half" style="color:#007AB8"></i>
					Aguardando vereador...</span>
				</div>
			</div>

			<div v-if="user_on && StoreAll.tvlength === 1" class="d-flex justify-content-center">
				<div class="WaitExplanation">
					<div v-if="ordem_vereadores.length >= 1" class="box-explanation d-flex justify-content-center">
					<div v-for="(explanation, index) in ordem_vereadores" :key="explanation.id" class="d-flex">
						<div v-if="explanation.state === 'F'" class="d-flex align-items-center">
							<div class="d-flex">
								<div>
								<div class="d-flex justify-content-center">
									<div>
										<img :src="userImagePath(explanation.user)"
										style="height: 24.5vw;width: 24.5vw;border-radius:50%;object-fit: cover;"
										alt="user photo" />
									</div>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ explanation.user.nickname }}
									</span>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<span
									v-if="explanation.user.user_category_id === 3"
									id="status"
									class="text-black ml-2 pl-2 pr-2 box-presidente"
									style="font-size: 1.1vw;">
									Presidente
									</span>
									<span
									v-if="explanation.user.user_category_id !== 5 && explanation.user.user_category_id !== 8"
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ explanation.user.category_party.name_party }}
									</span>
									<div class="d-flex justify-content-center align-items-center mr-2 box-presidente" style="background-color: #198754;">
										<i class="fa-solid fa-microphone mr-2 text-white" style="font-size: 1.2vw"></i>
										<span class="text-white fonts-body" style="font-size: 1.2vw">Falando</span>
									</div>
								</div>
							</div>
						</div>
							<div class="d-flex justify-content-center">
								<div class="box-timerTalkExplanation">
									<div class="d-grid align-items-center justify-content-center" style="top: 20%;position: relative;">
										<div class="progress" style="width: 55vw; height: 2vh;">
											<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerz" class="fonts-body" style="font-size:6.9vw;"> {{ cronometro }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerzz" class="fonts-body" style="font-size:2.2vw;"> Tempo </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!SeeState" style="position: relative; top: 20%; height:40vh">
							<div v-if="index === 0">
								<div class="d-flex justify-content-center align-items-center text-center">
									<div>
										<i class="fa-solid fa-user" style="color:#007AB8;font-size: 16.6vw;height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"></i>
									</div>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<i class="fa-solid fa-hourglass-half" style="font-size: 2.6vw; color: #007AB8"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>

			<div v-if="user_on && StoreAll.tvlength === 2 && StoreAll.fullScren_quorum" class="d-flex justify-content-center">
				<div class="WaitExplanation">
					<span
					class="font_footer_17 d-grid"
					style="transform: translate(-50%, 80%);margin-right: -50%;top: 35%;left: 50%;position: absolute;font-size: 2.5vw;font-weight: 500 !important;color: white;">
					<i class="d-flex justify-content-center fa-solid fa-microphone-lines" style="color:#007AB8"></i>
					Questão de ordem</span>
				</div>
			</div>

			<div v-if="user_on && StoreAll.tvlength === 2 && StoreAll.fullScren_actions" class="d-flex justify-content-center">
				<div class="WaitExplanation">
					<div v-if="ordem_vereadores.length >= 1" class="box-explanation d-flex justify-content-center">
					<div v-for="(explanation, index) in ordem_vereadores" :key="explanation.id" class="d-flex">
						<div v-if="explanation.state === 'F'" class="d-flex align-items-center">
							<div class="d-flex">
								<div>
								<div class="d-flex justify-content-center">
									<div>
										<img :src="userImagePath(explanation.user)"
										style="height: 24.5vw;width: 24.5vw;border-radius:50%;object-fit: cover;"
										alt="user photo" />
									</div>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ explanation.user.nickname }}
									</span>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<span
									v-if="explanation.user.user_category_id === 3"
									id="status"
									class="text-black ml-2 pl-2 pr-2 box-presidente"
									style="font-size: 1.1vw;">
									Presidente
									</span>
									<span
									v-if="explanation.user.user_category_id !== 5 && explanation.user.user_category_id !== 8"
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ explanation.user.category_party.name_party }}
									</span>
									<div class="d-flex justify-content-center align-items-center mr-2 box-presidente" style="background-color: #198754;">
										<i class="fa-solid fa-microphone mr-2 text-white" style="font-size: 1.2vw"></i>
										<span class="text-white fonts-body" style="font-size: 1.2vw">Falando</span>
									</div>
								</div>
							</div>
						</div>
							<div class="d-flex justify-content-center">
								<div class="box-timerTalkExplanation">
									<div class="d-grid align-items-center justify-content-center" style="top: 20%;position: relative;">
										<div class="progress" style="width: 55vw; height: 2vh;">
											<div class="progress-bar" role="progressbar" style="width: 100%; background-color:#198754" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerz" class="fonts-body" style="font-size:6.9vw;"> {{ cronometro }} </span>
										</div>
										<div class="d-flex justify-content-center align-items-center">
											<span id="timerzz" class="fonts-body" style="font-size:2.2vw;"> Tempo </span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!SeeState" style="position: relative; top: 20%; height:40vh">
							<div v-if="index === 0">
								<div class="d-flex justify-content-center align-items-center text-center">
									<div>
										<i class="fa-solid fa-user" style="color:#007AB8;font-size: 16.6vw;height: 16.5vw;width: 16.5vw;border-radius:50%;object-fit: cover;"></i>
									</div>
								</div>
								<div class="d-flex justify-content-center mt-3">
									<i class="fa-solid fa-hourglass-half" style="font-size: 2.6vw; color: #007AB8"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>

		<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
				<div class="text-white text-center d-flex justify-content-end align-items-center font_footer_17 w-40"
					style="font-size: 1.8vw;">
					<i class="fa-solid fa-clock mr-2"></i>
					Tempo de sessão
					<span class="ml-2" style="color: #DEE2E6">{{ timer_session }}</span>
				</div>
			</div>
    </div>
</template>
<style>
.text-black {
    color: black;
}

.status_circle {
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.status_circle_active {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    border: 2px solid #0db80d;
    background-color: #0db80d;
}

.display_vh {
    height: clamp(0.1vw, 44.6vw, 855px);
}

.display_sign {
    width: clamp(0.1vw, 22.6vw, 410px);
}

.display_cronometro {
    width: clamp(0.1vw, 50vw, 600px);
}

.display_img {
    width: clamp(0.1vw, 20vw, 310px);
    height: clamp(0.1vw, 20vw, 310px);
}

.display_margin {
    margin-right: 12%;
}

@media only screen and (max-width: 1600px) {
    .display_vh {
        height: clamp(0.1vw, 43vw, 690px);
    }

    .display_sign {
        width: clamp(0.1vw, 25.6vw, 410px);
    }

    .display_cronometro {
        width: clamp(0.1vw, 30vw, 500px);
    }

    .display_margin {
        margin-right: 7%;
    }
}

@media only screen and (max-width: 1380px) {
    .display_vh {
        height: clamp(0.1vw, 43vw, 570px);
    }

    .display_sign {
        width: clamp(0.1vw, 28.6vw, 400px);
    }

    .display_margin {
        margin-right: 4%;
    }
}

@media only screen and (max-width: 1280px) {
    .display_vh {
        height: clamp(0.1vw, 43vw, 530px);
    }

    .display_sign {
        width: clamp(0.1vw, 29.6vw, 400px);
    }

    .display_margin {
        margin-right: 2%;
    }
}</style>
  