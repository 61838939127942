<script>
import { useMainStore, useBusStore } from '../../stores/index';

export default {
	name: 'TvHeader',
	components: {},
	setup(){
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		return{
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			brasao: '',
			tribuna: false,
			vote: false,
			discut: false,
			explanation: false,
			Ordem: false,
			session: { name: 'Aguarde o quorum ser iniciado...', },
			socket_ids: [],
			QuorumRemoveUser: false,
			QuorumAddUser: false,
			users_quorums: [],
			users: [],
			User_Tot: [],
			totall: 0,
			presentess: 0,
			ausentess: 0,
			us: [],
			Loading: false,
			session_status_name: '',
			session_id: 0,
		};
	},

	watch: {

		'StoreAll.tribuna': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.tribuna = true;
			} else {
				this.tribuna = false;
			}
		},

		'StoreAll.vote': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.vote = true;
			} else {
				this.vote = false;
			}
		},

		'StoreAll.discut': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.discut = true;
			} else {
				this.discut = false;
			}
		},

		'StoreAll.explanation': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.explanation = true;
			} else {
				this.explanation = false;
			}
		},

		'StoreAll.Ordem': function (novoValor, valorAntigo) {
			if (novoValor === true) {
				this.Ordem = true;
			} else {
				this.Ordem = false;
			}
		},

	},

	created () {
		let OnSocket = setInterval(() => {
			if (this.StoreAll.websocket_host !== null) {
				clearInterval(OnSocket);
				window.Echo.channel(this.StoreAll.websocket_host).listen('.' + this.StoreAll.websocket_host, (e) => {
					if (e.socket_id === 1) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.GetSession();
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 10) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.tribuna = true;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 13) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.tribuna = false;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 21) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.explanation = true;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 24) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.explanation = false;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 25) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.discut = true;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 30) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.discut = false;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 31) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.Ordem = true;
						this.socket_ids.push(e.event_id);
					}

					if (e.socket_id === 34) {
						if (this.socket_ids.includes(e.event_id)) {
							return;
						}

						this.Ordem = false;
						this.socket_ids.push(e.event_id);
					}

				});
			}}, 500);

		let OnSystem = setInterval(() => {
			if (this.StoreAll.socket !== null) {
				clearInterval(OnSystem);

				this.StoreAll.socket.on('6', (e) => {
					if (this.vote === false) {
						this.vote = true;
					}
				});

				this.StoreAll.socket.on('8', (e) => {
					if (this.vote === true) {
						this.vote = false;
					}
				});
			}}, 500);
	},

	mounted() {
		this.GetSession();
		this.Legendas();
		this.BrasaoAndName();
	},

	methods: {
		async GetSession() {
			this.Loading = true;
			await this.StoreAll.Get_Data();
			const { session } = this.StoreAll;
			if (session == null) {
				this.GetSession();
				return;
			}
			const { session_status, id } = session;
			this.session = session;
			this.session_status = session_status.id;
			this.session_id = id;
			this.session_status_name = session_status.name;
		},

		BrasaoAndName(){
			if(this.StoreAll.host == 'dev'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_bd.png');

			}else if(this.StoreAll.host == 'teste'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_bd.png');

			}else if(this.StoreAll.host == 'jc'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_jc.png');

			}else if(this.StoreAll.host == 'bodo'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_bd.png');

			}else if(this.StoreAll.host == 'pedroavelino'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_pa.png');

			}else if(this.StoreAll.host == 'pedrapreta'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_pp.png');

			}else if(this.StoreAll.host == 'campogrande'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_cg.png');

			}else if(this.StoreAll.host == 'parazinho'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_parazinho.png');

			}else if(this.StoreAll.host == 'local'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_afonso.png');

			}else if(this.StoreAll.host == 'macaiba'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_macaiba.png');

			}else if(this.StoreAll.host == 'cc'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_cc.png');

			}else if(this.StoreAll.host == 'lajes'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_lajes.png');

			}else if(this.StoreAll.host == 'veracruz'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_veracruz.png');

			}else if(this.StoreAll.host == 'riodofogo'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_riodofogo.png');

			}else if(this.StoreAll.host == 'caraubas'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_caraubas.png');

			}else if(this.StoreAll.host == 'bentofernandes'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_bentofernandes.png');

			}else if(this.StoreAll.host == 'pg'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_pg.png');

			}else if(this.StoreAll.host == 'galinhos'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_galinhos.png');

			}else if(this.StoreAll.host == 'jandaira'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_jd.png');

			}else if(this.StoreAll.host == 'triunfo'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_tp.png');

			}else if(this.StoreAll.host == 'upanema'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_upanema.png');

			}else if(this.StoreAll.host == 'ja'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_ja.png');

			}else if(this.StoreAll.host == 'angicos'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_angicos.png');

			}else if(this.StoreAll.host == 'crv'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_crv.png');

			}else if(this.StoreAll.host == 'goianinha'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_goianinha.png');
			}
			else if(this.StoreAll.host == 'jucurutu'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_jucurutu.png');
			}
			else if(this.StoreAll.host == 'odb'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_odb.png');
			}
			else if(this.StoreAll.host == 'sm'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_sm.png');
			}
			else if(this.StoreAll.host == 'lp'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_lagoapedra.png');
			}
			else if(this.StoreAll.host == 'sr'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_sr.png');
			}
			else if(this.StoreAll.host == 'bf'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_bf.png');
			}
			else if(this.StoreAll.host == 'sbn'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_sbn.png');
			}
			else if(this.StoreAll.host == 'pureza'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_pureza.png');
			}
			else if(this.StoreAll.host == 'abapp'){
				this.brasao = require('../../assets/img/brasoes/BrasaoCamara_afonso.png');
			}
		},

		Legendas() {
			setTimeout(() => {
				if (this.StoreAll.tribuna === true) {
					this.tribuna = true;
				} else {
					this.tribuna = false;
				}

				if (this.StoreAll.vote === true) {
					this.vote = true;
				} else {
					this.vote = false;
				}

				if (this.StoreAll.discut === true) {
					this.discut = true;
				} else {
					this.discut = false;
				}

				if (this.StoreAll.explanation === true) {
					this.explanation = true;
				} else {
					this.explanation = false;
				}

				if (this.StoreAll.Ordem === true) {
					this.Ordem = true;
				} else {
					this.Ordem = false;
				}

			}, 3000);
		},
	},
};
</script>
<template>
    <div>
      <div class="d-flex justify-content-between ml-1 align-items-center" style="padding-top: 1rem;">
		<div class="d-flex align-items-center w-72">
        	<img :src="brasao" class="justify-content-start mt-1 display_image" />
			<div>
				<div class="space">
					<span class="font-text-header"> {{ this.StoreAll.name_camara }}</span>
				</div>
				<div class="space">
					<span class="font-text-subtitle">{{ session.name }}</span>
				</div>
			</div>
		</div>

		<div class="d-flex w-23">
			<div class="LocaleNow" :class="{'LocaleNowExplanation': explanation === true && Ordem === false, 'LocaleNowVoteSecret': vote === true && Ordem === false && StoreAll.vote_secret}">
				<span v-if="(vote === false && tribuna == false && discut == false && explanation == false && Ordem === false)" class="text-white font-Local">Quórum</span>
				<span v-else-if="vote === true && Ordem === false && !StoreAll.vote_secret" class="text-white font-Local">Votação</span>
				<span v-else-if="vote === true && Ordem === false && StoreAll.vote_secret" class="text-white font-Local">Votação Secreta</span>
				<span v-else-if="tribuna === true && Ordem === false" class="text-white font-Local">Tribuna</span>
				<span v-else-if="discut === true && Ordem === false" class="text-white font-Local">Discussão</span>
				<span v-else-if="explanation === true && Ordem === false" class="text-white font-Local">Explanações</span>
				<span v-else-if="Ordem === true" class="text-white font-Local">Ordem</span>
				<span v-else class="text-white font-Local"></span>
			</div>
		</div>
      </div>
  </div>
</template>
<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

.text-black {
  color: black;
}

.display_image {
	width: 120px;
    height: 110px;
    margin-left: 1rem;
}
.space {
	height: 45px;
}
.font-text-header {
	color: #DEE2E6;
	font-family: "Roboto", serif;
	font-size: 1.85vw;
	font-weight: 700 !important;
	margin-left: 1.5rem;
}

.font-text-subtitle {
	color: #ADB5BD;
	font-family: "Roboto", serif;
	font-size: 1.85vw;
	font-weight: 400 !important;
	margin-left: 1.5rem;
}

.LocaleNow {
	background-color: #007AB8;
	border-radius: 10px;
	margin-right: 2rem;
	width: 200px;
	height: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.LocaleNowExplanation{
	background-color: #007AB8;
	border-radius: 10px;
	margin-right: 2rem;
	width: 18vw !important;
	height: 10vh !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.LocaleNowVoteSecret {
	background-color: #007AB8;
	border-radius: 10px;
	margin-right: 2rem;
	width: 23vw !important;
	height: 10vh !important;
	display: flex;
	align-items: center;
	justify-content: center;
}

.font-Local{
	font-family: "Roboto", serif;
	font-size: 2.3vw;
	font-weight: 600 !important;
	display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (min-width: 500px) {
	.display_image {
		width: 90px;
        height: 85px;
	}

	.space {
		height: 35px;
	}

	.font-Local{
		font-size: 2.8vw;
	}

	.img_legis_footer_17 {
		width: 40px;
        height: 35px;
	}
}

@media only screen and (min-width: 1300px) {
	.display_image {
		width: 100px;
        height: 95px;
	}

	.space {
		height: 35px;
	}

	.font-Local{
		font-size: 2.8vw;
	}

	.img_legis_footer_17 {
		width: 45px;
        height: 40px;
	}
}

@media only screen and (min-width: 1900px) {
	.display_image {
		width: 150px;
        height: 130px;
	}

	.space {
		height: 55px;
	}

	.LocaleNow {
		width: 250px;
		height: 100px;
	}

	.font-Local{
		font-size: 2.8vw;
	}

	.img_legis_footer_17 {
		width: 60px;
        height: 50px;
	}
}

@media only screen and (min-width: 2540px) {
	.display_image {
		width: 200px;
        height: 180px;
	}

	.space {
		height: 65px;
	}

	.LocaleNow {
		width: 340px;
		height: 140px;
	}

	.font-Local{
		font-size: 3vw;
	}

	.img_legis_footer_17 {
		width: 80px;
        height: 70px;
	}
}

@media only screen and (min-width: 3600px) {
	.display_image {
		width: 290px;
        height: 250px;
	}

	.space {
		height: 95px;
	}

	.LocaleNow {
		width: 500px;
        height: 180px;
	}

	.font-Local{
		font-size: 3vw;
	}

	.img_legis_footer_17 {
		width: 125px;
        height: 100px;
	}
}
/* @media only screen and (max-width: 1650px) {
  .display_image {
    width: 135px;
    height: 110px;
  }
}

@media only screen and (max-width: 1380px) {
  .display_image {
    width: 120px;
    height: 105px;
  }
}

@media only screen and (max-width: 1280px) {
  .display_image {
    width: 120px;
    height: 95px;
  }
} */
</style>
