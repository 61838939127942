<script>
import { useMainStore } from '../../../stores/index';

export default {
	name: 'LayoutUsersVote',
	setup() {
		const StoreAll = useMainStore();
		StoreAll.User();
		return {
			StoreAll: StoreAll
		};
	},
	props: {
		Users: {
			type: Array,
			required: true
		}
	},
	data() {
		return {

		};
	},

	created () {

	},

	mounted() {

	},

	methods: {
		isPresent(user) {
			return user[1] === 'P';
		},
		userStatus(user) {
			if (user[2] === 'F') {
				return 'Favor';
			} else if (user[2] === 'C') {
				return 'Contra';
			} else if (user[2] === 'V') {
				return 'Abstenção';
			} else {
				return 'Aguardando';
			}
		},
		userImagePath(user) {
			return this.StoreAll.Dochost + user.path_image;
		}
	},
};
</script>
<template>
  <div class="container-body">
	<div v-if="this.StoreAll.Large_host == 17" style="height: 62vh;">
		<div class="d-flex" style="height: 17%;">
			<div v-for="(user, index) in Users.slice(0, 3)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" style="width: 4.5vw;height: 4.5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

							<div>
								<div >
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 17%;">
			<div v-for="(user, index) in Users.slice(3, 6)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" style="width: 4.5vw;height: 4.5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

							<div>
								<div >
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 17%;">
			<div v-for="(user, index) in Users.slice(6, 9)" :key="index" class="w-100" style="margin-right: 1rem;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" style="width: 4.5vw;height: 4.5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

								<div>
									<div >
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
		<div class="d-flex" style="height: 17%;">
			<div v-for="(user, index) in Users.slice(9, 12)" :key="index" class="w-100" style="margin-right: 1rem;">
					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" style="width: 4.5vw;height: 4.5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

								<div>
									<div >
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
        <div class="d-flex" style="height: 17%;">
			<div v-for="(user, index) in Users.slice(12, 15)" :key="index" class="w-100" style="margin-right: 1rem;">
					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" style="width: 4.5vw;height: 4.5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

								<div>
									<div >
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
        <div class="d-flex" style="height: 17%;">
			<div v-for="(user, index) in Users.slice(15, 16)" :key="index" class="w-100" style="margin-right: 1rem;">
					<div class="d-flex container-vereador py-2" style="width: 32.5%;">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" style="width: 4.5vw;height: 4.5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

								<div>
									<div >
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
	</div>
	<div v-if="this.StoreAll.Large_host == 13" style="height: 62vh;">
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(0, 3)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" style="width: 5vw;height: 5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

							<div>
								<div >
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(3, 6)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" style="width: 5vw;height: 5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

							<div>
								<div >
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(6, 9)" :key="index" class="w-100" style="margin-right: 1rem;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" style="width: 5vw;height: 5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

								<div>
									<div >
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(9, 12)" :key="index" class="w-100" style="margin-right: 1rem;">
					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" style="width: 5vw;height: 5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

								<div>
									<div >
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
	</div>
	<div v-if="this.StoreAll.Large_host == 11" style="height: 62vh;">
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(0, 3)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" style="width: 5vw;height: 5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

							<div>
								<div >
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(3, 6)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" style="width: 5vw;height: 5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

							<div>
								<div >
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(6, 9)" :key="index" class="w-100" style="margin-right: 1rem;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" style="width: 5vw;height: 5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

								<div>
									<div >
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(9, 10)" :key="index" class="w-100" style="margin-right: 1rem;">
					<div class="d-flex container-vereador py-2" style="width: 32%;">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" style="width: 5vw;height: 5vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

								<div>
									<div >
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
	</div>
	<div v-if="this.StoreAll.Large_host == 9" style="height: 62vh;">
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(0, 2)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" style="width: 6vw;height: 6vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

							<div>
								<div >
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(2, 4)" :key="index" class="w-100" style="margin-right: 1rem;">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
						<div class="d-flex align-items-center">
							<img :src="userImagePath(user)" style="width: 6vw;height: 6vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

							<div>
								<div >
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.nickname }}
									</span>
								</div>

								<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(4, 6)" :key="index" class="w-100" style="margin-right: 1rem;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" style="width: 6vw;height: 6vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

								<div>
									<div >
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
		<div class="d-flex" style="height: 23.5%;">
			<div v-for="(user, index) in Users.slice(6, 8)" :key="index" class="w-100" style="margin-right: 1rem;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start" style="border-radius: 10px;">
							<div class="d-flex align-items-center">
								<img :src="userImagePath(user)" style="width: 6vw;height: 6vw;border-radius: 50%;object-fit: cover;" alt="user photo" />

								<div>
									<div >
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.nickname }}
										</span>
									</div>

									<div v-if="isPresent(user)" class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
                                    <div v-if="userStatus(user) === 'Favor'" class="d-flex justify-content-center align-items-center ml-2" style="width: 70%;height: 2vw; background-color: #198754; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Favor</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Contra'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #DC3545; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-white fonts-body" style="font-size: 1.2vw">Contra</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Abstenção'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #E9ECEF; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Abstenção</span>
                                    </div>
                                    <div v-if="userStatus(user) === 'Aguardando'" class="d-flex justify-content-center align-items-center ml-2" style="width: 100%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
                                    </div>
								</div>

                                <div v-else class="d-flex">
									<span
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.category_party.name_party }}
									</span>
									<span
									id="status"
									class="text-white pl-2 pr-2 box-ausente-status ml-2"
									style="font-size: 1.1vw;">
									Ausente
									</span>
								</div>

								</div>
							</div>
						</div>
					</div>
			</div>
		</div>
	</div>
  </div>
</template>
<style>

</style>