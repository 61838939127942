import axios from 'axios';
import router from './router';

const url = window.location.href;
const startIndex = url.indexOf('https://') + 8;
const pathIndex = url.indexOf('/', startIndex);
const result = url.substring(startIndex, pathIndex);

const bases = {
	'localhost:8080': 'devapp.smartlegis.net.br',
	'192.168.0.100:8080': 'devapp.smartlegis.net.br',
	'dev.smartlegis.net.br': 'devapp.smartlegis.net.br',
	'jc.smartlegis.net.br': 'jcapp.smartlegis.net.br',
	'bd.smartlegis.net.br': 'bodoapp.smartlegis.net.br',
	'pa.smartlegis.net.br': 'paapp.smartlegis.net.br',
	'pp.smartlegis.net.br': 'ppapp.smartlegis.net.br',
	'cg.smartlegis.net.br': 'cgapp.smartlegis.net.br',
	'parazinho.smartlegis.net.br': 'parazinhoapp.smartlegis.net.br',
	'jd.smartlegis.net.br': 'jandairaapp.smartlegis.net.br',
	'tp.smartlegis.net.br': 'tpapp.smartlegis.net.br',
	'upanema.smartlegis.net.br': 'upnapp.smartlegis.net.br',
	'ja.smartlegis.net.br': 'jaapp.smartlegis.net.br',
	'teste.smartlegis.net.br': 'sandbox.smartlegis.net.br',
	'angicos.smartlegis.net.br': 'angicosapp.smartlegis.net.br',
	'crv.smartlegis.net.br': 'crvapp.smartlegis.net.br',
	'goianinha.smartlegis.net.br': 'goianinhaapp.smartlegis.net.br',
	'odb.smartlegis.net.br': 'odbapp.smartlegis.net.br',
	'jucurutu.smartlegis.net.br': 'jucurutuapp.smartlegis.net.br',
	'sm.smartlegis.net.br': 'smapp.smartlegis.net.br',
	'lp.smartlegis.net.br': 'lpapp.smartlegis.net.br',
	'sr.smartlegis.net.br': 'srapp.smartlegis.net.br',
	'bf.smartlegis.net.br': 'bfapp.smartlegis.net.br',
	'sbn.smartlegis.net.br': 'sbnapp.smartlegis.net.br',
	'pureza.smartlegis.net.br': 'purezaapp.smartlegis.net.br',
	'afonsobezerra.smartlegis.net.br': 'abapp.smartlegis.net.br',
	'macaiba.smartlegis.net.br': 'macaibaapp.smartlegis.net.br',
	'veracruz.smartlegis.net.br': 'veracruzapp.smartlegis.net.br',
	'galinhos.smartlegis.net.br': 'galinhosapp.smartlegis.net.br',
	'riodofogo.smartlegis.net.br': 'riodofogoapp.smartlegis.net.br',
	'caraubas.smartlegis.net.br': 'caraubasapp.smartlegis.net.br',
	'bentofernandes.smartlegis.net.br': 'bentofernandesapp.smartlegis.net.br',
	'pg.smartlegis.net.br': 'pgapp.smartlegis.net.br',
	'lajes.smartlegis.net.br': 'lajesapp.smartlegis.net.br',
	'cc.smartlegis.net.br': 'ccapp.smartlegis.net.br',
};

let linkBase = '';

if(result == 'dev.smartlegis.net.br'){
	linkBase = 'https://smartsocketdev.smartlegis.net.br/'; //3030 bicause port 6000 don´t work...
}else if(result == 'localhost:8080'){
	linkBase = 'https://smartsocketdev.smartlegis.net.br/'; //3030 bicause port 6000 don´t work...
}else if(result == '192.168.0.100:8080'){
	linkBase = 'https://smartsocketdev.smartlegis.net.br/';//3030 bicause port 6000 don´t work...
}else{
	linkBase = 'https://smartsocket.smartlegis.net.br/';
}

const base = bases[result] || false;



const getAPI = axios.create({
	baseURL: 'https://'+base+'/api/',
	timeout: 600000,
});
const AxiosAPI = axios.create({
	baseURL: 'https://'+base+'/api/',
	timeout: 600000,
});

const SocketAPI = axios.create({
	baseURL: linkBase,
	timeout: 600000,
});
/* interceptors */
getAPI.interceptors.response.use(
	response => {
		return response;
	},
	function(error) {
		if (error.response.status === 401) {
			router.push({ name: 'Login' });
		}
		return Promise.reject(error);
	}
);



export { getAPI, AxiosAPI, SocketAPI};
