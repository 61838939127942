<script>
import SidenavItem from './SidenavItem.vue';
import SidenavCollapse from './SidenavCollapse.vue';
import SidenavCard from './SidenavCard.vue';
import { useMainStore } from '../../stores/index';
export default {
	name: 'SidenavList',
	components: {
		SidenavItem,
		SidenavCollapse,
		SidenavCard,
	},
	setup() {
		const StoreAll = useMainStore();
		return {
			StoreAll: StoreAll,
		};
	},
	data(){
		return{
			session_id: 'Hoje',
		};
	},
	methods: {
		getRoute() {
			const routeArr = this.$route.path.split('/');
			return routeArr[1];
		}
	}
};
</script>

<template>
<div>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100 overflow-hidden"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="dashboardsExamples"
          nav-text="Tarefas"
          :class="getRoute() === 'dashboards' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <!--Alterar params: { id: '1' } -->
              <sidenav-item
                :to="{ name: 'Sessão da semana',params: { id: session_id } }"
                mini-icon="S"
                text="Sessão da semana"
              />
              <sidenav-item
                :to="{ name: 'Histórico de sessões' }"
                mini-icon="H"
                text="Histórico de sessões"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Documentos"
          :class="getRoute() === 'pages' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-ungroup text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->

              <sidenav-item
                :to="{ name: 'Envio de documentos' }"
                mini-icon="E"
                text="Envio de Documento"
              />
              <div v-if="StoreAll.user.user_category_id == 1">
              <sidenav-item :to="{ name: 'Documentos recebidos' }" mini-icon="R" text="Documentos recebidos" />
              </div>
              <!--<div  v-if="StoreAll.comission == true">
                <sidenav-item :to="{ name: 'Documentos recebidos comissão de justiça' }" mini-icon="R" text="Documentos recebidos" />
              </div>-->
              <sidenav-item
                :to="{ name: 'Pesquisa de documentos gerais' }"
                mini-icon="P"
                text="Todos os documentos"
              />
              <div v-if="StoreAll.user.user_category_id == 1">
              </div>
              <div v-if="StoreAll.user.user_category_id == 4">
                <sidenav-item
                :to="{ name: 'Documentos procurador' }"
                mini-icon="DP"
                text="Documentos procurador"
              />
              </div>
              <div v-if="StoreAll.user.user_category_id == 4">
                <sidenav-item
                :to="{ name: 'Documentos com parecer' }"
                mini-icon="DCP"
                text="Documentos com parecer"
              />
              </div>
              <div v-if="StoreAll.user.user_category_id == 5">
                <sidenav-item
                :to="{ name: 'Documentos recebidos prefeitura' }"
                mini-icon="DR"
                text="Documentos prefeitura"
              />
              </div>
              <div v-else>
                <sidenav-item
                :to="{ name: 'Meus documentos enviados' }"
                mini-icon="P"
                text="Meus documentos enviados"
              />
              <sidenav-item
                :to="{ name: 'Documentos para assinatura' }"
                mini-icon="DA"
                text="Documentos a assinar"
              />
              <sidenav-item
                :to="{ name: 'Documentos do legislativo' }"
                mini-icon="DL"
                text="Documentos do legislativo"
              />
              <sidenav-item
                :to="{ name: 'Relatório de documentos' }"
                mini-icon="RD"
                text="Relatório de documentos"
              />
              <sidenav-item
                v-if="
                  StoreAll.user.user_category_id == 1 && StoreAll.host === 'goianinha'
                || StoreAll.user.user_category_id == 2 && StoreAll.host === 'goianinha'
                || StoreAll.user.user_category_id == 3 && StoreAll.host === 'goianinha'
                || StoreAll.user.user_category_id == 1 && StoreAll.host === 'local'
                || StoreAll.user.user_category_id == 2 && StoreAll.host === 'local'
                || StoreAll.user.user_category_id == 3 && StoreAll.host === 'local'
                || StoreAll.user.user_category_id == 1 && StoreAll.host === 'teste'
                || StoreAll.user.user_category_id == 2 && StoreAll.host === 'teste'
                || StoreAll.user.user_category_id == 3 && StoreAll.host === 'teste'"
                :to="{ name: 'Analise de documentos' }"
                mini-icon="AD"
                text="Analise de documentos"
              />
              <sidenav-item
                v-if="StoreAll.comission_user == true"
                :to="{ name: 'Parecer signer' }"
                mini-icon="PS"
                text="Pareceres a assinar"
              />
              </div>
              <sidenav-item
                :to="{ name: 'Modelo de documentos' }"
                mini-icon="M"
                text="Modelo de documentos"
              />

              <sidenav-item
                v-if="StoreAll.comission_justice == true"
                :to="{ name: 'Comissão de justiça' }"
                mini-icon="CJ"
                text="CM DE JUSTIÇA"
              />
              <sidenav-item
                v-if="StoreAll.comission_education == true"
                :to="{ name: 'Comissão da educação' }"
                mini-icon="CO"
                text="CM DE ORÇAMENTO"
              />
              <sidenav-item
                v-if="StoreAll.comission_health == true"
                :to="{ name: 'Comissão da saúde' }"
                mini-icon="COS"
                text="CM DE OBRAS"
              />
              <sidenav-item
              v-if="StoreAll.comission_infra == true"
                :to="{ name: 'Comissão de infraestrutura' }"
                mini-icon="CS"
                text="CM DE SAÚDE"
              />
              <sidenav-item
                v-if="StoreAll.comission_human == true"
                :to="{ name: 'Comissão de direitos humanos' }"
                mini-icon="CP"
                text="CM DE PLANEJAMENTO"
              />
              <sidenav-item
                v-if="StoreAll.comission_farm == true"
                :to="{ name: 'Comissão da fazenda' }"
                mini-icon="CDE"
                text="CM DESENVOLVIMENTO ECONÔMICO"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

    <div v-if="StoreAll.user.user_category_id == 6">
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="applicationsExamples"
          nav-text="TV"
          :class="getRoute() === 'applications' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-ui-04 text-info text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <!-- alterar para UPANEMA E GOIANINHA-->
              <sidenav-item
              v-if="StoreAll.host != 'upanema' && StoreAll.host != 'goianinha' && StoreAll.host != 'sm' && StoreAll.host != 'macaiba'"
                :to="{ name: 'Tela de sessão' }"
                mini-icon="TV"
                text="Tela da sessão"
              />

              <sidenav-item
              v-else
                :to="{ name: 'Tela de sessão up' }"
                mini-icon="TV"
                text="Tela da sessão"
              />
              <div v-if="StoreAll.user.user_category_id == 9">
                <sidenav-item
                :to="{ name: 'Controle da discussão' }"
                mini-icon="DS"
                text="Controle da discussão"
              />

              <sidenav-item
                :to="{ name: 'Controle da Tribuna' }"
                mini-icon="TR"
                text="Controle da Tribuna"
              />
              </div>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      </div>
      <div v-else>
      </div>
    </ul>

  </div>
  <div class="mt-3 sidenav-footer">
    <sidenav-card
      :card="{
        title: 'Precisa de ajuda?',
        description: 'Entre em contato pelo whatsapp',
        links: [
          {
            label: 'WhatsApp',
            route:
              'https://wa.me/558491374004',
            color: 'success'
          }
        ]
      }"
    />
  </div>
  </div>
</template>
