<script>
import { useMainStore } from '../../../stores/index';

export default {
	name: 'LayoutUsersTribuna',
	setup() {
		const StoreAll = useMainStore();
		StoreAll.User();
		return {
			StoreAll: StoreAll
		};
	},
	props: {
		UserTribuna: {
			type: Array,
			required: true
		},
		timerPause : {
			type: String,
			required: true
		}
	},
	data() {
		return {
			subscribeOn: false
		};
	},

	created () {

	},

	mounted() {

	},

	methods: {
		isPresent(user) {
			return user[1] === 'P';
		},
		userStatus(user) {
			return this.isPresent(user) ? 'Presente' : 'Ausente';
		},
		userImagePath(user) {
			return this.StoreAll.Dochost + user.path_image;
		}
	},
};
</script>
<template>
	<div>
	<div v-if="!this.StoreAll.timerSubscribeEnd && StoreAll.tvlength === 1" class="container-body">
		<div v-if="this.StoreAll.Large_host == 17" style="height: 62vh;">
			<div class="d-flex w-100" style="height: 12.5%;">
				<div v-for="(user, index) in UserTribuna.slice(0, 1)" :key="index" class="w-100">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">
								<img :src="userImagePath(user.user)" style="height: 3.5vw;width: 3.5vw;border-radius:50%;object-fit: cover;" alt="user photo" />
								<div class="d-flex align-items-center w-100">
									<div class="d-flex align-items-center justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>
									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 35%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
											<span class="text-black fonts-body" style="font-size: 1.2vw">Próximo</span>
										</div>

										<div v-if="user.state === 'P'" class="d-flex w-25 align-items-center">
											<span class="text-white fonts-body" style="font-size: 1.2vw">{{ timerPause }}</span>
											<div class="d-flex justify-content-center align-items-center mr-2 ml-2" style="width: 35%;height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-pause" style="font-size: 1.2vw"></i>
											</div>
										</div>

										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex" style="height: 95%;">
				<div class="w-100" :class="{'mr-2': UserTribuna.length > 10}">
					<div v-for="(user, index) in UserTribuna.slice(1, 10)" :key="index" style="width: 100%;height: 11.5%;">

						<div class="d-flex container-vereador py-2 w-100">
							<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
								<div class="d-flex align-items-center w-100">

									<div class="d-flex align-items-center w-100">
										<div class="justify-content-start mb-1 w-50">
											<span
											class="ml-2 fonts-body"
											style="font-size: 1.6vw">
											{{ index + 2 }}) {{ user.user.nickname }}
											</span>
										</div>

										<div class="d-flex justify-content-end w-50">
											<span
											v-if="user.user.user_category_id === 3"
											id="status"
											class="text-black ml-1 pl-1 pr-1 box-presidente"
											style="font-size: 1.1vw;">
											Presidente
											</span>
											<span
											v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
											id="status"
											class="text-white mr-2 pl-2 pr-2 box-partido"
											style="border:1px solid white; font-size: 1.1vw;">
											{{ user.user.category_party.name_party }}
											</span>
											<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-hourglass-half" style="font-size: 1.2vw"></i>
											</div>
											<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="UserTribuna.length > 10" class="w-100">
							<div v-for="(user, index) in UserTribuna.slice(10, 19)" :key="index" style="width: 100%;height: 11.5%;">

						<div class="d-flex container-vereador py-2 w-100">
							<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
								<div class="d-flex align-items-center w-100">

									<div class="d-flex align-items-center w-100">
										<div class="justify-content-start mb-1 w-50">
											<span
											class="ml-2 fonts-body"
											style="font-size: 1.6vw">
											{{ index + 11 }}) {{ user.user.nickname }}
											</span>
										</div>

										<div class="d-flex justify-content-end w-50">
											<span
											v-if="user.user.user_category_id === 3"
											id="status"
											class="text-black ml-1 pl-1 pr-1 box-presidente"
											style="font-size: 1.1vw;">
											Presidente
											</span>
											<span
											v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
											id="status"
											class="text-white mr-2 pl-2 pr-2 box-partido"
											style="border:1px solid white; font-size: 1.1vw;">
											{{ user.user.category_party.name_party }}
											</span>
											<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-hourglass-half" style="font-size: 1.2vw"></i>
											</div>
											<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="this.StoreAll.Large_host == 13" style="height: 62vh;">
			<div class="d-flex w-100" style="height: 14.5%;">
				<div v-for="(user, index) in UserTribuna.slice(0, 1)" :key="index" class="w-100">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">
								<img :src="userImagePath(user.user)" style="height: 4vw;width: 4vw;border-radius:50%;object-fit: cover;" alt="user photo" />
								<div class="d-flex align-items-center w-100">
									<div class="d-flex align-items-center justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>
									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 30%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Próximo</span>
                                    </div>

									<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
										<span class="text-white fonts-body mr-1" style="font-size: 1.2vw; width: 6vw;">{{ timerPause }}</span>
										<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw;"></i>
										<span class="text-black fonts-body text-black" style="font-size: 1.2vw;">Pausado</span>
										</div>
									</div>

									<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 55%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
										<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
									<span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
									</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex" style="height: 95%;">
				<div class="w-100" :class="{'mr-2': UserTribuna.length > 8}">
					<div v-for="(user, index) in UserTribuna.slice(1, 8)" :key="index" style="width: 100%;height: 15%;">

						<div class="d-flex container-vereador py-2 w-100">
							<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
								<div class="d-flex align-items-center w-100">

									<div class="d-flex align-items-center w-100">
										<div class="justify-content-start mb-1 w-50">
											<span
											class="ml-2 fonts-body"
											style="font-size: 1.6vw">
											{{ index + 2 }}) {{ user.user.nickname }}
											</span>
											<span
											v-if="user.user.user_category_id === 3"
											id="status"
											class="text-black ml-2 pl-2 pr-2 box-presidente"
											style="font-size: 1.1vw;">
											Presidente
											</span>
										</div>

										<div class="d-flex justify-content-end w-50">
											<span
											v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
											id="status"
											class="text-white mr-2 pl-2 pr-2 box-partido"
											style="border:1px solid white; font-size: 1.1vw;">
											{{ user.user.category_party.name_party }}
											</span>
											<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-hourglass-half" style="font-size: 1.2vw"></i>
											</div>
											<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="UserTribuna.length > 8" class="w-100">
							<div v-for="(user, index) in UserTribuna.slice(8, 15)" :key="index" style="width: 100%;height: 15%;">

						<div class="d-flex container-vereador py-2 w-100">
							<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
								<div class="d-flex align-items-center w-100">

									<div class="d-flex align-items-center w-100">
										<div class="justify-content-start mb-1 w-50">
											<span
											class="ml-2 fonts-body"
											style="font-size: 1.6vw">
											{{ index + 9 }}) {{ user.user.nickname }}
											</span>
											<span
											v-if="user.user.user_category_id === 3"
											id="status"
											class="text-black ml-2 pl-2 pr-2 box-presidente"
											style="font-size: 1.1vw;">
											Presidente
											</span>
										</div>

										<div class="d-flex justify-content-end w-50">
											<span
											v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
											id="status"
											class="text-white mr-2 pl-2 pr-2 box-partido"
											style="border:1px solid white; font-size: 1.1vw;">
											{{ user.user.category_party.name_party }}
											</span>
											<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-hourglass-half" style="font-size: 1.2vw"></i>
											</div>
											<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="this.StoreAll.Large_host == 11" style="height: 62vh;">
			<div class="d-flex w-100" style="height: 14.5%;">
				<div v-for="(user, index) in UserTribuna.slice(0, 1)" :key="index" class="w-100">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">
								<img :src="userImagePath(user.user)" style="height: 4vw;width: 4vw;border-radius:50%;object-fit: cover;" alt="user photo" />
								<div class="d-flex align-items-center w-100">
									<div class="justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>
									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 30%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Próximo</span>
                                    </div>

									<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
										<span class="text-white fonts-body mr-1" style="font-size: 1.2vw; width: 6vw;">{{ timerPause }}</span>
										<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw;"></i>
										<span class="text-black fonts-body text-black" style="font-size: 1.2vw;">Pausado</span>
										</div>
									</div>

									<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 55%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
										<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
									<span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
									</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex" style="height: 95%;">
				<div class="w-100" :class="{'mr-2': UserTribuna.length > 7}">
					<div v-for="(user, index) in UserTribuna.slice(1, 7)" :key="index" style="width: 100%;height: 15%;">

						<div class="d-flex container-vereador py-2 w-100">
							<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
								<div class="d-flex align-items-center w-100">

									<div class="d-flex align-items-center w-100">
										<div class="justify-content-start mb-1 w-50">
											<span
											class="ml-2 fonts-body"
											style="font-size: 1.6vw">
											{{ index + 2 }}) {{ user.user.nickname }}
											</span>
											<span
											v-if="user.user.user_category_id === 3"
											id="status"
											class="text-black ml-2 pl-2 pr-2 box-presidente"
											style="font-size: 1.1vw;">
											Presidente
											</span>
										</div>

										<div class="d-flex justify-content-end w-50">
											<span
											v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
											id="status"
											class="text-white mr-2 pl-2 pr-2 box-partido"
											style="border:1px solid white; font-size: 1.1vw;">
											{{ user.user.category_party.name_party }}
											</span>
											<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-hourglass-half" style="font-size: 1.2vw"></i>
											</div>
											<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-if="UserTribuna.length > 7" class="w-100">
							<div v-for="(user, index) in UserTribuna.slice(7, 13)" :key="index" style="width: 100%;height: 15%;">

						<div class="d-flex container-vereador py-2 w-100">
							<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
								<div class="d-flex align-items-center w-100">

									<div class="d-flex align-items-center w-100">
										<div class="justify-content-start mb-1 w-50">
											<span
											class="ml-2 fonts-body"
											style="font-size: 1.6vw">
											{{ index + 7 }}) {{ user.user.nickname }}
											</span>
											<span
											v-if="user.user.user_category_id === 3"
											id="status"
											class="text-black ml-2 pl-2 pr-2 box-presidente"
											style="font-size: 1.1vw;">
											Presidente
											</span>
										</div>

										<div class="d-flex justify-content-end w-50">
											<span
											v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
											id="status"
											class="text-white mr-2 pl-2 pr-2 box-partido"
											style="border:1px solid white; font-size: 1.1vw;">
											{{ user.user.category_party.name_party }}
											</span>
											<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-hourglass-half" style="font-size: 1.2vw"></i>
											</div>
											<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="this.StoreAll.Large_host == 9" style="height: 62vh;">
			<div class="d-flex w-100" style="height: 18%;">
				<div v-for="(user, index) in UserTribuna.slice(0, 1)" :key="index" class="w-100">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">
								<img :src="userImagePath(user.user)" style="height: 5vw;width: 5vw;border-radius:50%;object-fit: cover;" alt="user photo" />
								<div class="d-flex align-items-center w-100">
									<div class="justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ user.user.nickname }}
										</span>
									</div>
									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 35%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
											<span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
										</div>

										<div v-if="user.state === 'P'" class="d-flex w-25 align-items-center">
											<span class="text-white fonts-body" style="font-size: 1.2vw">{{ timerPause }}</span>
											<div class="d-flex justify-content-center align-items-center mr-2 ml-2" style="width: 35%;height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-pause" style="font-size: 1.2vw"></i>
											</div>
										</div>

										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="d-flex" style="height: 95%;">
				<div class="w-100" :class="{'mr-2': UserTribuna.length > 6}">
					<div v-for="(user, index) in UserTribuna.slice(1, 6)" :key="index" style="width: 100%;height: 18%;">

						<div class="d-flex container-vereador py-2 w-100">
							<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
								<div class="d-flex align-items-center w-100">

									<div class="d-flex align-items-center w-100">
										<div class="justify-content-start mb-1 w-50">
											<span
											class="ml-2 fonts-body"
											style="font-size: 1.6vw">
											{{ index + 2 }}) {{ user.user.nickname }}
											</span>
											<span
											v-if="user.user.user_category_id === 3"
											id="status"
											class="text-black ml-2 pl-2 pr-2 box-presidente"
											style="font-size: 1.1vw;">
											Presidente
											</span>
										</div>

										<div class="d-flex justify-content-end w-50">
											<span
											v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
											id="status"
											class="text-white mr-2 pl-2 pr-2 box-partido"
											style="border:1px solid white; font-size: 1.1vw;">
											{{ user.user.category_party.name_party }}
											</span>
											<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-hourglass-half" style="font-size: 1.2vw"></i>
											</div>
											<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div  v-if="UserTribuna.length > 6" class="w-100">
					<div v-for="(user, index) in UserTribuna.slice(6, 11)" :key="index" style="width: 100%;margin-right: 1rem;height: 18%;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">

								<div class="d-flex align-items-center w-100">
									<div class="justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ index + 7 }}) {{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>

									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-hourglass-half" style="font-size: 1.2vw"></i>
										</div>
										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 15%; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else class="container-body-sub-of">
	<div v-if="this.StoreAll.Large_host == 17" style="height: 70vh;">
		<div class="d-flex w-100" style="height: 14.5%;">
			<div v-for="(user, index) in UserTribuna.slice(0, 1)" :key="index" class="w-100">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
						<div class="d-flex align-items-center w-100">
                            <img :src="userImagePath(user.user)" style="height: 3.5vw;width: 3.5vw;border-radius:50%;object-fit: cover;" alt="user photo" />
							<div class="d-flex align-items-center w-100">
								<div class="d-flex align-items-center justify-content-start mb-1 w-50">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.user.nickname }}
									</span>
									<span
                                    v-if="user.user.user_category_id === 3"
                                    id="status"
                                    class="text-black ml-2 pl-2 pr-2 box-presidente"
                                    style="font-size: 1.1vw;">
                                    Presidente
                                    </span>
								</div>
								<div class="d-flex justify-content-end w-50">
									<span
									v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.user.category_party.name_party }}
									</span>
                                    <div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 30%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Próximo</span>
                                    </div>

									<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
										<span class="text-white fonts-body mr-1" style="font-size: 1.2vw; width: 6vw;">{{ timerPause }}</span>
										<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw;"></i>
										<span class="text-black fonts-body text-black" style="font-size: 1.2vw;">Pausado</span>
										</div>
									</div>

									<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 55%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
										<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
									<span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="d-flex" style="height: 95%;">
			<div class="w-100" :class="{'mr-2': UserTribuna.length > 10}">
				<div v-for="(user, index) in UserTribuna.slice(1, 10)" :key="index" style="width: 100%;height: 10.5%;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">

								<div class="d-flex align-items-center w-100">
									<div class="d-flex align-items-center justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ index + 2 }}) {{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>

									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
										</div>

										<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
											<span class="text-white fonts-body mr-1" style="font-size: 1.2vw">{{ timerPause }}</span>
											<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw"></i>
											<span class="text-black fonts-body text-black" style="font-size: 1.2vw">Pausado</span>
											</div>
										</div>

										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white mr-1" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="UserTribuna.length > 10" class="w-100">
						<div v-for="(user, index) in UserTribuna.slice(10, 19)" :key="index" style="width: 100%;height: 10.5%;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">

								<div class="d-flex align-items-center w-100">
									<div class="d-flex align-items-center justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ index + 11 }}) {{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>

									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
										</div>

										<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
											<span class="text-white fonts-body mr-1" style="font-size: 1.2vw">{{ timerPause }}</span>
											<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw"></i>
											<span class="text-black fonts-body text-black" style="font-size: 1.2vw">Pausado</span>
											</div>
										</div>

										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white mr-1" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="this.StoreAll.Large_host == 13" style="height: 70vh;">
		<div class="d-flex w-100" style="height: 14.5%;">
			<div v-for="(user, index) in UserTribuna.slice(0, 1)" :key="index" class="w-100">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
						<div class="d-flex align-items-center w-100">
                            <img :src="userImagePath(user.user)" style="height: 4vw;width: 4vw;border-radius:50%;object-fit: cover;" alt="user photo" />
							<div class="d-flex align-items-center w-100">
								<div class="d-flex align-items-center justify-content-start mb-1 w-50">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.user.nickname }}
									</span>
									<span
                                    v-if="user.user.user_category_id === 3"
                                    id="status"
                                    class="text-black ml-2 pl-2 pr-2 box-presidente"
                                    style="font-size: 1.1vw;">
                                    Presidente
                                    </span>
								</div>
								<div class="d-flex justify-content-end w-50">
									<span
									v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.user.category_party.name_party }}
									</span>
                                    <div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 30%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Próximo</span>
                                    </div>

									<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
										<span class="text-white fonts-body mr-1" style="font-size: 1.2vw; width: 6vw;">{{ timerPause }}</span>
										<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw;"></i>
										<span class="text-black fonts-body text-black" style="font-size: 1.2vw;">Pausado</span>
										</div>
									</div>

									<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 55%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
										<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
									<span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="d-flex" style="height: 95%;">
			<div class="w-100" :class="{'mr-2': UserTribuna.length > 8}">
				<div v-for="(user, index) in UserTribuna.slice(1, 8)" :key="index" style="width: 100%;height: 13.5%;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">

								<div class="d-flex align-items-center w-100">
									<div class="d-flex align-items-center justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ index + 2 }}) {{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>

									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
										</div>

										<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
											<span class="text-white fonts-body mr-1" style="font-size: 1.2vw">{{ timerPause }}</span>
											<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw"></i>
											<span class="text-black fonts-body text-black" style="font-size: 1.2vw">Pausado</span>
											</div>
										</div>

										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white mr-1" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="UserTribuna.length > 8" class="w-100">
						<div v-for="(user, index) in UserTribuna.slice(8, 15)" :key="index" style="width: 100%;height: 13.5%;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">

								<div class="d-flex align-items-center w-100">
									<div class="d-flex align-items-center justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ index + 9 }}) {{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>

									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
										</div>

										<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
											<span class="text-white fonts-body mr-1" style="font-size: 1.2vw">{{ timerPause }}</span>
											<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw"></i>
											<span class="text-black fonts-body text-black" style="font-size: 1.2vw">Pausado</span>
											</div>
										</div>

										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white mr-1" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="this.StoreAll.Large_host == 11" style="height: 70vh;">
		<div class="d-flex w-100" style="height: 14.5%;">
			<div v-for="(user, index) in UserTribuna.slice(0, 1)" :key="index" class="w-100">

				<div class="d-flex container-vereador py-2 w-100">
					<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
						<div class="d-flex align-items-center w-100">
                            <img :src="userImagePath(user.user)" style="height: 4vw;width: 4vw;border-radius:50%;object-fit: cover;" alt="user photo" />
							<div class="d-flex align-items-center w-100">
								<div class="d-flex aling-items-center justify-content-start mb-1 w-50">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.user.nickname }}
									</span>
									<span
                                    v-if="user.user.user_category_id === 3"
                                    id="status"
                                    class="text-black ml-2 pl-2 pr-2 box-presidente"
                                    style="font-size: 1.1vw;">
                                    Presidente
                                    </span>
								</div>
								<div class="d-flex justify-content-end w-50">
									<span
									v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.user.category_party.name_party }}
									</span>
                                    <div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 30%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Próximo</span>
                                    </div>

									<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
										<span class="text-white fonts-body mr-1" style="font-size: 1.2vw; width: 6vw;">{{ timerPause }}</span>
										<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw;"></i>
										<span class="text-black fonts-body text-black" style="font-size: 1.2vw;">Pausado</span>
										</div>
									</div>

									<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 55%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
										<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
									<span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="d-flex" style="height: 95%;">
			<div class="w-100" :class="{'mr-2': UserTribuna.length > 7}">
				<div v-for="(user, index) in UserTribuna.slice(1, 7)" :key="index" style="width: 100%;height: 15%;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">

								<div class="d-flex align-items-center w-100">
									<div class="d-flex aling-items-center justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ index + 2 }}) {{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>

									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
										</div>

										<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
											<span class="text-white fonts-body mr-1" style="font-size: 1.2vw">{{ timerPause }}</span>
											<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw"></i>
											<span class="text-black fonts-body text-black" style="font-size: 1.2vw">Pausado</span>
											</div>
										</div>

										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white mr-1" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="UserTribuna.length > 7" class="w-100">
						<div v-for="(user, index) in UserTribuna.slice(7, 13)" :key="index" style="width: 100%;height: 15%;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">

								<div class="d-flex align-items-center w-100">
									<div class="d-flex aling-items-center justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ index + 8 }}) {{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>

									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
										</div>

										<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
											<span class="text-white fonts-body mr-1" style="font-size: 1.2vw">{{ timerPause }}</span>
											<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw"></i>
											<span class="text-black fonts-body text-black" style="font-size: 1.2vw">Pausado</span>
											</div>
										</div>

										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white mr-1" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-if="this.StoreAll.Large_host == 9" style="height: 70vh;">
		<div class="d-flex w-100" style="height: 18%;">
			<div v-for="(user, index) in UserTribuna.slice(0, 1)" :key="index" class="w-100">

				<div class="d-flex container-vereador w-100">
					<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
						<div class="d-flex align-items-center w-100">
                            <img :src="userImagePath(user.user)" style="height: 5vw;width: 5vw;border-radius:50%;object-fit: cover;" alt="user photo" />
							<div class="d-flex align-items-center w-100">
								<div class="justify-content-start mb-1 w-50">
									<span
									class="ml-2 fonts-body"
									style="font-size: 1.6vw">
									{{ user.user.nickname }}
									</span>
									<span
                                    v-if="user.user.user_category_id === 3"
                                    id="status"
                                    class="text-black ml-2 pl-2 pr-2 box-presidente"
                                    style="font-size: 1.1vw;">
                                    Presidente
                                    </span>
								</div>
								<div class="d-flex justify-content-end w-50">
									<span
									v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
									id="status"
									class="text-white mr-2 pl-2 pr-2 box-partido"
									style="border:1px solid white; font-size: 1.1vw;">
									{{ user.user.category_party.name_party }}
									</span>
                                    <div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 30%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Próximo</span>
                                    </div>

									<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
										<span class="text-white fonts-body mr-1" style="font-size: 1.2vw; width: 6vw;">{{ timerPause }}</span>
										<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw;"></i>
										<span class="text-black fonts-body text-black" style="font-size: 1.2vw;">Pausado</span>
										</div>
									</div>

									<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 55%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
										<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
									<span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="d-flex" style="height: 95%;">
			<div class="w-100" :class="{'mr-2': UserTribuna.length > 6}">
				<div v-for="(user, index) in UserTribuna.slice(1, 6)" :key="index" style="width: 100%;height: 18%;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">

								<div class="d-flex align-items-center w-100">
									<div class="d-flex align-items-center justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ index + 2 }}) {{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>

									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>

										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
										</div>

										<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
											<span class="text-white fonts-body mr-1" style="font-size: 1.2vw">{{ timerPause }}</span>
											<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw"></i>
											<span class="text-black fonts-body text-black" style="font-size: 1.2vw">Pausado</span>
											</div>
										</div>

										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white mr-1" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="UserTribuna.length > 6" class="w-100">
				<div v-for="(user, index) in UserTribuna.slice(6, 11)" :key="index"  style="width: 100%;height: 18%;">

					<div class="d-flex container-vereador py-2 w-100">
						<div class="d-flex ml-2 justify-content-start w-100" style="border-radius: 10px;">
							<div class="d-flex align-items-center w-100">

								<div class="d-flex align-items-center w-100">
									<div class="d-flex align-items-center justify-content-start mb-1 w-50">
										<span
										class="ml-2 fonts-body"
										style="font-size: 1.6vw">
										{{ index + 7 }}) {{ user.user.nickname }}
										</span>
										<span
										v-if="user.user.user_category_id === 3"
										id="status"
										class="text-black ml-2 pl-2 pr-2 box-presidente"
										style="font-size: 1.1vw;">
										Presidente
										</span>
									</div>

									<div class="d-flex justify-content-end w-50">
										<span
										v-if="user.user.user_category_id !== 5 && user.user.user_category_id !== 8"
										id="status"
										class="text-white mr-2 pl-2 pr-2 box-partido"
										style="border:1px solid white; font-size: 1.1vw;">
										{{ user.user.category_party.name_party }}
										</span>
										<div v-if="user.state === 'A'" class="d-flex justify-content-center align-items-center mr-2" style="width: 50%;height: 2vw; background-color: #FFC107; border-radius: 32px; padding: 8px 0px 8px 0px;">
                                        <i class="fa-solid fa-hourglass-half mr-2" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body" style="font-size: 1.2vw">Aguardando</span>
										</div>

										<div v-if="user.state === 'P'" class="d-flex align-items-center" style="width: 12vw;">
											<span class="text-white fonts-body mr-1" style="font-size: 1.2vw">{{ timerPause }}</span>
											<div class="d-flex justify-content-center align-items-center mr-2 w-100" style="height: 2vw; background-color: #DEE2E6; border-radius: 32px; padding: 8px 0px 8px 0px;">
												<i class="fa-solid fa-pause mr-1" style="font-size: 1.2vw"></i>
											<span class="text-black fonts-body text-black" style="font-size: 1.2vw">Pausado</span>
											</div>
										</div>

										<div v-if="user.state === 'FS'" class="d-flex justify-content-center align-items-center mr-2" style="width: 55%;height: 2vw; background-color: #6C757D; border-radius: 32px; padding: 8px 0px 8px 0px;">
											<i class="fa-solid fa-microphone-slash text-white" style="font-size: 1.2vw"></i>
                                        <span class="text-black fonts-body text-white" style="font-size: 1.2vw">Finalizado</span>
										</div>
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div
		></div>
	</div>
  </div>
  </div>
</template>
<style scoped>
.container-body {
    margin-top: 2rem;
    margin-left: 1.5rem;
	height: 70vh;
    width: 63vw;
}

.container-body-sub-of {
    margin-top: 2rem;
    margin-left: 1rem;
	height: 70vh;
	width: 98vw;
}

.container-vereador {
    background-color: #023866;
	border-radius: 32px;
	margin-right: 0.5rem;
}

.img_display{
	width: 7vw;
    height: 7vw;
	border-radius: 50%;
	object-fit: cover;
}

.box-presente-status {
	display: flex;
    width: 6vw;
	height: 2vw;
	justify-content: center;
	font-family: "Roboto", serif;
    align-items: center;
	font-weight: 500 !important;
	background-color: #20875b;
	border-radius: 36px;
}

.box-ausente-status {
	display: flex;
    width: 6vw;
	height: 2vw;
	justify-content: center;
	font-family: "Roboto", serif;
    align-items: center;
	font-weight: 500 !important;
	background-color: #6C757D;
	border-radius: 36px;
}

.box-partido {
	display: flex;
    width: 5vw;
	height: 2vw;
	margin-left: 0.3rem;
	justify-content: center;
	font-family: "Roboto", serif;
	font-weight: 500 !important;
    align-items: center;
	border-radius: 36px;
}



</style>