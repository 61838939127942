<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useBusStore } from '../../stores/index';

export default {
	name: 'TvBody',
	setup() {
		const StoreAll = useMainStore();
		const Bus = useBusStore();
		StoreAll.Token();
		StoreAll.User();
		return {
			StoreAll: StoreAll,
			Bus
		};
	},
	data() {
		return {
			session_id: 0,
			tela: '',
			Loading: true,
			dia: '',
			mes: '',
			time: '',
		};
	},



	mounted() {
		this.GetSession();
	},

	methods: {
		async GetSession() {
			this.Loading = true;
			await this.StoreAll.Get_Data();
			const { session } = this.StoreAll;
			if (session == null) {
				this.GetSession();
				return;
			}
			const { session_status, id } = session;
			this.session = session;
			this.session_status = session_status.id;
			this.session_id = id;
			this.session_status_name = session_status.name;

			this.Hora();
			setInterval(this.Hora, 1000);
		},

		Hora() {
			let date_time = new Date();
			let dia = date_time.getDate();
			let mesesEmPortugues = [
				'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
				'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
			];
			let mes = mesesEmPortugues[date_time.getMonth()];

			let minutes = date_time.getMinutes().toString().padStart(2, '0');
			let hours = date_time.getHours().toString().padStart(2, '0');
			let seconds = date_time.getSeconds().toString().padStart(2, '0');

			this.time = `${hours}: ${minutes}: ${seconds}`;
			this.dia = dia;
			this.mes = mes;
		},

	},
};
</script>
<style>
.text-black {
  color: black;
}

.display_fast_body {
  width: clamp(0.1vw, 8.5vw, 300px);
  height: clamp(0.1vw, 8.5vw, 300px);
}

.display_body_nine {
  width: clamp(0.1vw, 25vw, 475px);
}

.font_element_vote {
  font-size: 27px;
}
@media only screen and (max-width: 1600px) {
  .display_fast_body {
    width: clamp(0.1vw, 8vw, 300px);
    height: clamp(0.1vw, 8vw, 300px);
  }

  .font_element_vote {
  font-size: 21px;
}
}

@media only screen and (max-width: 1380px) {
  .display_fast_body {
    width: clamp(0.1vw, 7.5vw, 300px);
    height: clamp(0.1vw, 7.5vw, 300px);
  }

  .display_body_nine {
    width: clamp(0.1vw, 25vw, 330px);
  }

  .font_element_vote {
  font-size: 19px;
}
}

@media only screen and (max-width: 1280px) {
  .display_fast_body {
    width: clamp(0.1vw, 7.1vw, 300px);
    height: clamp(0.1vw, 7.1vw, 300px);
  }

  .font_element_vote {
  font-size: 19px;
}
}

.QuorumBox{
	width: clamp(0.1vw, 22.5vw, 450px); 
	height: clamp(0.1vw, 9vw, 160px);
}

.TextQuorum{
	font-size: clamp(1vw, 4vw, 4vw);
	font-family: 'Old Standard TT', serif; 
	position: absolute; 
	transform: translate(-50%, -10%);
}

.TextMargin{
	margin-top: 5%; 
	font-size: clamp(1vw, 2vw, 3vw);
	font-family: 'Old Standard TT', serif;
}

.col-1-5-users-14 {
  width: 14% !important;
}

.col-1-5-users-11 {
  width: 20% !important;
}

.rounded-border {
  border-radius: 10px;
}

.text-white {
  color: white;
}

.font-weight-bold {
  font-weight: bold;
}
</style>
<template>
  <div>
    <div class="d-flex justify-content-center">
		<div class="WaitExplanation d-flex justify-conten-center">
			<div class="d-flex justify-content-center align-items-center w-100">
				<div class="text-center">
					<div class="d-flex justify-content-center">
					<div style="width: 55%;background-color: #6C757D;padding: 8px;border-radius: 16px;">
						<span
						class="fonts-body" style="font-size: 2.9vw">
						Sessão finalizada
						</span>
					</div>
					</div>
					<div class="d-flex justify-content-center">
					<div>
						<span
						class="fonts-body" style="font-size: 3.9vw">
						Obrigado por acompanhar!
						</span>
					</div>
					</div>
					</div>
			</div>
		</div>
    </div>

	<div class="d-flex align-items-center justify-content-between box-footer-17 w-95"
			style="bottom: 15px; position: fixed;">
				<div class="d-flex align-items-center w-20">
					<img src="@/assets/img/LogoSmart.png" class="img_legis_footer_17"/>
					<span class="text-white font_footer_17" style=" font-size: 1.8vw;">Smartlegis</span>
				</div>
				<div class="text-white align-items-center w-30" style=" font-size: 1.8vw;">
					<i class="fa-solid fa-calendar-day"></i> {{ dia }} de {{ mes }} | {{ time }}
				</div>
			</div>
  </div>
</template>