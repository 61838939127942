<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../stores/index';
import Loading from '../../components/documents/Modals/Loading.vue';
export default {
	name: 'DashboardDefault',
	components: {Loading},
	setup() {
		const StoreAll = useMainStore();
		StoreAll.Token();
		return {
			StoreAll: StoreAll,
		};
	},
	data() {
		return {
			data_start: '',
			session_name: '',
			session_id: 0,
			user_name: '',
			vereadores: [],
			results: [],
			quorum: [],
			quorums: [],
			quorum_obj: 0,
			grapic: false,
			header_error: '',
			body_error: '',
			newPresident: 0
		};
	},
	mounted() {
		this.User();
		this.GetSession();
		this.GetUsersAll();
		this.Notification_sign();
	},
	methods: {

		async GetUsersAll() {
			await this.StoreAll.UserAllEnvio();
			await this.StoreAll.UserAll();
			let idPresident = 0;
			for (let i = 0; i < this.StoreAll.user_all.length; i++) {
				if (this.StoreAll.user_all[i].user_category_id === 3) {
					idPresident = this.StoreAll.user_all[i].id;
				}
			}
			this.newPresident = idPresident;
		},

		async GetSession() {
			await this.StoreAll.Get_Data();
			this.session_id = this.StoreAll.session.id;
			this.getQuorum();
		},

		async User() {
			await this.StoreAll.User();
		},

		async Notification_sign() {
			await this.StoreAll.Notification();
		},


		chech_session() {
			this.$refs.Loading.openModal();
			if (this.StoreAll.session == null) {
				this.CreateSession();
			}
			else if (this.StoreAll.session.session_status_id == 1 || this.StoreAll.session.session_status_id == 2) {
				this.$refs.Loading.closeModal();
				document.getElementById('session_init').click();
			} else {
				this.CreateSession();
			}
		},
		async CreateSession() {
			if (this.session_name === '') {
				this.header_error = 'Sessão não criada';
				this.body_error = 'Você precisa por nome a sessão';
				this.$refs.Loading.closeModal();
				this.show_error();
			}
			if (this.data_start === '') {
				this.header_error = 'Sessão não criada';
				this.body_error = 'Você precisa por a data da sessão';
				this.$refs.Loading.closeModal();
				this.show_error();
			}
			let create = {
				name: this.session_name,
				datetime_start: this.data_start,
				session_status_id: 1,
				user_id: 1,
			};
			await AxiosAPI.post('/session', create, {
				headers: {
					Authorization: 'Bearer ' + this.StoreAll.accessToken
				}
			}).then((resp) => {
				if (resp.status === 200) {
					this.GetSession();
					document.getElementById('Close_session_modal').click();
					document.getElementById('Close_session_modal_warnig').click();
					this.header_error = '';
					this.body_error = '';
					this.$refs.Loading.closeModal();
					this.show_success();
				}
			});
		},

		finallyloading() {
			document.getElementById('loads').style.display = 'flex';
			document.getElementById('loadingoff').style.display = 'none';
			document.getElementById('sessionoff').style.display = 'none';
			document.getElementById('dataoff').style.display = 'none';
			document.getElementById('start_tribune').style.display = 'none';
		},


		async getQuorum() {
			if (this.StoreAll.session == null) {
				return;
			} else {
				await AxiosAPI.get('/quorum?session_id=' + this.StoreAll.session.id, {
					headers: {
						authorization: 'Bearer ' + this.StoreAll.accessToken,
					},
				}).then((response) => {
					if (response.data.data.length <= 0) {
						return;
					} else {
						let size = response.data.data.length;
						this.quorum_obj = response.data.data[size - 1];
						localStorage.setItem('Quorum_id', this.quorum_obj.id);
					}

				});

			}
		},

		NewPresidentSet() {
			document.getElementById('CloseNewPresident_modal').click();
			this.$refs.Loading.openModal();
			AxiosAPI.post('/users/'+this.newPresident+'/change-president', {},{
				headers: {
					authorization: 'Bearer ' + this.StoreAll.accessToken,
				},
			}).then((response) => {
				if (response.status === 200) {
					this.$refs.Loading.closeModal();
					this.GetUsersAll();
					this.$toast.add({ severity: 'success', summary: 'Troca efetuada!', detail: 'O presidente foi trocado', life: 5000 });
				}
			}) .catch((error) => {
				if (error.response.status === 400) {
					this.$refs.Loading.closeModal();
					this.$toast.add({severity: 'info', summary: 'Troca não realizada', detail: 'Presidente selecionado já é presidente', life: 5000});
				}
			});
		},

		show_success() {
			this.$toast.add({ severity: 'success', summary: 'Sessão criada!', detail: 'A sessão foi criada', life: 3000 });
		},

		show_error() {
			this.$toast.add({severity: 'error', summary: this.header_error , detail: this.body_error , group: 'tl', life: 5000});
		},

	},
};
</script>
<template>
 <div class="py-4 container-fluid">
  <div style="z-index: 1010 !important;"><Loading ref="Loading"></Loading></div>
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <img
            style="
              width: 95%;
              border-radius: 35px;
              display: block;
              margin: 5px auto;
              max-width: 100%;
              height: 260px;
            "
            src="../../assets/img/SmartBack.png"
          />


          <div class="d-flex justify-content-between">

            <div v-if="this.StoreAll.user.user_category_id != 6 && this.StoreAll.user.user_category_id != 4 && this.StoreAll.user.user_category_id != 9" class="card mt-4 mx-auto"
              style="width: 18rem">
              <router-link :to="{ name: 'Sessão da semana', params: { id: this.session_id } }" exact>
                <img class="card-img-top" src="../../assets/img/StartSessao.png" alt="Card image cap" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Sessão da Semana</h5>
                  <p class="card-text">
                    Clicando aqui você poderá acessar e criar a sessão da
                    semana.
                  </p>
                </div>
              </router-link>
            </div>

            <div v-if="this.StoreAll.user.user_category_id == 1" class="card mt-4 mx-auto"
              style="width: 18rem;cursor: pointer;" data-toggle="modal" data-target="#exampleModal">
              <img class="card-img-top" src="../../assets/img/createsession.png" alt="Card image cap"
                style="border-radius: 10px 10px 10px 10px;" />
              <div class="card-body text-dark">
                <h5 class="card-title">Criar sessão</h5>
                <p class="card-text">
                  Crie a sessão da semana!
                </p>
              </div>
            </div>


            <div v-if="this.StoreAll.user.user_category_id == 3" class="card mt-4 mx-auto"
              style="width: 18rem;cursor: pointer;">
              <router-link :to="{ name: 'Tribuna' }" exact>
                <img class="card-img-top" src="../../assets/img/createsession.png" alt="Card image cap"
                  style="border-radius: 10px 10px 10px 10px;" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Tribuna</h5>
                  <p class="card-text">
                    Inicie a tribuna por aqui!
                  </p>
                </div>
              </router-link>
            </div>

            <div v-if="this.StoreAll.user.user_category_id == 5" class="card mt-4 mx-auto"
              style="width: 18rem;cursor: pointer;">
              <router-link :to="{ name: 'Documentos recebidos prefeitura' }" exact>
                <img class="card-img-top" src="../../assets/img/Controle_disc.jpg" alt="Card image cap"
                  style="border-radius: 10px 10px 10px 10px;" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Documentos Recepcionados</h5>
                  <p class="card-text">
                    Verifique os documentos recebidos.
                  </p>
                </div>
              </router-link>
            </div>

            <div v-if="this.StoreAll.user.user_category_id == 4" class="card mt-4 mx-auto"
              style="width: 18rem;cursor: pointer;">
              <router-link :to="{ name: 'Documentos procurador' }" exact>
                <img class="card-img-top" src="../../assets/img/Controle_disc.jpg" alt="Card image cap"
                  style="border-radius: 10px 10px 10px 10px;" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Documentos procurador</h5>
                  <p class="card-text">
                    Verifique os documentos que precisam de parecer.
                  </p>
                </div>
              </router-link>
            </div>

            <div v-if="this.StoreAll.user.user_category_id == 9" class="card mt-4 mx-auto"
              style="width: 18rem;cursor: pointer;">
              <router-link :to="{ name: 'Controle da discussão' }" exact>
                <img class="card-img-top" src="../../assets/img/createsession.png" alt="Card image cap"
                  style="border-radius: 10px 10px 10px 10px;" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Controle da discussão</h5>
                  <p class="card-text">
                    Controle a discussão por aqui.
                  </p>
                </div>
              </router-link>
            </div>

            <div v-if="this.StoreAll.user.user_category_id == 9" class="card mt-4 mx-auto"
              style="width: 18rem;cursor: pointer;">
              <router-link :to="{ name: 'Controle da Tribuna' }" exact>
                <img class="card-img-top" src="../../assets/img/Controle_disc.jpg" alt="Card image cap"
                  style="border-radius: 10px 10px 10px 10px;" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Controle da tribuna</h5>
                  <p class="card-text">
                    Controle a tribuna por aqui.
                  </p>
                </div>
              </router-link>
            </div>

            <div v-if="this.StoreAll.user.user_category_id == 9" class="card mt-4 mx-auto"
              style="width: 18rem;cursor: pointer;">
              <router-link :to="{ name: 'Controle da explanation' }" exact>
                <img class="card-img-top" src="../../assets/img/StorySessao.png" alt="Card image cap"
                  style="border-radius: 10px 10px 10px 10px;" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Explanações pessoais</h5>
                  <p class="card-text">
                    Controle as explanações pessoais.
                  </p>
                </div>
              </router-link>
            </div>

            <div v-if="this.StoreAll.user.user_category_id == 9" class="card mt-4 mx-auto"
              style="width: 18rem;cursor: pointer;">
              <router-link :to="{ name: 'Controle da questão de ordem' }" exact>
                <img class="card-img-top" src="../../assets/img/ordem.jpg" alt="Card image cap"
                  style="border-radius: 10px 10px 10px 10px;" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Questão de ordem</h5>
                  <p class="card-text">
                    Iniciar questão de ordem.
                  </p>
                </div>
              </router-link>
            </div>

            <div v-if="this.StoreAll.user.user_category_id != 6 && this.StoreAll.user.user_category_id != 9" class="card mt-4 mx-auto"
              style="width: 18rem">
              <router-link :to="{ name: 'Histórico de sessões' }" exact>
                <img class="card-img-top" src="../../assets/img/StorySessao.png" alt="Card image cap" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Histórico de sessões</h5>
                  <p class="card-text">
                    Clicando abaixo você poderá acessar o histórico de sessões
                    já realizadas.
                  </p>
                </div>
              </router-link>
            </div>
             <div v-if="this.$pinia.state.value.main.host != 'upanema' && this.$pinia.state.value.main.host != 'goianinha' && this.$pinia.state.value.main.host != 'macaiba' && this.$pinia.state.value.main.host != 'sm' && this.StoreAll.user.user_category_id == 6" class="card mt-4 mx-auto"
              style="width: 18rem">
              <router-link :to="{ name: 'Tela de sessão'}" exact>
                <img class="card-img-top" src="../../assets/img/StartSessao.png" alt="Card image cap" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Tela de sessão</h5>
                  <p class="card-text">
                    Ir para a TV.
                  </p>
                </div>
              </router-link>
            </div>

            <div v-if="this.$pinia.state.value.main.host == 'upanema' && this.StoreAll.user.user_category_id == 6 || this.$pinia.state.value.main.host == 'goianinha' && this.StoreAll.user.user_category_id == 6 || this.$pinia.state.value.main.host == 'sm' && this.StoreAll.user.user_category_id == 6 || this.$pinia.state.value.main.host == 'macaiba' && this.StoreAll.user.user_category_id == 6" class="card mt-4 mx-auto"
              style="width: 18rem">
              <router-link :to="{ name: 'Tela de sessão up'}" exact>
                <img class="card-img-top" src="../../assets/img/StartSessao.png" alt="Card image cap" />
                <div class="card-body text-dark">
                  <h5 class="card-title">Tela de sessão</h5>
                  <p class="card-text">
                    Ir para a TV.
                  </p>
                </div>
              </router-link>
            </div>
          </div>
          <div v-if="this.StoreAll.user.user_category_id === 1" class="d-flex justify-content-center">
            <div v-for="UserHall in this.StoreAll.user_all" :key="UserHall">
              <div v-if="UserHall.user_category_id ===3" class="d-flex justify-content-center">
                  <div class="text-center" style="width: 200px;">
                    <div class="mb-3">
                      <span><b>Presidente atual</b></span>
                    </div>
                    <img :src="this.StoreAll.Dochost+UserHall.path_image" style="width: 200px; border-radius: 50%">
                    <div class="mt-2">
                      <span>{{ UserHall.nickname }}</span>
                    </div>
                    <button type="button" class="btn btn-success mt-3" data-toggle="modal" data-target="#PresidenteChange">Trocar presidente</button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" style="z-index: 1001 !important;" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Crie a sessão</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="Close_session_modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 15px;">Nome da sessão:</label>
            <input style="font-size: 15px;" class="mb-3 multisteps-form__input form-control" type="text"
              placeholder="Xº SESSÃO ORDINÁRIA DO Xº PERIODO" v-model="session_name" />

            <label style="font-size: 15px;">Data:</label>
            <input type="date" class="mb-3 multisteps-form__input form-control" v-model="data_start">
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Voltar</button>
            <button type="button" class="btn btn-primary" @click="chech_session();">Criar</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade PresidenteChange" style="z-index: 1001 !important;" id="PresidenteChange" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Trocar presidente</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="CloseNewPresident_modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <label style="font-size: 15px;">Quem será o presidente:</label>
            <select
                  id="choices-category"
                  v-model="newPresident"
                  class="form-control"
                  name="choices-category"
                  >
                  <option
                    v-for="option in this.StoreAll.user_all"
                    :key="option.id"
                    :value="option.id"
                  >
                    {{ option.name }}
                  </option>
                </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Voltar</button>
            <button type="button" class="btn btn-success" @click="NewPresidentSet();">Trocar</button>
          </div>
        </div>
      </div>
    </div>

    <button id="session_init" style="display:none" data-toggle="modal" data-target=".session_init_modal"></button>
    <div class="modal fade session_init_modal" style="z-index: 1002 !important;" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-sm">
        <div class="modal-content">
          <div class="modal-header">
            <h6>Criar sessão</h6>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="Close_session_modal_warnig">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <span style="color:red;"> Existe uma sessão criada não encerrada, deseja continuar?</span>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Voltar</button>
            <button type="button" class="btn btn-success"
              @click="CreateSession();">Continuar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.close {
  font-size: 2.3rem !important;
}

.photo_ranking {
  width: 163px;
  height: 163px;
}
</style>
